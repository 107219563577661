import { Store } from 'vuex';
import { watch } from '@vue/composition-api';
import { differenceWith, isEqual } from 'lodash';
import { RootState } from '@/store/store';
import { useStore } from '@/composables/useStore';
import FabricCanvas from '@/canvas/fabric/FabricCanvas';
import { AlertColor } from '@/utils/alerts';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { safeAreaObjToFabricObj } from '@/canvas/mappers';
import { CanvasMode } from '@/canvas/types';

export function watchSafeAreaObjects(canvas: FabricCanvas) {
  const store: Store<RootState> = useStore();

  watch(
    () => store.getters['design/safeAreas'],
    async (components: SafeAreaObject[], prevComponents: SafeAreaObject[]) => {
      let errorMessage = null;

      try {
        // delete old objects
        const deletedObjects = differenceWith(
          prevComponents,
          components,
          (a: SafeAreaObject, b: SafeAreaObject) => a.id === b.id,
        );
        console.debug('deleted safe areas', deletedObjects);
        canvas.deleteObjects(deletedObjects.map((o: SafeAreaObject) => o.id));
      } catch (e) {
        console.error(e);
        errorMessage = 'Cannot remove this safe area';
      }

      try {
        if (store.getters['design/loadDesignMode']) {
          canvas.setCanvasMode(CanvasMode.LoadDesign);
          canvas.multipleObjsModeOn(store.getters['design/countObjects']);
        }

        // add/update objects where image url changed
        const changedObjects = differenceWith(
          components,
          prevComponents,
          (a: SafeAreaObject, b: SafeAreaObject) =>
            a.id === b.id && isEqual(a.transformMatrix, b.transformMatrix),
        );
        console.debug('added or changed url', changedObjects);
        await Promise.all(
          changedObjects.map(async (dObj: SafeAreaObject) => {
            const index = store.getters['design/getObjectIndex'](dObj);
            const objToAdd = await safeAreaObjToFabricObj(dObj, index);
            canvas.replaceObject(objToAdd, index);
          }),
        );
        canvas.requestRender();
      } catch (e) {
        console.error(e);
        errorMessage = 'Cannot load safe area';
      }

      if (errorMessage) {
        await store.dispatch('addAlert', {
          message: errorMessage,
          color: AlertColor.ERROR,
        });
        await store.dispatch('design/revertToPrevState');
      }
    },
    { deep: true },
  );
}
