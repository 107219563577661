import { Mapper } from '@/models/mappers/Mapper';
import { IDesignState } from '@/store/modules/design/types';
import {
  DesignDTO,
  ProductObjectDTO,
  TextObjectDTO,
} from '@/api/dto/DesignDTO';
import { cloneDeep } from 'lodash';
import { ProductObject } from '@/models/designObject/ProductObject';
import { catalogItemMapper } from '@/models/mappers/CatalogItemMapper';
import { DesignObjectType } from '@/models/DesignObjectType';
import { TextObject } from '@/models/designObject/TextObject';
import {
  isTextMultiline,
  textBehaviourOptions,
  textVisibility,
} from '@/calculateProperties/textProps';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { createObjSafeAreas, getCanvasSize } from '@/utils/storeUtils';
import { productBehaviourOptions } from '@/calculateProperties/productProps';
import { getRingTransforms } from '@/calculateProperties/helpers/ringTransforms';

export class DesignMapper extends Mapper<IDesignState, DesignDTO> {
  async mapFromDTO(itemDTO: DesignDTO): Promise<IDesignState> {
    const productObjects: ProductObject[] = [];
    const textObjects: TextObject[] = [];
    const safeAreas: SafeAreaObject[] = [];

    const productsData = itemDTO.products;
    const textsData = itemDTO.texts;

    const baseProductData = productsData.find(
      pr => pr.type === DesignObjectType.BASE,
    );

    await Promise.all(
      productsData.map(async productData => {
        const productObject = await this.mapProductFromDTO(
          productData,
          productData.type !== DesignObjectType.BASE
            ? baseProductData?.designObjId
            : undefined,
        );
        productObjects.push(productObject);

        const objSafeAreas = (await createObjSafeAreas([], productObject))
          .safeAreasToAdd;
        safeAreas.push(...objSafeAreas);
        // }
      }),
    );

    textsData.map(textData => {
      const parent = productObjects.find(
        productObject => productObject.id === textData.parentDesignObjId,
      );
      textObjects.push(this.mapTextFromDTO(textData, parent));
    });

    return {
      selectedId: null,
      productObjects: productObjects,
      texts: textObjects,
      safeAreas: safeAreas,
      totalPrice: 0,
      cartPreview: '',
      genCartPreview: false,
      quoteItems: [],
      canvasZoom: 'FIT',
    };
  }

  mapToDTO(item: IDesignState): DesignDTO {
    const version = '1.1.0';

    const products: ProductObjectDTO[] = item.productObjects.map(obj => {
      return {
        designObjId: obj.id,
        product: catalogItemMapper.mapToDTO(obj.product),
        sizeId: obj.sizeId,
        parentDesignObjId: obj.parentId,
        transformMatrix: cloneDeep(obj.transformMatrix),
        boundingBox: cloneDeep(obj.boundingBox),
        sizePrice: obj.price,
        type: obj.type,
      };
    });

    const texts: TextObjectDTO[] = item.texts.map(txt => {
      return {
        designObjId: txt.id,
        parentDesignObjId: txt.parentId || '',
        locationId: txt.locationId,
        text: txt.text,
        fontFamily: txt.fontFamily,
        fontSize: txt.fontSize,
        fill: txt.fill,
        transformMatrix: txt.transformMatrix,
        boundingBox: txt.boundingBox,
        parentProductName: txt.parentProductName,
      };
    });

    return {
      products: products,
      texts: texts,
      version,
    };
  }

  private async mapProductFromDTO(
    productData: ProductObjectDTO,
    baseProductId?: string,
  ): Promise<ProductObject> {
    const productObject: ProductObject = new ProductObject(
      productData.type,
      await catalogItemMapper.mapFromDTO(productData.product),
      productData.sizeId,
      undefined,
      baseProductId,
      productData.designObjId,
    );
    productObject.updateProps({
      transformMatrix: productData.transformMatrix,
      boundingBox: productData.boundingBox,
      behaviourOptions: productBehaviourOptions(productObject),
    });

    // recalculate transforms for rings locations
    if (productObject.product.isRing) {
      const transformOptions = await getRingTransforms(
        productObject,
        getCanvasSize(),
      );
      productObject.updateProps({
        ...transformOptions,
      });
    }
    return productObject;
  }

  private mapTextFromDTO(
    textData: TextObjectDTO,
    parentObj?: ProductObject,
  ): TextObject {
    const textObject = new TextObject(
      textData.parentDesignObjId,
      textData.locationId,
      textData.parentProductName,
    );

    textObject.updateProps({
      transformMatrix: textData.transformMatrix,
      boundingBox: textData.boundingBox,
      visible: textVisibility(textObject, parentObj),
      fontFamily: textData.fontFamily,
      fontSize: textData.fontSize,
      fill: textData.fill,
      text: textData.text,
      isMultiline: isTextMultiline(parentObj),
      behaviourOptions: textBehaviourOptions(),
    });
    return textObject;
  }
}
export const designMapper = new DesignMapper();
