import { DesignObject } from '@/models/designObject/DesignObject';
import { DesignObjectType } from '@/models/DesignObjectType';

/**
 * Represents text object information received from canvas
 */
export type TextObjectData = Partial<
  Omit<TextObject, 'id' | 'parentId' | 'locationId'>
>;

export class TextObject extends DesignObject {
  private _defaultText = 'Your text';

  private _locationId: number;

  public text = this._defaultText;

  public fontFamily = 'Arial';
  public fontSize = 10;
  public fill = '#000000FF';

  public isMultiline = false;

  public visible = true;

  public parentProductName;

  public constructor(
    parentId: string,
    locationId: number,
    parentProductName: string,
    id?: string,
  ) {
    super(DesignObjectType.TEXT, parentId, id);
    this._locationId = locationId;
    this.parentProductName = parentProductName;
  }

  public get locationId() {
    return this._locationId;
  }

  public updateProps(data: TextObjectData) {
    const text = data.text ?? this.text;

    Object.assign(this, { ...data, text: text.trim() || this._defaultText });
  }
}
