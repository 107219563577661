







































































import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from '@vue/composition-api';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { formatCurrency } from '@/utils/currencyUtils';
import { useWindowSize } from '@vueuse/core';
import { DesignObjectType } from '@/models/DesignObjectType';
import { ROUTES } from '@/router/constants';

const Component = defineComponent({
  name: 'ItemSliderList',

  props: {
    items: {
      type: Array as PropType<CatalogItem[]>,
      default: () => [],
    },
    selectedItem: {
      type: Object as PropType<CatalogItem>,
      default: () => null,
    },
    addItem: {
      type: Function as PropType<() => void>,
      default: () => {},
    },

    selectedPrice: Number,

    showTitle: {
      type: Boolean,
      default: false,
    },

    designObjectType: {
      type: String as PropType<DesignObjectType>,
      default: '',
    },

    showPrice: {
      type: Boolean,
      default: true,
    },

    /**
     * Action to do on select, passes selected item
     * used for adding custom logic upon selection
     */
    selectItem: {
      type: Function as PropType<((item: CatalogItem) => void) | undefined>,
      default: undefined,
    },
  },

  emits: ['update:selectedItem'],

  setup(props, { emit, root: { $router } }: SetupContext) {
    const cardSize = 96;

    const item = computed({
      get: () => props.selectedItem,
      set: value => emit('update:selectedItem', value),
    });

    const { width: screenWidth } = useWindowSize();

    /** margin is set in v-card class */
    const cardMargin = 16;
    const isPlusButtonShown = computed(() => {
      return (cardSize + cardMargin) * props.items.length > screenWidth.value;
    });
    const addButtonWidth = cardSize * 0.75;
    const sliderWidth = computed(
      () => screenWidth.value - addButtonWidth - cardMargin,
    );

    const viewMore = () => {
      $router.push({
        name: ROUTES.viewMore.name,
        params: {
          designObjectType: props.designObjectType ?? '',
        },
      });
    };

    const activePrice = computed(
      () => props.selectedPrice ?? item.value.minPrice,
    );

    const selectCard = (toggle: Function, selectedItem: CatalogItem) => {
      if (props.selectItem) return props.selectItem(selectedItem);
      toggle();
    };

    return {
      item,
      viewMore,
      cardSize,
      selectCard,
      activePrice,
      sliderWidth,
      addButtonWidth,
      formatCurrency,
      isPlusButtonShown,
    };
  },
});

export default Component;
