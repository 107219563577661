



















import { defineComponent, SetupContext } from '@vue/composition-api';

const Component = defineComponent({
  name: 'TabCardLayout',

  emits: ['close'],

  setup(_, { root, emit }: SetupContext) {
    const close = () => {
      emit('close');
    };

    return {
      close,
    };
  },
});

export default Component;
