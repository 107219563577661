import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { fetchUSymbolsList } from '@/api/apiClient';
import { USymbol } from '@/models/catalog/USymbol';
import { USymbolDTO } from '@/api/dto/USymbolDTO';

interface SymbolsState {
  items: USymbol[];
  loading: boolean;
}

class SymbolsModule implements Module<SymbolsState, any> {
  namespaced: boolean = true;

  state: SymbolsState = {
    items: [],
    loading: false,
  };

  getters: GetterTree<SymbolsState, any> = {
    items(state): USymbol[] {
      return state.items;
    },

    loading(state): boolean {
      return state.loading;
    },
  };

  actions: ActionTree<SymbolsState, any> = {
    async fetchItems({ state, commit }) {
      commit('setLoading', true);
      try {
        if (!state.items.length) {
          const data: USymbolDTO[] = await fetchUSymbolsList();
          const result: USymbol[] = (data || []).map(item => new USymbol(item));

          commit('setItems', result);
        }
      } catch (err) {
        console.error(err);
      } finally {
        commit('setLoading', false);
      }
    },
  };

  mutations: MutationTree<SymbolsState> = {
    setItems(state, items: USymbol[]) {
      state.items = items;
    },

    setLoading(state, value: boolean) {
      state.loading = value;
    },
  };
}

const symbolsModule = new SymbolsModule();

export default symbolsModule;
