import { inject, provide } from '@vue/composition-api';
import { Store } from 'vuex';
import { RootState } from '@/store/store';
const StoreSymbol = Symbol();

export function provideStore(store: Store<RootState>) {
  provide(StoreSymbol, store);
}

export function useStore(): Store<RootState> {
  const store: Store<RootState> | void = inject(StoreSymbol);
  if (store instanceof Store) return store;
  return new Store<RootState>({});
}
