import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { getSafeAreaTransform } from '@/calculateProperties/helpers/transformMatrix';
import { ProductObject } from '@/models/designObject/ProductObject';
import { getSafeAreaBoundingBox } from '@/calculateProperties/helpers/boundingBox';

export async function safeAreaProps(
  safeAreaObj: SafeAreaObject,
  parent: ProductObject,
) {
  const transformMatrix = getSafeAreaTransform(safeAreaObj, parent);
  const boundingBox = getSafeAreaBoundingBox(safeAreaObj, transformMatrix);

  return {
    transformMatrix,
    boundingBox,
  };
}
