
































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  SetupContext,
} from '@vue/composition-api';
import { Font, FontStatus } from '@/models/catalog/Font';
import { sortBy } from 'lodash';
import { loadAllFonts, SKIP_FONTS } from '@/canvas/utils/fonts';

interface State {
  fonts: Font[];
  selectedFont: Font;
}

const Component = defineComponent({
  name: 'FontList',
  props: {
    font: String,
  },

  emits: ['update:font'],

  setup(props, { root: { $store }, emit }: SetupContext) {
    const state = reactive({
      fonts: computed(() => $store.getters[`fontsModule/items`]),
      selectedFont: computed({
        get: () => state.fonts.find(f => f.fontFamily === props.font),
        set: value => emit('update:font', value),
      }),
    }) as State;

    const loading = ref(false);

    /**
     * Filter fonts that are not loaded yet
     * Sort in the ASC order by font family
     */
    const visibleFonts = computed(() => {
      const fonts = $store.getters[`fontsModule/items`]
        .filter((f: Font) => f.status !== FontStatus.Failed)
        // hardcoded hack to drop broken fonts (causes performance issues)
        .filter((f: Font) => !SKIP_FONTS.includes(f.fontFamily));
      return sortBy(fonts, ['fontFamily']);
    });

    onMounted(() => {
      loading.value = true;
      const fonts = $store.getters[`fontsModule/items`];
      loadAllFonts(fonts).finally(() => (loading.value = false));
    });

    return {
      state,
      FontStatus,
      visibleFonts,
      loading,
    };
  },
});

export default Component;
