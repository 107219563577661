import { render, staticRenderFns } from "./MobileLayout.vue?vue&type=template&id=1678217e&scoped=true&"
import script from "./MobileLayout.vue?vue&type=script&lang=ts&"
export * from "./MobileLayout.vue?vue&type=script&lang=ts&"
import style0 from "./MobileLayout.vue?vue&type=style&index=0&id=1678217e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1678217e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VContainer,VExpandTransition,VOverlay,VSheet})
