export const ROUTES = {
  root: {
    path: '/',
    name: 'designer',
  },
  cart: {
    path: '/cart',
    name: 'cart',
  },
  catalogTab: {
    path: 'catalog-tab',
    name: 'catalogTab',
  },
  selectCategory: {
    path: '/select-category',
    name: 'categoriesTab',
  },
  textTab: {
    path: '/text-tab',
    name: 'engravingTab',
  },
  detailsTab: {
    path: '/details-tab',
    name: 'detailsTab',
  },
  viewMore: {
    path: 'view-more/:designObjectType',
    name: 'viewMoreTab',
  },
  baseProduct: {
    path: 'base-product',
    name: 'baseProductTab',
  },
  closure: {
    path: 'closure',
    name: 'closureTab',
  },
  component: {
    path: 'component',
    name: 'componentTab',
  },
};
