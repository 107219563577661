import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { SafeAreaSegment } from '@/models/designObject/types';
import { Point } from '@/canvas/types';

const Bezier = require('bezier-js');

export function segmentsToBezierCurves(safeArea: SafeAreaObject) {
  return safeArea.scaledTranslatedSegments().map((segment: SafeAreaSegment) => {
    return new Bezier.Bezier(
      segment.startPoint?.x,
      segment.startPoint?.y,
      segment.ctrl1?.x,
      segment.ctrl1?.y,
      segment.ctrl2?.x,
      segment.ctrl2?.y,
      segment.endPoint?.x,
      segment.endPoint?.y,
    );
  });
}

export function calcPositionOnSafeLineObj(
  bezierCurves: Object[] = [],
  newPosition: Point,
) {
  const projections: Point[] = [];
  bezierCurves.forEach((curve: any) => {
    const p = curve.project(newPosition);
    projections.push(p);
  });

  let closestP: Point | undefined;
  let prevDist = Infinity;

  //find projection point that is closest to the new position
  projections.forEach(p => {
    const dist = Math.hypot(p.x - newPosition.x, p.y - newPosition.y);
    if (dist < prevDist) {
      closestP = p;
      prevDist = dist;
    }
  });

  if (!closestP) return newPosition;

  return {
    x: closestP.x,
    y: closestP.y,
  };
}
