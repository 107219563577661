import { ProductObject } from '@/models/designObject/ProductObject';
import { getProductTransformMatrix } from '@/calculateProperties/helpers/transformMatrix';
import { getProductBoundingBox } from '@/calculateProperties/helpers/boundingBox';
import { getRingTransforms } from '@/calculateProperties/helpers/ringTransforms';
import { DesignObjectType } from '@/models/DesignObjectType';
import {
  BehaviourOptions,
  ProductCanvasProps,
} from '@/models/designObject/types';
import { Dimensions } from '@/canvas/types';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { cloneDeep } from 'lodash';

export async function productProps(
  designObj: ProductObject,
  canvasSize: Dimensions,
  baseProduct?: ProductObject,
  safeArea?: SafeAreaObject,
  componentsCount?: number,
): Promise<ProductCanvasProps> {
  let transformPropsObject: ProductCanvasProps = {};

  if (designObj.product.isRing) {
    transformPropsObject = await getRingTransforms(
      cloneDeep(designObj),
      canvasSize,
      // baseProduct,
    );
  } else {
    transformPropsObject.transformMatrix = await getProductTransformMatrix(
      cloneDeep(designObj),
      canvasSize,
      cloneDeep(baseProduct),
      cloneDeep(safeArea),
      componentsCount,
    );

    transformPropsObject.boundingBox = await getProductBoundingBox(
      designObj,
      transformPropsObject.transformMatrix,
    );
  }

  const behaviourOptions: BehaviourOptions = productBehaviourOptions(designObj);

  return {
    ...transformPropsObject,
    behaviourOptions,
  };
}

export function productBehaviourOptions(designObj: ProductObject) {
  return {
    resizable: false,
    selectable: true,
    movable: designObj.type === DesignObjectType.COMPONENT,
    rotatable: false,
    deletable: designObj.type === DesignObjectType.COMPONENT,
    editable:
      designObj.type === DesignObjectType.COMPONENT &&
      designObj.product.isTextAllowed,
  };
}
