import { inject, provide } from '@vue/composition-api';
import VueRouter from 'vue-router';
const RouterSymbol = Symbol();

export function provideRouter(router: VueRouter) {
  provide(RouterSymbol, router);
}

export function useRouter(): VueRouter {
  const router: VueRouter | void = inject(RouterSymbol);
  if (router instanceof VueRouter) return router;
  return new VueRouter({});
}
