import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { Store } from 'vuex';
import { RootState } from '@/store/store';
import { DesignObjectType } from '@/models/DesignObjectType';
import VueI18n, { IVueI18n } from 'vue-i18n';

const getParent = (
  type: DesignObjectType,
  store: Store<RootState>,
  id?: number,
): CatalogItem => store.getters[`${type}/getItemById`](id);

/**
 * if category selected, its parent should be selected
 * if product selected, its category parent should be selected as products do not fetch subItems
 * */
export const stepBackInCatalogTree = (
  selectedItem: CatalogItem | null,
  store: Store<RootState>,
  type: DesignObjectType,
) => {
  const category = getParent(type, store, selectedItem?.parentId);

  store.dispatch(
    `${type}/setSelectedItem`,
    selectedItem?.isProduct
      ? getParent(type, store, category.parentId)
      : category,
  );
};

/**
 * traverse catalog tree to fetch all parents names
 */
export const fetchAllParentsNames = (
  item: CatalogItem | null,
  type: DesignObjectType,
  store: Store<RootState>,
) => {
  const names: string[] = [];

  if (!item) return names;

  let currentItem = item;

  while (currentItem) {
    const parent = getParent(type, store, currentItem.parentId);
    if (parent) {
      names.push(parent.name);
    }
    currentItem = parent;
  }

  return names;
};

/**
 * defines the view more tab title based on the type
 */
export const selectViewMoreTitle = (
  isMobile: boolean,
  isCategory: boolean,
  isRing: boolean,
  type: DesignObjectType,
  $i18n: VueI18n & IVueI18n,
) => {
  if (type === DesignObjectType.COMPONENT) {
    if (isCategory) {
      return $i18n.t('titles.viewMore.component.category');
    } else {
      return $i18n.t('titles.viewMore.component.product');
    }
  } else {
    const objectType =
      type === DesignObjectType.CLOSURE ? 'clasp' : isRing ? 'ring' : 'chain';

    if (isMobile) {
      return $i18n.tc(`common.${objectType}`, 2);
    } else {
      return $i18n.t('titles.viewMore.default', { objectType });
    }
  }
};
