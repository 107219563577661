





























































import {
  computed,
  defineComponent,
  reactive,
  ref,
  SetupContext,
  watch,
} from '@vue/composition-api';
import {
  allRingItems,
  CatalogItem,
} from '@/models/catalog/product/CatalogItem';
import { DesignObjectType } from '@/models/DesignObjectType';
import ItemGridList from '@/components/elements/ItemGridList.vue';
import { Size } from '@/models/catalog/product/Size';
import {
  stepBackInCatalogTree,
  selectViewMoreTitle,
} from '@/utils/catalogUtils';
import { useDefaultProductChosen } from '@/composables/useDefaultProductChosen';
import { useChangeBaseProduct } from '@/composables/useChooseBaseProduct';

interface State {
  items: CatalogItem[];
  siblings: CatalogItem[];
  selectedItem: CatalogItem | null;
  selectedSize: Size | null;
  selectedSizePrice: number;
  isRootLevel: boolean;
  isLastLevel: boolean;
}

const Component = defineComponent({
  name: 'ViewMoreTab',
  components: {
    ItemGridList,
  },

  setup(_, { root: { $store, $route, $router, $i18n } }: SetupContext) {
    const type = $route.params.designObjectType as DesignObjectType;

    const state = reactive({
      items: computed(() => $store.getters[`${type}/subItems`]),
      siblings: computed(() => $store.getters[`${type}/siblings`]),
      selectedItem: ref($store.getters[`${type}/selectedItem`]),
      selectedSize: computed(() => state.selectedItem?.sizes[0]),
      selectedSizePrice: computed(
        () => state.selectedSize?.price ?? state.selectedItem?.minPrice ?? 0,
      ),
      isRootLevel: computed(
        () =>
          !state.siblings.length && state.items.every(item => !item.parentId),
      ),
      isLastLevel: computed(
        () =>
          state.items.every(item => item.isProduct) || !!state.siblings.length,
      ),
    }) as State;

    const gridItems = computed(() =>
      $store.getters[`${type}/selectedItem`]?.isProduct
        ? state.siblings
        : state.items,
    );

    /** if last level and no product selected, select first by default */
    watch(
      () => state.isLastLevel,
      value => {
        if (value && !state.selectedItem) state.selectedItem = state.items[0];
      },
    );

    const isCharm = type === DesignObjectType.COMPONENT;
    const isMobile = computed(() => $store.getters.isMobile);
    const isRing = computed(() => allRingItems(state.items));
    const isBackBtnVisible = computed(() => !state.isRootLevel && isCharm);

    const title = computed(() =>
      selectViewMoreTitle(
        isMobile.value,
        !state.isLastLevel,
        isRing.value,
        type,
        $i18n,
      ),
    );

    const selectItem = (item: CatalogItem) => {
      /** last level products should be written to store only by 'add' button */
      if (state.isLastLevel) return (state.selectedItem = item);
      $store.dispatch(`${type}/setSelectedItem`, item);
    };

    const closeView = () => $router.back();

    const addComponent = async () => {
      if (!state.selectedItem) return;
      await $store.dispatch('design/addComponent', {
        product: state.selectedItem,
        sizeId: state.selectedItem.sizes[0].id,
      });

      closeView();
    };

    const isDefaultProductChosen = useDefaultProductChosen();
    const selectBaseProduct = useChangeBaseProduct(
      $store,
      isDefaultProductChosen,
    );

    const addBaseProduct = computed(() => async () => {
      if (!state.selectedItem) return;
      selectBaseProduct.value(
        state.selectedItem,
        $store.getters[`${type}/selectedItem`],
        $store.getters[`${type}/selectedSize`],
        closeView,
      );
    });

    const addClosure = async () => {
      await $store.dispatch(`${type}/setSelectedItem`, state.selectedItem);
      closeView();
    };

    const addItem = async () => {
      switch (type) {
        case DesignObjectType.COMPONENT:
          $store.dispatch(
            `${DesignObjectType.COMPONENT}/setSelectedItem`,
            state.selectedItem,
          );
          return addComponent();
        case DesignObjectType.CLOSURE:
          $store.dispatch(
            `${DesignObjectType.CLOSURE}/setSelectedItem`,
            state.selectedItem,
          );
          return addClosure();
        case DesignObjectType.BASE:
        default:
          return addBaseProduct.value();
      }
    };

    const addItemOnDoubleClick = async () => {
      if (isCharm && state.selectedItem?.isProduct) {
        await addComponent();
      }
    };

    const returnBack = computed(() => () => {
      stepBackInCatalogTree(state.selectedItem, $store, type);
    });

    const gridCols = computed(() => {
      if (!isMobile.value) return 3;
      if (state.isLastLevel) return 4;
      return 6;
    });

    return {
      type,
      title,
      state,
      isCharm,
      addItem,
      gridCols,
      isMobile,
      gridItems,
      closeView,
      returnBack,
      selectItem,
      isBackBtnVisible,
      addItemOnDoubleClick,
    };
  },
});

export default Component;
