import { render, staticRenderFns } from "./ScreenRulers.vue?vue&type=template&id=62ee33ea&scoped=true&"
import script from "./ScreenRulers.vue?vue&type=script&lang=ts&"
export * from "./ScreenRulers.vue?vue&type=script&lang=ts&"
import style0 from "./ScreenRulers.vue?vue&type=style&index=0&id=62ee33ea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62ee33ea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VOverlay,VSheet})
