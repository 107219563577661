import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from 'vuex';
import {
  DesignMutationPayload,
  IDesignState,
  OutputState,
  OutputType,
} from '@/store/modules/design/types';
import {
  textActions,
  textGetters,
  textMutations,
} from '@/store/modules/design/DesignTexts';
import {
  productActions,
  productGetters,
  productMutations,
} from '@/store/modules/design/DesignProducts';
import { TextObject } from '@/models/designObject/TextObject';
import { getProductObjectById, turnObjectsToSide } from '@/utils/storeUtils';
import { getDesignById, getQuote, saveDesign } from '@/api/apiClient';
import { designMapper } from '@/models/mappers/DesignMapper';
import { AlertColor } from '@/utils/alerts';
import { DesignDTO } from '@/api/dto/DesignDTO';
import { DesignObject } from '@/models/designObject/DesignObject';
import { QuoteItem } from '@/models/quote/QuoteItem';
import { quoteMapper } from '@/models/mappers/QuoteMapper';

class DesignModule implements Module<IDesignState, any> {
  namespaced = true;

  state: IDesignState = {
    selectedId: null,
    productObjects: [],
    texts: [],
    safeAreas: [],
    totalPrice: 0,
    cartPreview: '',
    genCartPreview: false,
    designId: undefined,
    loadDesignMode: false,
    outputState: undefined,
    isLoading: true,
    quoteItems: [],
    canvasZoom: 'FIT',
  };

  getters: GetterTree<IDesignState, any> = {
    selectedId(state): string | null {
      return state.selectedId;
    },

    totalPrice(state): number {
      return state.totalPrice;
    },

    getOutputState(state: IDesignState) {
      return state.outputState;
    },

    getFirstOutput(state: IDesignState) {
      return state.firstOutput;
    },

    getSecondOutput(state: IDesignState) {
      return state.secondOutput;
    },

    getDesignId(state: IDesignState) {
      return state.designId;
    },

    loadDesignMode(state: IDesignState) {
      return !!state.loadDesignMode;
    },

    getGenCartPreview(state: IDesignState) {
      return state.genCartPreview;
    },

    getCartPreview(state: IDesignState) {
      return state.cartPreview;
    },

    countObjects(state: IDesignState) {
      return (
        state.productObjects.length +
        state.texts.filter(txt => txt.visible).length +
        state.safeAreas.length
      );
    },

    quoteItems(state: IDesignState) {
      return state.quoteItems;
    },

    /** Return selected object, whether it is product, text or safe area */
    selectedObject(state: IDesignState): DesignObject | null {
      const id = state.selectedId;
      if (!id) return null;
      const productObj = state.productObjects.find(obj => obj.id === id);
      const textObj = state.texts.find(obj => obj.id === id);
      const safeAreaObj = state.safeAreas.find(obj => obj.id === id);
      return productObj ?? textObj ?? safeAreaObj ?? null;
    },

    /**
     * Get all design objects despite their type
     */
    allObjects(state: IDesignState): DesignObject[] {
      return [...state.productObjects, ...state.safeAreas, ...state.texts];
    },

    isLoading(state: IDesignState): boolean {
      return !!state.isLoading;
    },

    canvasZoom(state) {
      return state.canvasZoom;
    },

    ...productGetters,
    ...textGetters,
  };

  actions: ActionTree<IDesignState, any> = {
    selectObject(
      {
        state,
        commit,
        getters,
        dispatch,
        rootGetters,
      }: ActionContext<IDesignState, any>,
      id: string | null,
    ) {
      if (!id) return dispatch('deselectObject');

      const obj =
        getProductObjectById(state.productObjects, id) ||
        getters.texts.find((text: TextObject) => text.id === id);
      if (!obj) throw `Object with ${id} is not present on canvas`;

      commit('setSelectedId', { id: id });
    },

    deselectObject({ dispatch, commit }) {
      commit('setSelectedId', { id: null });
    },

    selectParentObject({ getters, dispatch }) {
      const obj: DesignObject = getters.selectedObject;
      dispatch('selectObject', obj.parentId);
    },

    revertToPrevState({
      state,
      commit,
    }: ActionContext<IDesignState & { prev?: IDesignState }, any>) {
      if (!state.prev) return;

      commit('setProductObjects', { value: state.prev.productObjects });
      commit('setTexts', { value: state.prev.texts });
      commit('setSafeAreas', { value: state.prev.safeAreas });
      commit('setSelectedId', { id: state.prev.selectedId });
      commit('setPrevState', undefined);
    },

    resetState({ commit }: ActionContext<IDesignState, any>) {
      commit('setProductObjects', { value: [] });
      commit('setTexts', { value: [] });
      commit('setSafeAreas', { value: [] });
      commit('setSelectedId', { id: null });
    },

    async genCartPreview({ commit }: ActionContext<IDesignState, any>) {
      commit('setGenCartPreview');
    },

    async setCartPreview(
      { commit }: ActionContext<IDesignState, any>,
      cartPreview: string,
    ) {
      commit('setCartPreview', cartPreview);
    },

    async saveDesign({
      state,
      commit,
      getters,
      dispatch,
    }: ActionContext<IDesignState, any>) {
      commit('setLoading', true, { root: true });
      commit('setLoadingText', 'Adding to cart...', { root: true });

      try {
        const designData = designMapper.mapToDTO(state);
        await saveDesign({
          design: designData,
          cartPreview: getters.getCartPreview,
        });
      } catch (e) {
        console.error(e);
        await dispatch(
          'addAlert',
          {
            message: "Couldn't save design. Please, try again later",
            color: AlertColor.ERROR,
          },
          {
            root: true,
          },
        );
      } finally {
        commit('setLoading', false, { root: true });
        commit('setLoadingText', null, { root: true });
      }
    },

    async getQuote({
      state,
      commit,
      dispatch,
    }: ActionContext<IDesignState, any>) {
      try {
        const designData = designMapper.mapToDTO(state);
        const quoteDTO = await getQuote(designData);
        const { total, items } = await quoteMapper.mapFromDTO(quoteDTO);

        commit('setTotalPrice', { total });
        commit('setQuoteItems', items);
      } catch (e) {
        console.error(e);
        await dispatch(
          'addAlert',
          {
            message: 'Failed to calculate price',
            color: AlertColor.WARNING,
          },
          {
            root: true,
          },
        );
      }
    },

    async loadDesignById(
      ctx: ActionContext<IDesignState, any>,
      designId: string,
    ) {
      const { commit, dispatch } = ctx;

      if (!designId) return;

      commit('setLoading', true, { root: true });
      commit('setLoadDesignMode', true);

      try {
        const designDTO = await getDesignById(designId);
        await dispatch('loadDesign', {
          designId,
          designDTO,
        });
      } catch (e) {
        console.error(e);
      } finally {
        commit('setLoading', false, { root: true });
      }
    },

    async loadDesign(
      ctx: ActionContext<IDesignState, any>,
      data: { designId: string; designDTO: DesignDTO },
    ) {
      const { commit, dispatch } = ctx;

      commit('setLoading', true, { root: true });
      commit('setLoadDesignMode', true);

      try {
        const state = await designMapper.mapFromDTO(data.designDTO);
        commit('setState', state);
        commit('setDesignId', data.designId);

        await dispatch('getQuote');
      } catch (e) {
        console.error(e);
      } finally {
        commit('setLoading', false, { root: true });
      }
    },

    async onDesignLoaded({ commit }: ActionContext<IDesignState, any>) {
      commit('setLoadDesignMode', false);
    },

    async genFirstOutput({
      state,
      commit,
      rootGetters,
    }: ActionContext<IDesignState, any>) {
      const turnedObjects = await turnObjectsToSide(
        OutputType.First,
        state.productObjects,
        state.safeAreas,
        state.texts,
        rootGetters['canvasSize'],
      );

      commit('setOutputState', {
        productObjects: [...turnedObjects.products],
        texts: [...turnedObjects.texts],
        safeAreas: [...turnedObjects.safeAreas],
        outputType: OutputType.First,
      });
    },

    async genSecondOutput({
      state,
      commit,
      rootGetters,
    }: ActionContext<IDesignState, any>) {
      const turnedObjects = await turnObjectsToSide(
        OutputType.Second,
        state.productObjects,
        state.safeAreas,
        state.texts,
        rootGetters['canvasSize'],
      );

      commit('setOutputState', {
        productObjects: [...turnedObjects.products],
        texts: [...turnedObjects.texts],
        safeAreas: [...turnedObjects.safeAreas],
        outputType: OutputType.Second,
      });
    },

    setOutput(
      ctx: ActionContext<IDesignState, any>,
      data: { type: OutputType; output: string },
    ) {
      const { commit } = ctx;

      switch (data.type) {
        case OutputType.First:
          commit('setFirstOutput', data.output);
          break;
        case OutputType.Second:
          commit('setSecondOutput', data.output);
      }
    },

    updateCanvasZoom({ commit }, { zoom }) {
      commit('canvasZoom', zoom);
    },

    ...productActions,
    ...textActions,
  };

  mutations: MutationTree<IDesignState> = {
    setSelectedId(
      state: IDesignState,
      payload: DesignMutationPayload<{ id: string | null }>,
    ) {
      state.selectedId = payload.id;
    },

    setTotalPrice(
      state: IDesignState,
      payload: DesignMutationPayload<{ total: number }>,
    ) {
      state.totalPrice = payload.total;
    },

    setQuoteItems(state: IDesignState, value: QuoteItem[]) {
      state.quoteItems = value;
    },

    setPrevState(
      state: IDesignState & { prev?: IDesignState },
      value?: IDesignState,
    ) {
      if (value) {
        state.prev = {
          selectedId: state.selectedId,
          productObjects: [...state.productObjects],
          texts: [...state.texts],
          safeAreas: [...state.safeAreas],
          totalPrice: state.totalPrice,
          cartPreview: state.cartPreview,
          genCartPreview: state.genCartPreview,
          quoteItems: [...state.quoteItems],
          canvasZoom: state.canvasZoom,
        };
      } else {
        state.prev = value;
      }
    },

    setState(state: IDesignState, value: IDesignState) {
      state.safeAreas = value.safeAreas;
      state.productObjects = value.productObjects;
      state.texts = value.texts;
      state.selectedId = value.selectedId;
      state.totalPrice = value.totalPrice;
    },

    setCartPreview(state: IDesignState, cartPreview: string) {
      state.cartPreview = cartPreview;
    },

    setGenCartPreview(state: IDesignState) {
      state.genCartPreview = !state.genCartPreview;
    },

    setOutputState(state: IDesignState, value?: OutputState) {
      state.outputState = value;
    },

    setFirstOutput(state: IDesignState, base64Output: string) {
      state.firstOutput = base64Output;
    },

    setSecondOutput(state: IDesignState, base64Output: string) {
      state.secondOutput = base64Output;
    },

    setDesignId(state: IDesignState, designId: string) {
      state.designId = designId;
    },

    setLoadDesignMode(state: IDesignState, loadDesignMode: boolean) {
      state.loadDesignMode = loadDesignMode;
    },

    setIsLoading(state: IDesignState, loading: boolean) {
      state.isLoading = loading;
    },

    canvasZoom(state, value: number | 'FIT') {
      state.canvasZoom = value;
    },

    ...productMutations,
    ...textMutations,
  };
}

export const design = new DesignModule();
