import { Mapper } from '@/models/mappers/Mapper';
import { Quote } from '@/models/quote/Quote';
import { QuoteDTO } from '@/api/dto/QuoteDTO';

export class QuoteMapper extends Mapper<Quote, QuoteDTO> {
  async mapFromDTO(quoteDTO: QuoteDTO): Promise<Quote> {
    return new Quote({
      ...(<Partial<Quote>>quoteDTO),
    });
  }

  mapToDTO(quote: Quote): QuoteDTO {
    return {
      ...quote,
    };
  }
}

export const quoteMapper = new QuoteMapper();
