import { Mapper } from '@/models/mappers/Mapper';
import { CatalogItemDTO } from '@/api/dto/CatalogItemDTO';
import { SizeDTO } from '@/api/dto/SizeDTO';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { Size } from '@/models/catalog/product/Size';
import { Location } from '@/models/catalog/product/Location';
import { LocationDTO } from '@/api/dto/LocationDTO';
import { LocationDetails } from '@/models/catalog/product/LocationDetails';

export class CatalogItemMapper extends Mapper<CatalogItem, CatalogItemDTO> {
  async mapFromDTO(catalogItemDto: CatalogItemDTO): Promise<CatalogItem> {
    return new CatalogItem({
      ...(<Partial<CatalogItem>>catalogItemDto),
      sizes: catalogItemDto.sizes
        ? catalogItemDto.sizes.map((size: SizeDTO) => {
            return new Size({
              ...size,
              locationDetails: size.locationDetails.map(locImage => {
                return new LocationDetails({
                  ...locImage,
                });
              }),
            });
          })
        : [],
      locations: catalogItemDto.locations
        ? catalogItemDto.locations.map((location: LocationDTO) => {
            return new Location(location);
          })
        : [],
    });
  }

  mapToDTO(catalogItem: CatalogItem): CatalogItemDTO {
    return {
      ...catalogItem,
    };
  }
}

export const catalogItemMapper = new CatalogItemMapper();
