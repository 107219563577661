export const messages = {
  en: {
    common: {
      ring: 'ring | rings',
      chain: 'chain | chains',
      clasp: 'clasp | clasps',
      charm: 'charm | charms',
      engraving: 'engraving | engravings',
    },
    buttons: {
      no: 'No',
      yes: 'Yes',
      add: 'add',
      back: 'back',
      apply: 'apply',
      select: 'Select',
      cancel: 'Cancel',
      confirm: 'Confirm',
      seeMore: 'see more',
      addToBag: 'add to bag',
      engraving: 'Engraving',
      customize: 'Customize',
      startOver: 'start over',
      addEngraving: 'Add Engraving',
      editEngraving: 'Edit Engraving',
      deleteEngraving: 'Delete Engraving',
    },
    titles: {
      symbols: 'SYMBOLS',
      details: 'Details',
      engraving: 'Engraving',
      selectSize: 'SELECT SIZE',
      viewMore: {
        default: 'select {objectType}',
        component: {
          category: 'charm categories',
          product: 'double tap to add charm',
        },
      },
      catalog: {
        selectBaseProduct: 'Select your <br /> {objectType}',
        selectCategoryCreate: 'What would you <br /> like to create?',
        selectCategoryBegin: 'Select {category} to begin',
      },
    },
    engravingTab: {
      inputPlaceholder: 'Type Something',
      selectFont: 'Select Font',
    },
    prompts: {
      turnDevice: 'Please turn your device',
      changeProductDialog:
        'Choosing a different base product will erase all added components. Are you sure you want to proceed?',
    },
  },
};
