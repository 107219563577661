import Vue from 'vue';
import VueRouter from 'vue-router';
import { RouteConfig, RouterOptions } from 'vue-router';
import tabRoutes from './tabRoutes';

import CartView from '@/components/views/CartView.vue';
import ViewMoreTab from '@/components/tabs/ViewMoreTab.vue';
import DesignerView from '@/components/main/DesignerView.vue';
import CategoriesView from '@/components/views/CategoriesView.vue';
import { ROUTES } from '@/router/constants';
import { viewMoreBeforeEnter } from '@/router/guards';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: ROUTES.root.path,
    name: ROUTES.root.path,
    component: DesignerView,
    children: [...tabRoutes],
  },
  {
    path: ROUTES.selectCategory.path,
    name: ROUTES.selectCategory.name,
    component: CategoriesView,
  },
  {
    path: ROUTES.cart.path,
    name: ROUTES.cart.name,
    component: CartView,
  },
  {
    path: ROUTES.viewMore.path,
    name: ROUTES.viewMore.name,
    component: ViewMoreTab,
    beforeEnter: viewMoreBeforeEnter,
  },
];

const router = new VueRouter({
  routes: routes,
  mode: 'abstract',
} as RouterOptions);

export default router;
