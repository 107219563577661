
















import { defineComponent } from '@vue/composition-api';

const Component = defineComponent({
  name: 'PreloaderOverlay',

  props: {
    show: Boolean,
    text: String,
  },
});

export default Component;
