import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import BaseProductTab from '@/components/tabs/BaseProductTab.vue';
import TextTab from '@/components/tabs/TextTab.vue';
import { setDialog } from '@/utils/dialogs';
import { DesignObjectType } from '@/models/DesignObjectType';
import { ROUTES } from '@/router/constants';
import { messages } from '@/i18n/messages';

type GuardInstance<T extends { data?: unknown; store?: unknown }> =
  T['data'] & {
    $store: T['store'];
  };

/**
 * The guard opens confirmation dialog and clear the store before leaving catalog tab view.
 */
export function catalogTabBeforeRouteLeave(
  to: Route,
  _: Route,
  next: NavigationGuardNext<Vue>,
) {
  const instance = this as GuardInstance<typeof BaseProductTab>;

  const leavePage = (isConfirmed: boolean) => {
    if (isConfirmed) {
      if (to.name === ROUTES.selectCategory.name) {
        instance.$store?.dispatch(
          `${DesignObjectType.BASE}/setSelectedItem`,
          null,
        );
        instance.$store?.dispatch(
          `${DesignObjectType.BASE}/setSelectedCategory`,
          null,
        );
        instance.$store?.dispatch(
          `${DesignObjectType.CLOSURE}/setSelectedItem`,
          null,
        );
        instance.$store?.dispatch(
          `${DesignObjectType.COMPONENT}/setSelectedItem`,
          null,
        );
        instance.$store?.dispatch(`design/resetState`);
      }
      next();
    } else {
      next(false);
    }
  };

  const shouldSkip =
    instance.$store?.getters['dialog'] ||
    [
      ROUTES.closure.name,
      ROUTES.component.name,
      ROUTES.viewMore.name,
      ROUTES.textTab.name,
      ROUTES.detailsTab.name,
    ].includes(to.name ?? '');

  if (shouldSkip) {
    next();
  } else {
    instance.$store?.dispatch(
      'showDialog',
      setDialog(messages.en.prompts.changeProductDialog, leavePage),
    );
  }
}

/**
 * The guard redirects to categoriesTab view if catalog tab view was opened not from app routes.
 * It is particularly useful when routing back from the static cart page.
 */
export function catalogTabBeforeEnter(
  _: Route,
  from: Route,
  next: NavigationGuardNext<Vue>,
) {
  if (!from.name) {
    next({ name: ROUTES.catalogTab.name });
  } else next();
}

/**
 * The guard makes possible to route to the view more tab only from particular routes with correct params.
 */
export const viewMoreBeforeEnter: RouteConfig['beforeEnter'] = (
  to,
  from,
  next,
) => {
  const nullFromName = !from.name;

  const invalidFromName = ![
    ROUTES.baseProduct.name,
    ROUTES.closure.name,
    ROUTES.component.name,
  ].includes(from.name ?? '');

  const invalidParams = ![
    DesignObjectType.BASE,
    DesignObjectType.CLOSURE,
    DesignObjectType.COMPONENT,
  ].includes(to.params.designObjectType as DesignObjectType);

  if (nullFromName || invalidFromName || invalidParams) {
    next({ path: from.path });
  } else {
    next();
  }
};

/** Select parent object when leaving text tab */
export function textTabBeforeLeave(
  to: Route,
  _: Route,
  next: NavigationGuardNext<Vue>,
) {
  type Instance = (typeof TextTab)['data'] & {
    $store: (typeof TextTab)['store'];
  };

  const instance = this as Instance;
  instance.$store?.dispatch('design/selectParentObject');
  next();
}
