








import { defineComponent } from '@vue/composition-api';
const Component = defineComponent({
  name: 'SidebarTab',
});

export default Component;
