




























import {
  computed,
  defineComponent,
  onActivated,
  onDeactivated,
  reactive,
  SetupContext,
} from '@vue/composition-api';
import CanvasArea from '@/components/main/CanvasArea.vue';
import BottomBar from '@/components/main/BottomBar.vue';
import SidebarTab from '@/components/main/SidebarTab.vue';
import CanvasAreaActions from '@/components/elements/CanvasAreaActions.vue';

interface State {
  showSideBar: boolean;
  isExtraSmallScreen: boolean;
}

const Component = defineComponent({
  name: 'MobileLayout',

  components: {
    CanvasArea,
    BottomBar,
    SidebarTab,
    CanvasAreaActions,
  },

  setup(_, { root: { $store } }: SetupContext) {
    onActivated(() => {
      if (state.isExtraSmallScreen) $store.dispatch('showSideBar', false);
    });

    onDeactivated(() => {
      if (state.isExtraSmallScreen) $store.dispatch('showSideBar', false);
    });

    const state = reactive({
      showSideBar: computed({
        get: () => $store.getters['showSideBar'],
        set: value => $store.dispatch('showSideBar', value),
      }),
      isExtraSmallScreen: computed(() => $store.getters['isExtraSmallScreen']),
    }) as State;

    return {
      state,
    };
  },
});
export default Component;
