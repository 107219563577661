import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { DesignObjectType } from '@/models/DesignObjectType';
import { Size } from '@/models/catalog/product/Size';
import { setDialog } from '@/utils/dialogs';
import { ComputedRef, computed } from '@vue/composition-api';
import { Store } from 'vuex';
import { RootState } from '@/store/store';
import { messages } from '@/i18n/messages';

export const useChangeBaseProduct = (
  store: Store<RootState>,
  isDefaultProductChosen: ComputedRef<boolean>,
) => {
  const openConfirmationDialog = (
    onConfirm: (isConfirmed: boolean) => unknown,
  ) => {
    store.dispatch(
      'showDialog',
      setDialog(messages.en.prompts.changeProductDialog, onConfirm),
    );
  };

  const openSizeDialog = (
    item: CatalogItem,
    onConfirm?: (isConfirmed: boolean) => unknown,
  ) => {
    store.dispatch(`${DesignObjectType.BASE}/showSizeDialog`, {
      item: item,
      onConfirm: onConfirm,
    });
  };

  const changeBaseProduct = computed(
    () =>
      (
        selectedItem: CatalogItem,
        selectedProduct: CatalogItem | null,
        selectedSize: Size | null,
        close?: Function,
      ) => {
        if (selectedItem.id === selectedProduct?.id) return;
        const size = selectedSize;
        const isSelectedItemHasSameSize = selectedItem.sizes.some(
          ({ name }) => name === size?.name,
        );

        const changeItemWithSameSize = () => {
          if (isDefaultProductChosen.value) {
            store.dispatch(
              `${DesignObjectType.BASE}/setSelectedItem`,
              selectedItem,
            );
            close && close();
          } else {
            const changeProduct = (isConfirmed: boolean) => {
              if (!isConfirmed) return;
              store.dispatch(
                `${DesignObjectType.BASE}/setSelectedItem`,
                selectedItem,
              );
              close && close();
            };

            openConfirmationDialog(changeProduct);
          }
        };

        if (!isSelectedItemHasSameSize) {
          openSizeDialog(
            selectedItem,
            (isConfirmed: boolean) => isConfirmed && close && close(),
          );
        } else {
          changeItemWithSameSize();
        }
      },
  );

  return changeBaseProduct;
};
