import { computed, reactive, ref } from '@vue/composition-api';
import { useStore } from './useStore';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { DesignObjectType } from '@/models/DesignObjectType';

/**
 * Check if design ws updated by user.
 * Used to notify user about losing progress.
 * Design is assumed to be default if all default products are on the canvas and no other customizations.
 * TODO: improvement: this composable should be updated to compare design actual states
 *
 * @returns
 */
export const useDefaultProductChosen = () => {
  const store = useStore();

  return computed(() => {
    const currentClosure: CatalogItem | null =
      store.getters[`${DesignObjectType.CLOSURE}/selectedItem`];
    const defaultClosure: CatalogItem | null =
      store.getters[`${DesignObjectType.CLOSURE}/selectedItems`]?.[0];

    const isComponentChanged = store.getters[`design/components`]?.length;
    const isClosureChanged = currentClosure?.id !== defaultClosure?.id;
    const itTextChanged = store.getters[`design/texts`]?.length;

    return !isComponentChanged && !isClosureChanged && !itTextChanged;
  });
};
