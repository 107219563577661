













import { DesignObjectType } from '@/models/DesignObjectType';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { DesignObject } from '@/models/designObject/DesignObject';
import { ProductObject } from '@/models/designObject/ProductObject';
import { ROUTES } from '@/router/constants';
import { formatCurrency } from '@/utils/currencyUtils';
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  SetupContext,
} from '@vue/composition-api';

interface State {
  /** Object selected on canvas. can potentially be a product obj or text obj */
  selectedObj: DesignObject | null;
  /** Product obj on canvas which is selected or which text is selected */
  selectedProductObj: ProductObject | null;
  /**
   * Product object on canvas that corresponds to the selected passed catalog item. \
   * Used for enabling Engraving button for rings.
   */
  catalogItemProductObject: ProductObject | null;
}

const Component = defineComponent({
  name: 'AddEngravingButton',
  props: {
    item: Object as PropType<CatalogItem>,
    designObjectType: {
      type: String as PropType<DesignObjectType>,
      default: '',
    },
  },

  setup(props, { root: { $store, $router, $i18n } }: SetupContext) {
    const state = reactive({
      selectedProductObj: computed(
        () => $store.getters[`design/selectedProductObject`],
      ),
      selectedObj: computed(() => $store.getters[`design/selectedObject`]),
      catalogItemProductObject: computed(() =>
        $store.getters[`design/productObjects`].find(
          ({ product: { id } }: ProductObject) => id === props.item?.id,
        ),
      ),
    }) as State;

    // Show button if passed catalog item allows text
    // Or if passed catalog item is a component
    const isVisible = computed(
      () =>
        props.designObjectType === DesignObjectType.COMPONENT ||
        props.item?.isTextAllowed ||
        false,
    );

    // Enable text button if selected obj on canvas allows text or is a text itself
    const isDisabled = computed(() => {
      // enable for rings
      if (props.item?.isRing && state.catalogItemProductObject) return false;

      // enable if text is selected
      if (state.selectedObj && state.selectedObj.type === DesignObjectType.TEXT)
        return false;

      if (!state.selectedProductObj) return true;
      return !state.selectedProductObj.product.isTextAllowed;
    });

    const title = computed(() => {
      if (!state.selectedObj) return $i18n.t('buttons.addEngraving');
      if (state.selectedObj.type === DesignObjectType.TEXT)
        return $i18n.t('buttons.editEngraving');

      const product = state.selectedProductObj;
      if (!product) return $i18n.t('buttons.addEngraving');
      // check if some locations already have text added
      const editText = product.product.locations.some(l =>
        $store.getters[`design/getTextByObjLocationId`]({
          parentId: product.id,
          locationId: l.id,
        }),
      );
      return editText
        ? $i18n.t('buttons.editEngraving')
        : $i18n.t('buttons.addEngraving');
    });

    const addText = async () => {
      if (props.item?.isRing) {
        await $store.dispatch(
          'design/selectObject',
          state.catalogItemProductObject?.id,
        );
      }

      $router.push(ROUTES.textTab.path);
    };

    return {
      state,
      formatCurrency,
      isVisible,
      isDisabled,
      title,
      addText,
    };
  },
});

export default Component;
