import { DesignObjectType } from '@/models/DesignObjectType';
import { BehaviourOptions, BoundingBox } from '@/models/designObject/types';
import { v4 as uuid_v4 } from 'uuid';

export type DesignObjectData = Partial<Omit<DesignObject, 'id' | 'type'>>;

export class DesignObject {
  public readonly id: string;
  public readonly type: DesignObjectType;

  private _behaviourOptions: BehaviourOptions = {
    movable: false,
    resizable: false,
    rotatable: false,
    selectable: false,
    deletable: false,
  };

  //canvas properties
  private _transformMatrix?: number[];
  private _boundingBox?: BoundingBox;

  private _parentId?: string;

  public constructor(type: DesignObjectType, parentId?: string, id?: string) {
    this.type = type;
    this._parentId = parentId;
    this.id = id || uuid_v4();
  }

  get parentId(): string | undefined {
    return this._parentId;
  }

  get transformMatrix(): number[] | undefined {
    return this._transformMatrix;
  }

  set transformMatrix(value: number[] | undefined) {
    this._transformMatrix = value;
  }

  get boundingBox(): BoundingBox | undefined {
    return this._boundingBox;
  }

  get behaviourOptions(): BehaviourOptions {
    return this._behaviourOptions;
  }

  set behaviourOptions(options: BehaviourOptions) {
    this._behaviourOptions = options;
  }

  set boundingBox(value: BoundingBox | undefined) {
    if (value) {
      this._boundingBox = { ...value };
      return;
    }
    this._boundingBox = value;
  }

  public updateProps(data: DesignObjectData) {
    Object.assign(this, data);
  }
}
