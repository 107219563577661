








import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  SetupContext,
  watch,
} from '@vue/composition-api';
import FabricCanvas, { PaddingUnit } from '@/canvas/fabric/FabricCanvas';
import { watchCanvasEvents } from '@/canvas/canvasWatchers/canvasWatchers';
import { watchProductObjects } from '@/canvas/stateWatchers/productObjectsWatch';
import { watchTexts } from '@/canvas/stateWatchers/textsWatch';
import { watchSafeAreaObjects } from '@/canvas/stateWatchers/safeAreasWatch';
import { watchOutputState } from '@/canvas/stateWatchers/outputStateWatch';
import { watchGenCartPreview } from '@/canvas/stateWatchers/genCartPreviewWatch';
import { useElementSize } from '@vueuse/core';
import { debounce } from 'lodash';
import { watchRestoreCanvasState } from '@/canvas/stateWatchers/restoreCanvasWatch';
import { selectElement } from '@/canvas/stateWatchers/selectedWatch';
import { Dimensions } from './types';
import { watchViewportChange } from './stateWatchers/zoomWatch';

export interface State {
  canvas: FabricCanvas | null;
}

const Component = defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Canvas',

  setup(_, { root: { $store } }: SetupContext) {
    const htmlCanvas = ref<HTMLCanvasElement | null>(null);
    const canvasRoot = ref(null);
    const isMobile = computed(() => $store.getters.isMobile);

    const size = reactive(
      useElementSize(
        canvasRoot,
        { width: 250, height: 250 },
        { box: 'border-box' },
      ),
    );

    const state = reactive({
      canvas: null,
    }) as State;

    onMounted(() => {
      if (!htmlCanvas.value) return;
      const canvas = new FabricCanvas(
        htmlCanvas.value,
        {},
        {
          setupControls: true,
          padding: { value: 5, unit: PaddingUnit.PERCENT },
          enableZoom: true,
          workingArea: $store.getters.canvasSize,
        },
      );
      canvas.setCanvasSize($store.getters.canvasSize);
      state.canvas = canvas;

      watchProductObjects(canvas);
      watchTexts(canvas);
      watchCanvasEvents(canvas);
      watchSafeAreaObjects(canvas);
      watchGenCartPreview(canvas);
      watchOutputState(canvas);
      watchViewportChange(canvas);
      debouncedSizeWatch(size);
    });

    watchRestoreCanvasState(state);

    // watch for selected object in store
    // as it can be set programmatically
    watch(
      () => $store.getters['design/selectedId'],
      (newVal, oldVal) => {
        if (!state.canvas) return;
        if (newVal === oldVal) return;
        selectElement($store, state.canvas);
      },
    );

    const debouncedSizeWatch = debounce((size: Dimensions) => {
      if (!state.canvas) return;
      if (!size) return;
      state.canvas.scaleToSize(size, $store.getters.canvasSize);
    }, 200);

    watch(size, debouncedSizeWatch);

    onBeforeUnmount(() => {
      debouncedSizeWatch.cancel();
    });

    watch(isMobile, (isMobile: boolean) => {
      if (!isMobile) return;
      if (!state.canvas) return;
      // state.canvas.setupForSmallScreens(); // todo: add action for edit button
    });

    return {
      state,
      htmlCanvas,
      canvasRoot,
    };
  },
});

export default Component;
