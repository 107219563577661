import { ProductObject } from '@/models/designObject/ProductObject';
import { Dimensions } from '@/canvas/types';
import { getProductBoundingBox } from '@/calculateProperties/helpers/boundingBox';
import { getScaleFactor } from '@/calculateProperties/helpers/scaleFactor';

/**
 * Calculate transformations of ring group objects based on their sizes in px
 * to correctly position objects within a group
 */
export async function getRingTransforms(
  designProduct: ProductObject,
  canvasSize: Dimensions,
  // baseProduct?: ProductObject,
) {
  if (!designProduct.product.isRing)
    throw `Passed object #${designProduct.id} is not a ring`;

  const currPPI = (await designProduct?.getPPI()) || 0;

  //get images sizes
  const thumbSize = await designProduct.getThumbSize();
  const locationSize = await designProduct.locationImageSize();

  //calc desired thumb size
  const thumbWidth = locationSize.width / 2;
  const thumbHeight = (thumbSize.height / thumbSize.width) * thumbWidth;

  //calc paddings
  const mainPadding = thumbWidth / 2;
  const locationsPadding = 50;

  //whether to put objects in one row or in two
  const row = arrangeInRow(locationSize, canvasSize);

  //get height of all locations
  const locationsHeight =
    (locationSize?.height || 0) * designProduct.product.locations.length +
    (designProduct.product.locations.length - 1) * locationsPadding;

  //calc width & height of all objects combined
  const fullWidth = row
    ? thumbWidth + (locationSize?.width || 0) + mainPadding
    : Math.max(thumbWidth, locationSize?.width || 0);
  const fullHeight = row
    ? Math.max(thumbHeight, locationsHeight || 0)
    : thumbHeight * 1.5 + locationsHeight;

  //calc transform matrix for locations group
  const locationsPos = row
    ? {
        left: fullWidth - locationSize.width,
        top: (fullHeight - locationsHeight) / 2,
      }
    : {
        left: 0,
        top: fullHeight - locationsHeight,
      };

  const thumbTransformMatrix = getThumbTransformMatrix(
    thumbSize,
    { width: thumbWidth, height: thumbHeight },
    { width: fullWidth, height: fullHeight },
    row,
  );

  // calc transform matrix for every location object
  const locsMatrices: any = {};
  designProduct.product.locations.forEach((loc, i) => {
    locsMatrices[loc.id] = [
      1,
      0,
      0,
      1,
      locationsPos.left + locationSize.width / 2,
      locationsPos.top +
        (locationSize.height + locationsPadding) * i +
        locationSize.height / 2,
    ];
  });

  const locsBoundingBoxes: any = {};
  designProduct.product.locations.forEach((loc, i) => {
    locsBoundingBoxes[loc.id] = {
      width: locationSize.width,
      height: locationSize.height,
      left: locsMatrices[loc.id][4] - locationSize.width / 2,
      top: locsMatrices[loc.id][5] - locationSize.height / 2,
    };
  });

  //calc scale for the whole group
  const scaleFactor = getScaleFactor(
    designProduct,
    {
      width: fullWidth,
      height: fullHeight,
    },
    canvasSize,
    currPPI,
  );

  //calc transform matrix for the whole group
  let transformMatrix = designProduct.transformMatrix;
  if (!transformMatrix) {
    transformMatrix = [
      scaleFactor,
      0,
      0,
      scaleFactor,
      canvasSize.width / 2,
      canvasSize.height / 2,
    ];
  }

  const boundingBox = await getProductBoundingBox(
    designProduct,
    transformMatrix,
    {
      width: fullWidth,
      height: fullHeight,
    },
  );

  return {
    transformMatrix,
    thumbTransformMatrix,
    locsMatrices,
    boundingBox,
    locsBoundingBoxes,
  };
}

function arrangeInRow(locationSize: Dimensions, canvasSize: Dimensions) {
  return canvasSize.width > 1.75 * (locationSize?.width || 0);
}

function getThumbTransformMatrix(
  originalThumbSize: Dimensions,
  desiredThumbSize: Dimensions,
  fullSize: Dimensions,
  row: boolean,
) {
  const thumbScale = desiredThumbSize.width / originalThumbSize.width;
  const thumbPos = {
    centerX: row
      ? desiredThumbSize.width / 2
      : (fullSize.width - desiredThumbSize.width) / 2 +
        desiredThumbSize.width / 2,
    centerY: row
      ? (fullSize.height - desiredThumbSize.height) / 2 +
        desiredThumbSize.height / 2
      : desiredThumbSize.height / 2,
  };
  return [thumbScale, 0, 0, thumbScale, thumbPos.centerX, thumbPos.centerY];
}
