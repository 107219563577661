var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-overlay',{ref:"content",attrs:{"value":_vm.value}},[_c('v-sheet',{staticClass:"white pa-2",attrs:{"light":""}},[_c('strong',[_vm._v("Window Outer Size: ")]),_c('br'),_vm._v(" "+_vm._s(_vm.windowOuterSize.width)+" × "+_vm._s(_vm.windowOuterSize.height)+" px "),_c('br'),_c('strong',[_vm._v("Window Inner Size: ")]),_c('br'),_vm._v(" "+_vm._s(_vm.windowInnerSize.width)+" × "+_vm._s(_vm.windowInnerSize.height)+" px "),_c('br'),_c('strong',[_vm._v("Body Size: ")]),_c('br'),_vm._v(" "+_vm._s(_vm.bodySize.width)+" × "+_vm._s(_vm.bodySize.height)+" px "),_c('br'),_c('v-btn',{staticClass:"hide",attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('change', false)}}},[_vm._v(" Hide ")])],1),_vm._l((_vm.widths),function(s,i){return _c('div',{key:s.name + '-width',class:("bar h  orange lighten-" + i),style:({
      width: s.width,
      left: 0,
      top: 25 * i + 10 + 'px',
    })},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(s.name)+": "+_vm._s(s.width))])])}),_vm._l((_vm.heights),function(s,i){return _c('div',{key:s.name + '-height',class:("bar v cyan lighten-" + i),style:({
      height: s.height,
      top: 0,
      right: 25 * i + 10 + 'px',
    })},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(s.name)+": "+_vm._s(s.height))])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }