import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import {
  DesignMutationPayload,
  IDesignState,
} from '@/store/modules/design/types';
import {
  ProductObject,
  ProductObjectData,
} from '@/models/designObject/ProductObject';
import {
  createObjSafeAreas,
  getProductObjectById,
  getSafeAreasByObjId,
} from '@/utils/storeUtils';
import { DesignObjectType } from '@/models/DesignObjectType';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { productProps } from '@/calculateProperties/productProps';
import { cloneDeep } from 'lodash';
import {
  SafeAreaObject,
  SafeAreaObjectData,
} from '@/models/designObject/SafeAreaObject';
import { DesignObject } from '@/models/designObject/DesignObject';
import { safeAreaProps } from '@/calculateProperties/safeAreaProps';
import { TextObject } from '@/models/designObject/TextObject';

export const productGetters: GetterTree<IDesignState, any> = {
  productObjects(state): ProductObject[] {
    return [...state.productObjects];
  },

  safeAreas(state): SafeAreaObject[] {
    return [...state.safeAreas];
  },

  /**
   * Get selected product object
   * If selected object is not product, returns parent product
   */
  selectedProductObject(state, getters): ProductObject | null {
    if (!state.selectedId) return null;
    const selectedId = state.selectedId;
    let currObj = getProductObjectById(state.productObjects, selectedId);
    if (!currObj) {
      currObj = getters.selectedParentProductObject;
    }
    return currObj || null;
  },

  baseProduct(state): ProductObject | null {
    return (
      state.productObjects.find(
        productObject => productObject.type === DesignObjectType.BASE,
      ) || null
    );
  },

  baseSafeArea(state, getters): SafeAreaObject | null {
    const baseProductId = getters.baseProduct?.id || '';
    const safeArea = state.safeAreas.find(
      safeArea => safeArea.parentId === baseProductId,
    );
    return safeArea || null;
  },

  closure(state): ProductObject | null {
    return (
      state.productObjects.find(
        productObject => productObject.type === DesignObjectType.CLOSURE,
      ) || null
    );
  },

  components(state): ProductObject[] {
    return state.productObjects.filter(
      productObj => productObj.type === DesignObjectType.COMPONENT,
    );
  },

  allowClosure(state, getters): boolean {
    const baseProduct = getters.baseProduct;
    return !!baseProduct && !baseProduct.product.finished;
  },

  allowComponents(state, getters): boolean {
    const baseProduct = getters.baseProduct;
    return !!baseProduct && baseProduct.product.allowComponents;
  },

  getObjectIndex(state, getters): (obj: DesignObject) => number | null {
    return (obj: DesignObject) => {
      let index = -1;
      // safe area always at the back
      if (
        obj.type === DesignObjectType.SAFE_RECT ||
        obj.type === DesignObjectType.SAFE_LINE
      ) {
        index = getters.safeAreas.findIndex(
          (safeArea: SafeAreaObject) => safeArea.id === obj.id,
        );
      } else {
        // else it's product
        index = getters.productObjects.findIndex(
          (productObject: DesignObject) => productObject.id === obj.id,
        );
        const prevSafeAreas = getSafeAreasByObjId(state.safeAreas, obj.id);
        // if obj has safe areas and they are not yet included in the store, add to the index manually
        if (
          (!prevSafeAreas || !prevSafeAreas.length) &&
          (obj as ProductObject).currSafeArea()
        ) {
          const product = obj as ProductObject;
          const safeAreasQuantity = product.product.isRing
            ? product.locationSafeAreas().length
            : 1;
          index += safeAreasQuantity;
        }

        // if component updated the location, and the second safe area is not included, add it manually
        const isComponent = obj.type === DesignObjectType.COMPONENT;
        const isOnePrevSaveArea = prevSafeAreas.length === 1;
        if (isComponent && isOnePrevSaveArea) {
          const currComponent = obj as ProductObject;
          const prevComponent = state.prev?.productObjects.find(
            ({ id }) => id === obj.id,
          );

          if (
            prevComponent &&
            currComponent.locationId !== prevComponent.locationId
          ) {
            index++;
          }
        }

        // safe areas are always at the back
        index = state.safeAreas.length + index;
      }
      return index !== -1 ? index : null;
    };
  },

  getProductObjById(state) {
    return (id: string): ProductObject | null => {
      return state.productObjects.find(obj => obj.id === id) || null;
    };
  },

  /**
   * Get parent product object from selected
   * If no parent, return current product object
   */
  selectedParentProductObject(state, getters, rootState, rootGetters) {
    if (!state.selectedId) return null;
    const obj: DesignObject = rootGetters['design/selectedObject'];
    if (!obj) return null;
    const parentObj = state.productObjects.find(o => o.id === obj.parentId);
    const currentObj = getProductObjectById(state.productObjects, obj.id);
    return parentObj ?? currentObj ?? null;
  },
};

export const productActions: ActionTree<IDesignState, any> = {
  setProductObjects(
    { state, commit }: ActionContext<IDesignState, any>,
    components: ProductObject[],
  ) {
    commit('setPrevState', state);
    commit('setProductObjects', {
      value: components,
    });
  },

  async updateProductObject(
    {
      state,
      getters,
      commit,
      dispatch,
      rootGetters,
    }: ActionContext<IDesignState, any>,
    data: { id: string; data: ProductObjectData },
  ) {
    const prevObject = getProductObjectById(state.productObjects, data.id);
    if (!prevObject) return null;

    commit('setPrevState', state);

    const productImageChanged =
      prevObject.sizeId !== data.data?.sizeId ||
      prevObject.locationId !== data.data?.locationId;

    if (productImageChanged) commit('setLoading', true, { root: true });

    try {
      await dispatch('updateProduct', data);
    } catch (e) {
      await dispatch('revertToPrevState');
    } finally {
      if (productImageChanged) {
        dispatch('getQuote');
      }
      commit('setLoading', false, { root: true });
    }
  },

  // "private" action used by updateProductObject action
  async updateProduct(
    {
      state,
      getters,
      commit,
      dispatch,
      rootGetters,
    }: ActionContext<IDesignState, any>,
    data: { id: string; data: ProductObjectData },
  ) {
    const prevObject = getProductObjectById(state.productObjects, data.id);
    if (!prevObject) return null;

    const updatedObject = cloneDeep(prevObject);
    updatedObject.updateProps(data.data);

    const isBaseProduct = updatedObject.type === DesignObjectType.BASE;

    const props = await productProps(
      updatedObject,
      rootGetters['canvasSize'],
      getters['baseProduct'],
      // no need to specify base product safe area for base product itself
      isBaseProduct ? undefined : getters.baseSafeArea,
    );
    updatedObject.updateProps(props);
    commit('updateProductObject', {
      object: updatedObject,
    });

    // update product safe area
    await dispatch('addUpdateSafeAreas', updatedObject);

    //update text connected to product
    await dispatch('design/updateTextByParent', updatedObject, {
      root: true,
    });

    const productImageChanged =
      prevObject.sizeId !== data.data?.sizeId ||
      prevObject.locationId !== data.data?.locationId;

    if (isBaseProduct && productImageChanged) {
      // update closure when base product image changed
      if (getters['allowClosure']) {
        const currClosure = getters.closure;
        await dispatch('updateProduct', { id: currClosure.id, data: {} });
      }
      // update components if base product changed
      const components = getters.components;
      await Promise.all(
        components.map((comp: ProductObject) => {
          return dispatch('updateProduct', { id: comp.id, data: {} });
        }),
      );
    }
  },

  async updateSafeAreaObject(
    ctx: ActionContext<IDesignState, any>,
    data: { id: string; data: SafeAreaObjectData },
  ) {
    const { state, commit, getters } = ctx;

    const prevSafeArea = getters.safeAreas.find(
      (safeArea: SafeAreaObject) => safeArea.id === data.id,
    );
    if (!prevSafeArea) return null;

    commit('setPrevState', state);

    const parentObject: ProductObject | null = getProductObjectById(
      state.productObjects,
      prevSafeArea.parentId,
    );
    if (!parentObject)
      throw `Parent object with ${prevSafeArea.parentId} is not present on canvas`;

    //copy safe to trigger watcher
    const safeArea = cloneDeep<SafeAreaObject>(prevSafeArea);
    const props = await safeAreaProps(safeArea, parentObject);
    safeArea.updateProps(props);

    commit('updateSafeAreaObject', { object: safeArea });
  },

  /**
   * Delete product object and all related safe areas and texts to it
   * @param param0
   * @param id id of the object to delete
   */
  deleteProductObject(
    { state, commit, dispatch, getters }: ActionContext<IDesignState, any>,
    id: string,
  ) {
    commit('setPrevState', state);

    commit('setProductObjects', {
      value: state.productObjects.filter(c => c.id !== id),
    });

    const safeAreas = getSafeAreasByObjId(state.safeAreas, id);
    if (safeAreas) {
      commit('deleteSafeAreas', { safeAreas: safeAreas });
    }

    const texts: TextObject[] = getters.texts.filter(
      ({ parentId }: TextObject) => parentId === id,
    );
    texts.forEach(({ id }: TextObject) => {
      dispatch('deleteText', id);
    });

    dispatch('getQuote');
  },

  async chooseBaseProduct(
    ctx: ActionContext<IDesignState, any>,
    data: { product: CatalogItem; sizeId: number; locationId?: number },
  ) {
    const { state, commit, getters, rootGetters, dispatch } = ctx;
    const { product, sizeId, locationId } = data;

    if (!sizeId) return;

    commit('setLoading', true, { root: true });

    commit('setPrevState', state);

    const object = new ProductObject(
      DesignObjectType.BASE,
      product,
      sizeId,
      locationId,
    );

    let props;
    try {
      props = await productProps(
        object,
        rootGetters['canvasSize'],
        getters['baseProduct'],
      );
    } catch {
      commit('setLoading', false, { root: true });
      return;
    }

    object.updateProps(props);

    commit('setProductObjects', { value: [object] });
    commit('setTexts', { value: [] });
    commit('setSafeAreas', { value: [] });
    commit('setSelectedId', { id: null });

    // add safe area
    await dispatch('addUpdateSafeAreas', object);

    dispatch('getQuote');

    commit('setLoading', false, { root: true });
  },

  async chooseClosure(
    ctx: ActionContext<IDesignState, any>,
    data: {
      product: CatalogItem;
      sizeId: number;
      locationId?: number;
    },
  ) {
    const { state, commit, getters, rootGetters, dispatch } = ctx;
    const { product, sizeId, locationId } = data;

    commit('setLoading', true, { root: true });
    commit('setPrevState', state);

    const baseProduct = await getters['baseProduct'];

    const closure = getters.closure;
    const object = new ProductObject(
      DesignObjectType.CLOSURE,
      product,
      sizeId,
      locationId,
      baseProduct.id,
    );

    try {
      object.updateProps(
        await productProps(
          object,
          rootGetters['canvasSize'],
          getters['baseProduct'],
          getters.baseSafeArea,
        ),
      );
    } catch {
      commit('setLoading', false, { root: true });
      return;
    }

    const closureIndex = state.productObjects.findIndex(
      productObject => productObject.id === closure?.id,
    );

    if (closureIndex === -1) {
      commit('setProductObjects', {
        value: getters.productObjects.concat([object]),
      });
      dispatch('getQuote');
      commit('setLoading', false, { root: true });
      return;
    }

    const productObjects = [...getters.productObjects];
    productObjects.splice(closureIndex, 1, object);

    commit('setProductObjects', { value: productObjects });
    commit('setSelectedId', { id: object.id });

    dispatch('getQuote');

    commit('setLoading', false, { root: true });
  },

  removeClosure({ commit, getters, state }) {
    commit('setLoading', true, { root: true });
    const closure = getters.closure;
    if (!closure) {
      commit('setLoading', false, { root: true });
      return;
    }

    const closureIndex = state.productObjects.findIndex(
      obj => obj.id === closure.id,
    );
    if (closureIndex === -1) {
      commit('setLoading', false, { root: true });
      return;
    }

    const productObjects = [...getters.productObjects];
    productObjects.splice(closureIndex, 1);

    commit('setProductObjects', { value: productObjects });
    commit('setLoading', false, { root: true });
  },

  async addComponent(
    ctx: ActionContext<IDesignState, any>,
    data: {
      product: CatalogItem;
      sizeId: number;
      locationId?: number;
    },
  ) {
    const { state, commit, getters, dispatch, rootGetters } = ctx;
    const { product, sizeId, locationId } = data;

    commit('setLoading', true, { root: true });

    commit('setPrevState', state);

    const baseProduct = await getters['baseProduct'];

    const object = new ProductObject(
      DesignObjectType.COMPONENT,
      product,
      sizeId,
      locationId,
      baseProduct.id,
    );

    try {
      object.updateProps(
        await productProps(
          object,
          rootGetters['canvasSize'],
          getters['baseProduct'],
          getters.baseSafeArea,
          getters.components.length,
        ),
      );
    } catch {
      commit('setLoading', false, { root: true });
      return;
    }

    commit('setProductObjects', {
      value: getters.productObjects.concat([object]),
    });
    commit('setSelectedId', { id: object.id });

    // add object's safe area
    await dispatch('addUpdateSafeAreas', object);
    dispatch('getQuote');

    commit('setLoading', false, { root: true });
  },

  /**
   * Update safe areas of the product object if they exists
   * or add new safe areas if no were present before
   * @param ctx
   * @param parentObject - product object
   */
  async addUpdateSafeAreas(
    ctx: ActionContext<IDesignState, any>,
    parentObject: ProductObject,
  ) {
    const { commit, getters } = ctx;

    const objSafeAreas = await createObjSafeAreas(
      getters.safeAreas,
      parentObject,
    );
    commit('deleteSafeAreas', { safeAreas: objSafeAreas.safeAreasToDelete });
    commit('setSafeAreas', {
      value: [...getters.safeAreas, ...objSafeAreas.safeAreasToAdd],
    });
  },

  // used only after moving the object on the canvas
  async updateProductTransformMatrix(
    { state, commit }: ActionContext<IDesignState, any>,
    data: { id: string; data: ProductObjectData },
  ) {
    const prevObject = getProductObjectById(state.productObjects, data.id);
    if (!prevObject) return null;

    const updatedObject = cloneDeep(prevObject);
    updatedObject.updateProps({ transformMatrix: data.data.transformMatrix });

    commit('updateProductObject', {
      object: updatedObject,
    });
  },

  // used only after moving the object on the canvas
  async updateSafeAreaTransformMatrix(
    { state, commit, getters }: ActionContext<IDesignState, any>,
    data: { id: string; data: SafeAreaObjectData },
  ) {
    const prevSafeArea = getters.safeAreas.find(
      (safeArea: SafeAreaObject) => safeArea.id === data.id,
    );
    if (!prevSafeArea) return null;

    commit('setPrevState', state);

    const safeArea: SafeAreaObject = cloneDeep(prevSafeArea);
    safeArea.updateProps({ transformMatrix: data.data.transformMatrix });

    commit('updateSafeAreaObject', {
      object: safeArea,
    });
  },
};

export const productMutations: MutationTree<IDesignState> = {
  setProductObjects(
    state: IDesignState,
    payload: DesignMutationPayload<{ value: ProductObject[] }>,
  ) {
    state.productObjects = payload.value;
  },

  updateProductObject(
    state: IDesignState,
    payload: DesignMutationPayload<{ object: ProductObject }>,
  ) {
    const index = state.productObjects.findIndex(
      (i: ProductObject) => i.id === payload.object.id,
    );
    if (index !== -1) state.productObjects.splice(index, 1, payload.object);
  },

  setSafeAreas(
    state: IDesignState,
    payload: DesignMutationPayload<{ value: SafeAreaObject[] }>,
  ) {
    state.safeAreas = payload.value;
  },

  updateSafeAreaObject(
    state: IDesignState,
    payload: DesignMutationPayload<{ object: SafeAreaObject }>,
  ) {
    const index = state.safeAreas.findIndex(
      (i: SafeAreaObject) => i.id === payload.object.id,
    );
    if (index !== -1) state.safeAreas.splice(index, 1, payload.object);
  },

  deleteSafeAreas(
    state: IDesignState,
    payload: DesignMutationPayload<{ safeAreas: SafeAreaObject[] }>,
  ) {
    const ids = payload.safeAreas.map(safeArea => safeArea.id);
    state.safeAreas = state.safeAreas.filter(
      safeArea => !ids.includes(safeArea.id),
    );
  },
};
