import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { loadSvgFromString } from '@/canvas/utils/createObject';
import {
  applyOptions,
  applyTransformationMatrix,
} from '@/canvas/utils/modifyObject';
import { segmentsToBezierCurves } from '@/calculateProperties/helpers/bezierUtils';

export async function safeAreaObjToFabricObj(
  safeArea: SafeAreaObject,
  index?: number,
) {
  const obj = await loadSvgFromString(safeArea.svgString);
  obj.stroke = safeArea.strokeColor;
  obj.set({
    perPixelTargetFind: true,
    // development setup: uncomment to see the safe areas
    // strokeWidth: 2,
    // stroke: 'rgba(0, 225, 0, 1)',
  });

  obj.data = {
    id: safeArea.id,
    type: safeArea.type,
    parentId: safeArea.parentId,
    bezierCurves: segmentsToBezierCurves(safeArea),
    index: index,
  };

  if (safeArea.behaviourOptions) {
    applyOptions(obj, safeArea.behaviourOptions);
  }
  if (safeArea.transformMatrix) {
    applyTransformationMatrix(obj, safeArea.transformMatrix);
  }
  obj.setCoords();

  return obj;
}
