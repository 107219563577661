













import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  SetupContext,
  watch,
} from '@vue/composition-api';
import { ProductObject } from '@/models/designObject/ProductObject';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { TextObject } from '@/models/designObject/TextObject';
import { DesignObjectType } from '@/models/DesignObjectType';
import FabricCanvas, { PaddingUnit } from '@/canvas/fabric/FabricCanvas';
import { useElementSize } from '@vueuse/core';
import { cloneDeep, debounce } from 'lodash';
import { createFabricPreviewObjects } from '@/canvas/utils/preview';

interface State {
  // preview is canvas that rendered the selected product object \
  // and safe area and text related to it
  canvas: FabricCanvas | null;
  // single product object (specific location) to show in preview
  productObj: ProductObject | null;
  // safe area of the product object (if any)
  safeAreaObj: SafeAreaObject | null;
  // text applied to the product object (if any)
  textObj: TextObject | null;
}

const Component = defineComponent({
  name: 'EngravingPreview',
  components: {},
  props: {
    locationId: Number,
  },
  setup(props, { root: { $store, $router } }: SetupContext) {
    const state = reactive({
      canvas: null,
      productObj: computed(() => {
        const obj = $store.getters[`design/selectedObject`];
        if (obj?.type === DesignObjectType.TEXT)
          return $store.getters[`design/selectedParentProductObject`];
        return $store.getters[`design/selectedProductObject`];
      }),
      safeAreaObj: computed(() =>
        $store.getters['design/safeAreas'].find(
          (obj: SafeAreaObject) =>
            (obj.id === state.productObj?.id ||
              obj.parentId === state.productObj?.id) &&
            obj.locationId === state.productObj?.locationId,
        ),
      ),
      textObj: computed(() =>
        $store.getters['design/texts'].find(
          (obj: TextObject) =>
            (obj.id === state.productObj?.id ||
              obj.parentId === state.productObj?.id) &&
            obj.locationId === state.productObj?.locationId,
        ),
      ),
    }) as State;

    const htmlCanvas = ref<HTMLCanvasElement | null>(null);
    const canvasRoot = ref(null);
    const PREVIEW_HEIGHT = 200;

    onMounted(async () => {
      if (!htmlCanvas.value) return;
      const canvas = new FabricCanvas(
        htmlCanvas.value,
        {
          skipTargetFind: true, // disable selection of the single objects
        },
        {
          backgroundColor: '#ffffff',
          padding: { value: 5, unit: PaddingUnit.PERCENT },
        },
      );
      state.canvas = canvas;
      state.canvas.setCanvasSize({ width: size.width, height: PREVIEW_HEIGHT });
      await updatePreview([state.productObj, state.safeAreaObj, state.textObj]);
    });

    const size = reactive(
      useElementSize(
        canvasRoot,
        { width: 0, height: 0 },
        { box: 'border-box' },
      ),
    );

    const debouncedSizeWatch = debounce(async size => {
      if (!state.canvas) return;
      state.canvas.setCanvasSize({ width: size.width, height: PREVIEW_HEIGHT });
      await updatePreview([state.productObj, state.safeAreaObj, state.textObj]);
    }, 200);

    watch(size, debouncedSizeWatch);

    const debouncedObjWatch = debounce(async value => {
      if (!state.canvas) return;
      updatePreview(value);
    }, 200);

    watch<[ProductObject | null, SafeAreaObject | null, TextObject | null]>(
      () => [state.productObj, state.safeAreaObj, state.textObj],
      debouncedObjWatch,
    );

    onBeforeUnmount(() => {
      debouncedSizeWatch.cancel();
      debouncedObjWatch.cancel();
    });

    async function updatePreview([productObj, safeAreaObj, textObj]: [
      ProductObject | null,
      SafeAreaObject | null,
      TextObject | null,
    ]) {
      if (!state.canvas) return;
      if (!productObj) return;

      // map found objects to fabric objects for a preview
      const obj = await createFabricPreviewObjects({
        productObj: cloneDeep(productObj),
        safeAreaObj: safeAreaObj ? cloneDeep(safeAreaObj) : undefined,
        textObj: textObj ? cloneDeep(textObj) : undefined,
      });

      // add to canvas
      state.canvas.clear();
      state.canvas.canvas.add(...obj);
      state.canvas.scaleContentToFit();
      state.canvas.requestRender();
    }

    return {
      state,
      htmlCanvas,
      canvasRoot,
      PREVIEW_HEIGHT,
    };
  },
});

export default Component;
