import Vue from 'vue';
import Vuex, {
  ActionTree,
  GetterTree,
  ModuleTree,
  MutationTree,
  StoreOptions,
} from 'vuex';

import baseProductsModule from '@/store/modules/catalog/BaseProductsModule';
import closuresModule from '@/store/modules/catalog/ClosuresModule';
import componentModule from '@/store/modules/catalog/ComponentsModule';
import fontsModule from '@/store/modules/FontsModule';
import symbolsModule from '@/store/modules/Symbols';
import { design } from '@/store/modules/design/DesignModule';

import { DesignObjectType } from '@/models/DesignObjectType';
import uiModule from '@/store/modules/UIModule';

Vue.use(Vuex);

export interface RootState {}

class RootStoreOptions implements StoreOptions<RootState> {
  modules: ModuleTree<RootState> = {
    [DesignObjectType.BASE]: baseProductsModule,
    [DesignObjectType.CLOSURE]: closuresModule,
    [DesignObjectType.COMPONENT]: componentModule,
    fontsModule,
    symbolsModule,
    design,
    uiModule,
  };
}

export const store = new Vuex.Store<RootState>(new RootStoreOptions());
