import { Store } from 'vuex';
import { watch } from '@vue/composition-api';
import { RootState } from '@/store/store';
import { useStore } from '@/composables/useStore';
import {
  textObjToFabricText,
  productObjToFabricObj,
  safeAreaObjToFabricObj,
} from '@/canvas/mappers';
import { selectElement } from '@/canvas/stateWatchers/selectedWatch';
import { State } from '../Canvas.vue';
import { ProductObject } from '@/models/designObject/ProductObject';
import { DesignObjectType } from '@/models/DesignObjectType';
import { CanvasMode } from '../types';

/**
 * Add all objects to canvas \
 * Need to restore (rerender) design displayed by Canvas component as it is lost after unmount
 * (e.g. change mobile/desktop layout, switch between mobile tabs)
 */
export function watchRestoreCanvasState(state: State) {
  const store: Store<RootState> = useStore();

  watch(
    () => state.canvas,
    async canvas => {
      if (!canvas) return;
      // skip restore if base product was not changed
      const designBaseProduct = store.getters['design/baseProduct'];
      const baseProduct =
        store.getters[`${DesignObjectType.BASE}/selectedItem`];
      if (
        !store.getters['design/loadDesignMode'] &&
        designBaseProduct &&
        baseProduct &&
        designBaseProduct.product.id !== baseProduct.id
      )
        return;
      if (store.getters['design/loadDesignMode']) {
        canvas.setCanvasMode(CanvasMode.LoadDesign);
        canvas.multipleObjsModeOn(store.getters['design/countObjects']);
      }

      // add product objects
      const productObjs: ProductObject[] =
        store.getters['design/productObjects'];
      for (const obj of productObjs) {
        // skip closure restore if it was changed
        if (
          !store.getters['design/loadDesignMode'] &&
          obj.type === DesignObjectType.CLOSURE &&
          obj.product.id !==
            store.getters[`${DesignObjectType.CLOSURE}/selectedItem`].id
        )
          continue;

        const index = store.getters['design/getObjectIndex'](obj);
        const objToAdd = await productObjToFabricObj(obj, index);
        canvas.replaceObject(objToAdd, index);
      }

      // add safe areas
      const safeAreas = store.getters['design/safeAreas'];
      for (const obj of safeAreas) {
        const index = store.getters['design/getObjectIndex'](obj);
        const objToAdd = await safeAreaObjToFabricObj(obj, index);
        canvas.replaceObject(objToAdd, index);
      }

      // add text objects
      const texts = store.getters['design/texts'];
      for (const obj of texts) {
        if (!obj.visible) continue;

        const index = store.getters['design/getTextIndex'];
        const textToAdd = await textObjToFabricText(obj, index);
        canvas.replaceObject(textToAdd, index);
      }

      canvas.requestRender();
      await selectElement(store, canvas);
    },
  );
}
