
















































































import {
  computed,
  defineComponent,
  reactive,
  SetupContext,
} from '@vue/composition-api';
import { DesignObjectType } from '@/models/DesignObjectType';
import {
  allRingItems,
  CatalogItem,
} from '@/models/catalog/product/CatalogItem';
import { Component } from 'vue';
import { VBtn } from 'vuetify/lib';
import { ROUTES } from '@/router/constants';
import { catalogTabBeforeRouteLeave } from '@/router/guards';
import { stepBackInCatalogTree } from '@/utils/catalogUtils';

interface State {
  baseProducts: CatalogItem[];
  selectedComponent: CatalogItem | null;
  selectedBaseProduct: CatalogItem | null;
}

/**
 * Simple styled component for desktop tabs
 */
const ButtonTab = defineComponent({
  functional: true,
  props: {
    disabled: Boolean,
    to: String,
  },
  render(h, { props, children }) {
    return h(
      VBtn,
      {
        staticClass: 'fill-width justify-center',
        props: {
          disabled: props.disabled || false,
          to: props.to,
          replace: true,
          outlined: true,
          rounded: true,
          height: '42',
          activeClass: 'active',
          'data-test': 'component-tab',
        },
      },
      children,
    );
  },
});

const Component = defineComponent({
  name: 'CatalogTab',

  components: {
    ButtonTab,
  },

  beforeRouteLeave: catalogTabBeforeRouteLeave,

  setup(_, { root: { $store } }: SetupContext) {
    const state = reactive({
      baseProducts: computed(
        () => $store.getters[`${DesignObjectType.BASE}/subItems`],
      ),
      selectedBaseProduct: computed(
        () => $store.getters[`${DesignObjectType.BASE}/selectedItem`],
      ),
      selectedComponent: computed(
        () => $store.getters[`${DesignObjectType.COMPONENT}/selectedItem`],
      ),
    }) as State;

    const noClosures = computed(() =>
      state.baseProducts.every(p => p.finished),
    );

    // show closure tab if some of products have closures available
    // disable closure tab if base product is not selected
    // hide closure tab if selected product is finished
    const showClosureTab = computed(() => {
      if (state.selectedBaseProduct) return !state.selectedBaseProduct.finished;
      else return !noClosures.value;
    });

    const noComponents = computed(() =>
      state.baseProducts.every(p => !p.allowComponents),
    );

    // show components tab if some of products allow adding components
    // disable component tab if base product is not selected
    // hide components tab if selected product doesn't allow components
    const showComponentsTab = computed(() => {
      if (state.selectedBaseProduct)
        return state.selectedBaseProduct.allowComponents;
      else return !noComponents.value;
    });

    // used for mobile mode
    // when clicking on tab, tab items should step one level back
    const returnBack = computed(
      () => (type: DesignObjectType) =>
        stepBackInCatalogTree(state.selectedComponent, $store, type),
    );

    const isRingCategory = computed(() => allRingItems(state.baseProducts));

    const isMobile = computed(() => $store.getters.isMobile);

    return {
      state,
      ROUTES,
      isMobile,
      returnBack,
      noClosures,
      noComponents,
      isRingCategory,
      showClosureTab,
      DesignObjectType,
      showComponentsTab,
    };
  },
});

export default Component;
