import { DesignObjectType } from '@/models/DesignObjectType';
import { ProductObject } from '@/models/designObject/ProductObject';
import { Dimensions } from '@/canvas/types';
import { getScaleFactor } from '@/calculateProperties/helpers/scaleFactor';
import { TextObject } from '@/models/designObject/TextObject';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { BoundingBox } from '@/models/designObject/types';
import { componentTransform } from './componentTransform';

export async function getProductTransformMatrix(
  designObj: ProductObject,
  canvasSize: Dimensions,
  baseProduct?: ProductObject,
  safeArea?: SafeAreaObject,
  componentsCount?: number,
) {
  let transformMatrix = designObj.transformMatrix;
  if (!transformMatrix) {
    if (safeArea) {
      //if safe area is present, put object at the start of safe area
      const safeAreaStart = safeArea.scaledSafeAreaStart();
      transformMatrix = [1, 0, 0, 1, safeAreaStart.x, safeAreaStart.y];
    } else {
      //otherwise put at the center
      const canvasCenter = {
        x: canvasSize.width / 2,
        y: canvasSize.height / 2,
      };
      transformMatrix = [1, 0, 0, 1, canvasCenter.x, canvasCenter.y];
    }

    if (designObj.type === DesignObjectType.COMPONENT && safeArea) {
      transformMatrix = await componentTransform(
        safeArea,
        baseProduct,
        componentsCount,
      );
    }
  }

  if (designObj.type === DesignObjectType.CLOSURE) {
    const closurePos = safeArea?.scaledSafeAreaEnd();
    if (closurePos) {
      transformMatrix = [1, 0, 0, 1, closurePos.x, closurePos.y];
    }
  }

  const currPPI = (await baseProduct?.getPPI()) || 0;

  const objectOriginalSize = await designObj.locationImageSize();
  const scaleFactor = getScaleFactor(
    designObj,
    objectOriginalSize,
    canvasSize,
    currPPI,
  );
  transformMatrix[0] = scaleFactor;
  transformMatrix[3] = scaleFactor;

  return transformMatrix;
}

export function getTextTransformMatrix(
  textObj: TextObject,
  canvasSize: Dimensions,
  parent?: ProductObject,
  parentSafeArea?: SafeAreaObject,
) {
  const canvasCenter = {
    x: canvasSize.width / 2,
    y: canvasSize.height / 2,
  };
  const transformMatrix: number[] = [
    1,
    0,
    0,
    1,
    canvasCenter.x,
    canvasCenter.y,
  ];

  if (parentSafeArea?.transformMatrix) {
    transformMatrix[4] = parentSafeArea.transformMatrix[4];
    transformMatrix[5] = parentSafeArea.transformMatrix[5];
  }

  return transformMatrix;
}

/**
 * Get safe area transform matrix - matrix is calculated for the shape only, not for the whole svg size
 * @param safeAreaObject - safe area for which transform matrix is calculated
 * @param parent - parent object of the safe area
 */
export function getSafeAreaTransform(
  safeAreaObject: SafeAreaObject,
  parent: ProductObject,
): number[] | undefined {
  const locMatrix: number[] | undefined = parent.transformMatrix
    ? [...parent.transformMatrix]
    : undefined;
  if (!locMatrix) return undefined;

  const scale = locMatrix[0];
  const { x, y } = safeAreaObject.safeAreaOffset;
  const { width, height } = safeAreaObject.safeAreaSize;

  const boundingBox = parent.product.isRing
    ? calcRingLocBoundingBox(parent, safeAreaObject.locationId)
    : parent.boundingBox;
  // find parent top left translate
  const parentTopLeftPos = {
    x: boundingBox?.left || 0,
    y: boundingBox?.top || 0,
  };
  locMatrix[4] = parentTopLeftPos.x + x * scale + (width * scale) / 2;
  locMatrix[5] = parentTopLeftPos.y + y * scale + (height * scale) / 2;

  return locMatrix;
}

/**
 * Actual only for rings: calculate bounding box of the certain location
 * @param product
 * @param locId
 */
function calcRingLocBoundingBox(
  product: ProductObject,
  locId: number,
): BoundingBox | undefined {
  const scale = product.transformMatrix ? product.transformMatrix[0] : 1;
  const boundingBox = product.locsBoundingBoxes
    ? product.locsBoundingBoxes[locId]
    : undefined;

  if (!boundingBox) return undefined;
  const width = (boundingBox?.width || 0) * scale;
  const height = (boundingBox?.height || 0) * scale;
  const top = (boundingBox?.top || 0) * scale + (product.boundingBox?.top || 0);
  const left =
    (boundingBox?.left || 0) * scale + (product.boundingBox?.left || 0);
  return {
    width,
    height,
    top,
    left,
  };
}
