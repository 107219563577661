import { Dimensions } from '@/canvas/types';

export function getImageSize(url: string): () => Promise<Dimensions> {
  const imageSize: Dimensions | null = null;

  return async () => {
    if (imageSize) {
      console.log('USE CACHE');
      return imageSize;
    }

    return new Promise<Dimensions>((resolve, reject) => {
      const img = new Image();
      img.addEventListener('load', e =>
        resolve({
          width: img.width,
          height: img.height,
        }),
      );
      img.addEventListener('error', () => {
        reject(new Error(`Failed to load image's URL: ${url}`));
      });

      img.src = url;
    });
  };
}
