



























import { USymbol } from '@/models/catalog/USymbol';
import {
  computed,
  defineComponent,
  reactive,
  SetupContext,
} from '@vue/composition-api';

const Component = defineComponent({
  name: 'USymbolCard',

  emits: ['u-symbol-click', 'close'],

  setup(props, { emit, root: { $store } }: SetupContext) {
    const state = reactive({
      symbolsList: computed(() => $store.getters[`symbolsModule/items`]),
    });

    const onUSymbolClick = (item: USymbol) => {
      emit('u-symbol-click', item);
    };

    const onCloseClick = () => {
      emit('close');
    };

    const isMobile = computed(() => $store.getters.isMobile);
    const itemsPerRow = computed(() => (isMobile.value ? 4 : 6));

    return {
      state,
      itemsPerRow,
      onCloseClick,
      onUSymbolClick,
    };
  },
});

export default Component;
