import { ProductObject } from '@/models/designObject/ProductObject';
import { TextObject } from '@/models/designObject/TextObject';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { QuoteItem } from '@/models/quote/QuoteItem';

export interface OutputState {
  productObjects: ProductObject[];
  texts: TextObject[];
  safeAreas: SafeAreaObject[];
  outputType: OutputType;
}

export interface IDesignState {
  selectedId: string | null;
  productObjects: ProductObject[];
  texts: TextObject[];
  safeAreas: SafeAreaObject[];
  totalPrice: number;
  cartPreview: string;
  // trigger to generate cart preview
  genCartPreview: boolean;
  designId?: string;
  loadDesignMode?: boolean;
  // trigger preview generation
  outputState?: OutputState;
  // output values
  firstOutput?: string;
  secondOutput?: string;

  // if true - the canvas if in the process of updating
  // used to set base product before closure correctly
  isLoading?: boolean;
  /**
   * List of quote items with prices. \
   * Used to display total price components.
   */
  quoteItems: QuoteItem[];
  /** Previous design state */
  prev?: IDesignState;
  /**
   * Canvas zoom level represented by either
   * - Number in percent visible to user
   * (does NOT equal to the actual zoom inside canvas) \
   * - FIT - zoom to fit all content on the canvas
   * (may not be equal to 100%) \
   * - CUSTOM - zoom after zooming gestures
   */
  canvasZoom: ZoomLevel;
}

// ActionGroup - possible for future undo/redo use
export type DesignMutationPayload<T> = T & { actionGroup?: string };

export enum OutputType {
  First,
  Second,
}

export type ZoomLevel = number | 'FIT' | 'CUSTOM';
