import { render, staticRenderFns } from "./CanvasAreaActions.vue?vue&type=template&id=39d73058&scoped=true&"
import script from "./CanvasAreaActions.vue?vue&type=script&lang=ts&"
export * from "./CanvasAreaActions.vue?vue&type=script&lang=ts&"
import style0 from "./CanvasAreaActions.vue?vue&type=style&index=0&id=39d73058&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d73058",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VList,VListItem,VListItemContent,VMenu,VSheet,VSpacer})
