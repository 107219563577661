import { TextObject } from '@/models/designObject/TextObject';
import { ProductObject } from '@/models/designObject/ProductObject';
import { loadFont } from '@/canvas/utils/fonts';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';

const textFit = require('textfit');

const DEFAULT_FONT_SIZE = 20;

/**
 * Calculate font size depending on editable area size, font family and text
 * @param textObj - text design object
 * @param parent - parent of text design object
 * @param parentSafeArea - parent safe area
 */
export async function getFontSize(
  textObj: TextObject,
  parent?: ProductObject,
  parentSafeArea?: SafeAreaObject,
) {
  // make sure font is loaded before any measurements
  await loadFont(textObj.fontFamily);

  if (!parent || !parentSafeArea) return DEFAULT_FONT_SIZE;

  const safeAreaSize = parentSafeArea.scaledSafeAreaSize();
  if (!safeAreaSize.height || !safeAreaSize.width) return DEFAULT_FONT_SIZE;

  if (parent.product.isRing) {
    return measureFontSize(
      textObj,
      textObj.fontFamily,
      safeAreaSize.width,
      safeAreaSize.height,
    );
  } else {
    return measureFontSizeBox(
      textObj,
      textObj.fontFamily,
      safeAreaSize.width,
      safeAreaSize.height,
    );
  }
}

// single line for rings
const measureFontSize = findFontSize(false);
// multiline for components
const measureFontSizeBox = findFontSize(true);

function findFontSize(isMultiline?: boolean) {
  return async (
    text: TextObject,
    fontFamily: string,
    containerWidth: number,
    containerHeight?: number,
  ) => {
    const textContainer: HTMLElement = document.createElement('div');
    const textContent = document.createElement('span');
    textContainer.setAttribute('id', text.id);
    // text content styles
    textContent.innerText = text.text;
    textContent.style.setProperty('font-family', fontFamily, 'important');
    textContainer.appendChild(textContent);

    // text container styles
    textContainer.style.visibility = 'hidden';
    textContainer.style.position = 'absolute';
    textContainer.style.width = `${containerWidth}px`;
    if (containerHeight) textContainer.style.height = `${containerHeight}px`;
    document.getElementById('app')?.appendChild(textContainer);

    // run lib to measure font size
    const fontSize = await fitText(textContainer, !!isMultiline);
    document.getElementById('app')?.removeChild(textContainer);
    return fontSize;
  };
}

function fitText(textElem: HTMLElement, isMultiline = false): Promise<number> {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      try {
        textFit(textElem, { multiLine: isMultiline });
        //@ts-ignore
        const fontSize = textElem.childNodes[0].style.fontSize;
        resolve(fontSize ? parseInt(fontSize, 10) : DEFAULT_FONT_SIZE);
      } catch (e) {
        reject(e);
      }
    }, 0);
  });
}
