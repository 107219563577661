import { DesignObjectType } from '@/models/DesignObjectType';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { DesignObject } from '@/models/designObject/DesignObject';
import { Size } from '@/models/catalog/product/Size';
import { Location } from '@/models/catalog/product/Location';
import { Dimensions, Point } from '@/canvas/types';
import { getImageSize } from '@/utils/imageUtils';
import { BoundingBox } from '@/models/designObject/types';

/**
 * Interface to hold design object information received from canvas
 */
export type ProductObjectData = Partial<
  Omit<ProductObject, 'id' | 'type' | 'product'>
>;

/**
 * Customized Product
 */
export class ProductObject extends DesignObject {
  public readonly product: CatalogItem;
  private _sizeId: number;
  private _locationId: number;

  // ring only props
  public thumbTransformMatrix?: number[];
  // list of transform matrices for every ring location
  // key - location id; value - transform matrix
  public locsMatrices?: { [k: string]: number[] };
  // list of bounding boxes for every ring location
  public locsBoundingBoxes?: { [k: string]: BoundingBox };

  public constructor(
    type: DesignObjectType,
    product: CatalogItem,
    sizeId: number,
    locationId?: number,
    parentId?: string,
    id?: string,
  ) {
    super(type, parentId, id);
    this.product = product;
    this._sizeId = sizeId;
    this._locationId = locationId ? locationId : this.product.locations[0].id;
  }

  public getThumbSize(): Promise<Dimensions> {
    return getImageSize(this.product.thumbnailUrl)();
  }

  public get sizeId() {
    return this._sizeId;
  }

  public set sizeId(id: number) {
    const size = this.product.sizes.find(s => s.id === id);
    if (!size) {
      throw `No size with id ${id} is present`;
    }
    this._sizeId = id;
  }

  public get locationId() {
    return this._locationId;
  }

  public set locationId(id: number) {
    if (!id) {
      this._locationId = this.product.locations[0].id;
      return;
    }
    const location = this.product.locations.find(l => l.id === id);
    if (!location) {
      throw `No location with id ${id} is present`;
    }
    this._locationId = id;
  }

  public get size(): Size | null {
    return this.product.sizes.find(s => s.id === this._sizeId) || null;
  }

  public get location(): Location | null {
    return this.product.locations.find(s => s.id === this._locationId) || null;
  }

  public get locationImageUrl() {
    return this.getLocationImageUrlById(this._locationId);
  }

  public getLocationImageUrlById(locId: number) {
    const res = this.size?.getLocationDetailsById(locId)?.url;
    if (res) return res;
    throw `No image provided for location #${locId} in current size`;
  }

  public get price() {
    return this.size?.price || 0;
  }

  /**
   * Get current location image width in units
   */
  public imageWidthUnits() {
    const locationDetails = this.size?.getLocationDetailsById(this.locationId);
    return locationDetails?.widthUnits || 1;
  }

  /**
   * Get current image ppi (taking into account scaling)
   */
  public async getPPI() {
    const boundingBoxWidth = this.boundingBox?.width;
    if (boundingBoxWidth) {
      const imageUnitsWidth = this.imageWidthUnits();
      return boundingBoxWidth / imageUnitsWidth;
    }
    return await this.defaultPPI();
  }

  public locationMatrix(locId: number) {
    return this.locsMatrices ? this.locsMatrices[locId] : [1, 0, 0, 1, 0, 0];
  }

  public async locationImageSizeById(locId: number): Promise<Dimensions> {
    const currLocationDetails = this.size?.getLocationDetailsById(locId);
    return currLocationDetails
      ? await currLocationDetails.getLocationImageSize()
      : { width: 0, height: 0 };
  }

  /**
   * Get image size of current location in px
   */
  public async locationImageSize(): Promise<Dimensions> {
    return this.locationImageSizeById(this.locationId);
  }

  public currSafeArea(): string | undefined {
    const currLocation = this.size?.getLocationDetailsById(this.locationId);
    return currLocation?.safeArea;
  }

  /**
   * Get location-safe area relation objects
   */
  public locationSafeAreas() {
    return (
      this.size?.locationDetails.map(locDetails => {
        return {
          safeArea: locDetails.safeArea,
          locId: locDetails.locationId,
        };
      }) || []
    );
  }

  /**
   * Original ppi - before any scaling is done
   */
  public async defaultPPI() {
    const imageSize = await this.locationImageSize();
    return imageSize.width / this.imageWidthUnits();
  }

  public updateProps(data: ProductObjectData) {
    Object.assign(this, data);
  }
}
