import { Location } from '@/models/catalog/product/Location';
import { Size } from '@/models/catalog/product/Size';

export class CatalogItem {
  public id: number = 0;
  public name: string = '';
  public thumbnailUrl: string = '';
  public parentId?: number;
  public description?: string;

  public finished: boolean = false;
  public allowComponents: boolean = false;
  public isRing: boolean = false;
  public sizes: Size[] = [];
  public locations: Location[] = [];
  public closures: number[] = [];

  public categories?: number[];
  public products?: number[];

  public constructor(initData: Partial<CatalogItem>) {
    Object.assign(this, initData);
  }

  /**
   * Item is product means it is the lowest entity.
   * It doesn't have products or subcategories
   */
  public get isProduct() {
    return !this.categories && !this.products;
  }

  public get minPrice() {
    return this.isProduct && this.sizes.length
      ? Math.min(...this.sizes.map((s: Size) => s.price))
      : 0;
  }

  /**
   * Return true if any of the product locations allows adding text
   */
  public get isTextAllowed() {
    return this.locations.some(l => l.allowText);
  }
}

/**
 * Check if all of the provided Catalog Items are rings
 * Used to differentiate ring
 * @param items array of CatalogItems to check
 * @returns true if all items are rings
 */
export const allRingItems = (items: CatalogItem[]): boolean =>
  items.every(item => item.isRing);
