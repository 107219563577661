







































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  SetupContext,
  watch,
} from '@vue/composition-api';
import Canvas from '@/canvas/Canvas.vue';
import { ProductObject } from '@/models/designObject/ProductObject';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { DesignObjectType } from '@/models/DesignObjectType';
import { formatCurrency } from '@/utils/currencyUtils';
import { Size } from '@/models/catalog/product/Size';
import { DesignObject } from '@/models/designObject/DesignObject';
import { ROUTES } from '@/router/constants';
import CanvasAreaActions from '@/components/elements/CanvasAreaActions.vue';
import PreloaderOverlay from '@/components/utils/PreloaderOverlay.vue';

interface State {
  selectedObj: DesignObject | null;
  selectedProductObj: ProductObject | null;
  baseProduct: ProductObject | null;
  closure: ProductObject | null;
  allowClosure: boolean;
  allowComponents: boolean;
  loadDesignMode: boolean;
  total: number;
  selectedCategory: CatalogItem | null;
  selectedBaseProduct: CatalogItem | null;
  selectedBaseProductSize: Size | null;
  loadingBaseProduct: boolean;
  canvasLoading: boolean;
}

const Component = defineComponent({
  name: 'CanvasArea',

  components: {
    Canvas,
    CanvasAreaActions,
    PreloaderOverlay,
  },

  setup(props, { root: { $store, $router, $i18n } }: SetupContext) {
    const state = reactive({
      baseProduct: computed(() => $store.getters['design/baseProduct']),
      closure: computed(() => $store.getters['design/closure']),
      allowClosure: computed(() => $store.getters['design/allowClosure']),
      allowComponents: computed(() => $store.getters['design/allowComponents']),
      total: computed(() => $store.getters['design/totalPrice']),
      loadDesignMode: computed(() => $store.getters['design/loadDesignMode']),
      selectedObj: computed(() => $store.getters[`design/selectedObject`]),
      selectedProductObj: computed(
        () => $store.getters[`design/selectedProductObject`],
      ),
      selectedCategory: computed(
        () => $store.getters[`${DesignObjectType.BASE}/selectedCategory`],
      ),
      selectedBaseProduct: computed(
        () => $store.getters[`${DesignObjectType.BASE}/selectedItem`],
      ),
      selectedBaseProductSize: computed(
        () => $store.getters[`${DesignObjectType.BASE}/selectedSize`],
      ),
      // flag to mark if base product is in the progress of loading
      // used to not set closure until base product is loaded
      loadingBaseProduct: computed(() => $store.getters['design/isLoading']),
      canvasLoading: computed(() => $store.getters.loadingCanvas),
    }) as State;

    onMounted(async () => {
      $store.commit('design/setIsLoading', true);
      await setBaseProduct(
        state.selectedBaseProduct,
        state.selectedBaseProductSize,
      );
      $store.commit('design/setIsLoading', false);
      await setClosure(
        $store.getters[`${DesignObjectType.CLOSURE}/selectedItem`],
      );
    });

    watch(
      (): [CatalogItem | null, Size | null] => [
        state.selectedBaseProduct,
        state.selectedBaseProductSize,
      ],
      async ([product, size]) => {
        if (state.loadingBaseProduct) return;
        $store.commit('design/setIsLoading', true);
        await setBaseProduct(product, size);
        $store.commit('design/setIsLoading', false);
      },
    );

    const setBaseProduct = async (
      product: CatalogItem | null,
      size: Size | null,
    ) => {
      if (!size) return;
      if (!product) return;
      if (
        state.baseProduct?.product.id === product.id &&
        state.baseProduct?.sizeId === size.id
      )
        return;
      await $store.dispatch(`design/chooseBaseProduct`, {
        product: product,
        sizeId: size.id,
      });

      // set default closure
      if (!state.allowClosure) return;
      const closure =
        $store.getters[`${DesignObjectType.CLOSURE}/selectedItem`];
      if (!closure) return;
      if (state.closure?.product.id === closure.id) return;
      await $store.dispatch(`design/chooseClosure`, {
        product: closure,
        sizeId: closure.sizes[0].id,
      });
    };

    const setClosure = async (closure: CatalogItem | null) => {
      if (state.loadingBaseProduct) return;
      if (!closure) return;
      if (!state.baseProduct) return;
      if (state.closure?.product.id === closure.id) return;

      await $store.dispatch(`design/chooseClosure`, {
        product: closure,
        sizeId: closure.sizes[0].id,
      });
    };

    watch(
      () => $store.getters[`${DesignObjectType.CLOSURE}/selectedItem`],
      async value => {
        await setClosure(value);
      },
    );

    const isMobile = computed(() => $store.getters.isMobile);

    const description = computed(() => {
      if (!state.selectedCategory)
        return $i18n.t('titles.catalog.selectCategoryCreate');
      if (!state.baseProduct)
        return $i18n.t('titles.catalog.selectCategoryBegin', {
          category: state.selectedCategory.name,
        });
      const selectedProduct = state.selectedProductObj;
      if (!selectedProduct)
        return (
          state.baseProduct.product.description ??
          state.baseProduct.product.name
        );
      return (
        selectedProduct.product.description ?? selectedProduct.product.name
      );
    });

    const showCanvas = computed(
      () => state.selectedCategory && state.selectedBaseProductSize,
    );

    /** Open respective tab on object select */
    // todo: refactor routes, esp details and text
    watch(
      () => state.selectedObj,
      value => {
        if (!value) return;

        let path = $router.currentRoute.path;
        switch (value.type) {
          // open engraving tab is text selected
          case DesignObjectType.TEXT:
            if ($router.currentRoute.path === ROUTES.textTab.path) return;
            break;

          case DesignObjectType.BASE:
          case DesignObjectType.CLOSURE:
          case DesignObjectType.COMPONENT:
            // keep engraving tab open is possible
            const allowText = (state.selectedObj as ProductObject).product
              .isTextAllowed;
            if (allowText && $router.currentRoute.path === ROUTES.textTab.path)
              return;
            // otherwise, open respective catalog tab
            path = `/${ROUTES.catalogTab.path}/${ROUTES[value.type].path}`;
            break;
          // open components tab by default
          default:
            path = `/${ROUTES.catalogTab.path}/${ROUTES.component.path}`;
            break;
        }
        if ($router.currentRoute.path === path) return;
        $router.replace(path);
      },
    );

    /**
     * Check is details tab is available for current selected object.
     * Details are available for components and rings
     * @param obj
     */
    function detailsAvailable(obj: DesignObject) {
      if (!obj) return false;
      switch (obj.type) {
        case DesignObjectType.BASE:
          return (obj as ProductObject).product.isRing;
        case DesignObjectType.COMPONENT:
          return true;
        case DesignObjectType.CLOSURE:
        case DesignObjectType.TEXT:
        default:
          return false;
      }
    }

    return {
      state,
      formatCurrency,
      isMobile,
      description,
      showCanvas,
    };
  },
});

export default Component;
