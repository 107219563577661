




















































































import {
  computed,
  defineComponent,
  reactive,
  ref,
  SetupContext,
} from '@vue/composition-api';
import { DesignObjectType } from '@/models/DesignObjectType';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { Size } from '@/models/catalog/product/Size';
import ItemSliderList from '@/components/elements/ItemSliderList.vue';
import ItemGridList from '@/components/elements/ItemGridList.vue';
import AddEngravingButton from '@/components/elements/AddEngravingButton.vue';
import { setDialog } from '@/utils/dialogs';
import Footer from '@/components/elements/Footer.vue';
import { ROUTES } from '@/router/constants';
import { useDefaultProductChosen } from '@/composables/useDefaultProductChosen';
import { formatCurrency } from '@/utils/currencyUtils';
import { useChangeBaseProduct } from '@/composables/useChooseBaseProduct';

interface State {
  products: CatalogItem[];
  sizes: Size[];
  selectedProduct: CatalogItem | null;
  selectedSize: Size | null;
  selectedSizePrice: number;
}

const Component = defineComponent({
  name: 'BaseProductTab',
  components: {
    ItemGridList,
    ItemSliderList,
    AddEngravingButton,
    Footer,
  },

  setup(_, { root: { $store, $router, $i18n } }: SetupContext) {
    const type = DesignObjectType.BASE;

    const state = reactive({
      products: computed(() => $store.getters[`${type}/siblings`]),
      sizes: computed(() => $store.getters[`${type}/sizes`]),
      selectedProduct: computed({
        get: () => $store.getters[`${type}/selectedItem`],
        set: value => $store.dispatch(`${type}/setSelectedItem`, value),
      }),
      selectedSize: computed({
        get: () => $store.getters[`${type}/selectedSize`],
        set: value => $store.dispatch(`${type}/setSelectedSize`, value),
      }),
      selectedSizePrice: computed(
        () => state.selectedSize?.price ?? state.selectedProduct?.minPrice ?? 0,
      ),
    }) as State;

    const dropdownSelection = computed({
      get: () => {
        const selectedSize = $store.getters[`${type}/selectedSize`];
        return {
          text: selectedSize.name,
          value: selectedSize,
        };
      },
      set: value => $store.dispatch(`${type}/setSelectedSize`, value),
    });

    const dropdownOptions = computed(() =>
      state.sizes.map(size => ({ text: size.name, value: size })),
    );

    const isMobile = computed(() => $store.getters.isMobile);

    const menuNudgeSize = computed(() => (isMobile.value ? 10 : -10));

    const isShowMoreBtnVisible = ref(false);

    const isDefaultProductChosen = useDefaultProductChosen();

    const selectBaseProduct = useChangeBaseProduct(
      $store,
      isDefaultProductChosen,
    );

    const selectProduct = computed(() => (selectedItem: CatalogItem) => {
      selectBaseProduct.value(
        selectedItem,
        state.selectedProduct,
        state.selectedSize,
      );
    });

    const seeMore = () => {
      $router.push({
        name: ROUTES.viewMore.name,
        params: {
          designObjectType: type,
        },
      });
    };

    const changeSize = computed(
      () => (selectedSize: { value: Size }, on: { click: Function }) => {
        if (selectedSize.value.id === state.selectedSize?.id) return;
        const confirmSizeChange = on.click;

        if (isDefaultProductChosen.value) {
          confirmSizeChange();
        } else {
          $store.dispatch(
            'showDialog',
            setDialog(
              $i18n.t('prompts.changeProductDialog') as string,
              (onConfirm: boolean) => onConfirm && confirmSizeChange(),
            ),
          );
        }
      },
    );

    return {
      type,
      state,
      seeMore,
      isMobile,
      changeSize,
      selectProduct,
      dropdownOptions,
      isShowMoreBtnVisible,
      dropdownSelection,
      formatCurrency,
      menuNudgeSize,
    };
  },
});

export default Component;
