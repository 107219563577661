import { ProductObject } from '@/models/designObject/ProductObject';
import { fabric } from 'fabric';
import { DesignObjectType } from '@/models/DesignObjectType';
import { loadImageFromUrl } from '@/canvas/utils/createObject';
import { ringDesignObjectToFabricObj } from '@/canvas/mappers';
import {
  applyOptions,
  applyTransformationMatrix,
} from '@/canvas/utils/modifyObject';
import { lockChildrenTransformToObject } from '@/canvas/utils/attachEvents';
import { getLocationObjId } from '@/canvas/utils/idsUtils';

export async function productObjToFabricObj(
  designObj: ProductObject,
  index?: number,
): Promise<fabric.Object> {
  let object: fabric.Object;

  const isRing =
    designObj.type === DesignObjectType.BASE && designObj.product.isRing;
  if (isRing) {
    object = await ringDesignObjectToFabricObj(designObj);
    object.data = { id: designObj.id, type: designObj.type, index: index };
  } else {
    object = await productDesignObjToFabric(designObj);
    object.data = {
      id: designObj.id,
      type: designObj.type,
      sizeId: designObj.size?.id,
      locationId: designObj.location?.id,
      parentId: designObj.parentId,
      index: index,
    };
  }

  applyOptions(object, {
    ...designObj.behaviourOptions,
    //make ring group non selectable
    selectable: !isRing,
  });

  //move object to it's position
  if (designObj.transformMatrix) {
    applyTransformationMatrix(object, designObj.transformMatrix);
  }

  if (isRing) {
    const group = <fabric.Group>object;
    if (group) group.addWithUpdate();
  }

  lockChildrenTransformToObject(object);
  return object;
}

export async function productDesignObjToFabric(designObj: ProductObject) {
  const thumbObject = await loadImageFromUrl(designObj.locationImageUrl);
  thumbObject.data = {
    id: getLocationObjId(designObj.id, designObj.locationId),
  };
  return new fabric.Group([thumbObject], {
    subTargetCheck: true,
    perPixelTargetFind: true,
  });
}
