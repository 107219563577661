import { fabric } from 'fabric';
import { ProductObject } from '@/models/designObject/ProductObject';
import { loadImageFromUrl } from '@/canvas/utils/createObject';
import {
  applyOptions,
  applyTransformationMatrix,
} from '@/canvas/utils/modifyObject';
import { makeObjectSelectableInGroup } from '@/canvas/utils/attachEvents';
import { getLocationObjId, getRingThumbId } from '@/canvas/utils/idsUtils';

export async function ringDesignObjectToFabricObj(designObj: ProductObject) {
  const group = new fabric.Group([], { subTargetCheck: true });

  // create thumb image
  const thumb = await loadImageFromUrl(designObj.product.thumbnailUrl);
  thumb.data = {
    id: getRingThumbId(designObj.id),
    sizeId: designObj.size?.id,
    type: designObj.type,
    isRing: true,
  };

  // create all locations
  const locations = await Promise.all(
    designObj.product.locations.map(async location => {
      const locMatrix = designObj.locationMatrix(location.id);

      const locObject = await createLocationObject(
        designObj.getLocationImageUrlById(location.id),
        {
          id: getLocationObjId(designObj.id, location.id),
          locationId: location.id,
          isRing: true,
        },
      );
      applyTransformationMatrix(locObject, locMatrix);
      const title = createLocationTitle(
        location.name,
        locMatrix,
        locObject.width,
      );

      return {
        locObject,
        title,
      };
    }),
  );

  // update thumb transformation & make selectable
  applyTransformationMatrix(thumb, designObj.thumbTransformMatrix);
  applyOptions(thumb, designObj.behaviourOptions);
  // makeObjectSelectableInGroup(thumb);

  // add all to group
  group.addWithUpdate(thumb);
  locations.forEach(l => {
    group.addWithUpdate(l.title);
    group.addWithUpdate(l.locObject);
  });
  return group;
}

/**
 * Create fabric object that represents location image
 * @param imageUrl
 * @param data
 */
async function createLocationObject(imageUrl: string, data: any) {
  return (await loadImageFromUrl(imageUrl)).set({
    data: data,
  });
}

function createLocationTitle(
  title: string,
  locTransformMatrix: number[],
  locWidth?: number,
) {
  if (locTransformMatrix.length !== 6)
    throw `Transformation matrix is invalid: ${locTransformMatrix}`;

  const labelOptions = {
    fontSize: 18,
    fontFamily: 'roboto',
  };
  const label = new fabric.Text(title, labelOptions);
  applyTransformationMatrix(
    label,
    [
      1,
      0,
      0,
      1,
      locTransformMatrix[4] - (locWidth ? locWidth / 2 : 0),
      locTransformMatrix[5] - 45,
    ],
    'left',
    'top',
  );
  return label;
}
