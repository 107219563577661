


































import {
  SetupContext,
  computed,
  defineComponent,
  reactive,
  ref,
} from '@vue/composition-api';
import CategoriesGridList from '@/components/elements/CategoriesGridList.vue';
import {
  allRingItems,
  CatalogItem,
} from '@/models/catalog/product/CatalogItem';
import { DesignObjectType } from '@/models/DesignObjectType';
import { ROUTES } from '@/router/constants';
import Footer from '@/components/elements/Footer.vue';
import { stepBackInCatalogTree } from '@/utils/catalogUtils';

interface State {
  items: CatalogItem[];
  selectedItem: CatalogItem | null;
  isRootLevel: boolean;
}

const Component = defineComponent({
  name: 'CategoriesView',

  components: {
    Footer,
    CategoriesGridList,
  },

  setup(_, { root: { $store, $router, $vuetify, $i18n } }: SetupContext) {
    const type = DesignObjectType.BASE;

    const state = reactive({
      items: computed(() => $store.getters[`${type}/subItems`]),
      selectedItem: computed(() => $store.getters[`${type}/selectedItem`]),
      isRootLevel: computed(() => state.items.every(item => !item.parentId)),
    }) as State;

    const breadcrumbsItems = ref<object[]>([]);

    const selectItem = computed(() => (item: CatalogItem) => {
      $store.dispatch(`${type}/setSelectedItem`, item);

      if (!item.isProduct) {
        breadcrumbsItems.value.push({ text: item.name });
      } else {
        const onConfirm = (isConfirmed: boolean) => {
          if (!isConfirmed) return;
          if (item.allowComponents)
            return $router.push({ name: ROUTES.component.name });
          if (!item.finished)
            return $router.push({ name: ROUTES.closure.name });
          $router.push({ name: ROUTES.baseProduct.name });
        };

        $store.dispatch(`${type}/showSizeDialog`, {
          item: item,
          onConfirm: onConfirm,
        });
      }
    });

    const isMobile = computed(() => $store.getters.isMobile);

    const header = computed(() =>
      state.isRootLevel
        ? $i18n.t('titles.catalog.selectCategoryCreate')
        : `${$i18n.t('titles.catalog.selectBaseProduct', {
            objectType: allRingItems(state.items) ? 'Ring' : 'Chain',
          })}`,
    );

    const returnBack = computed(() => () => {
      stepBackInCatalogTree(state.selectedItem, $store, type);
      breadcrumbsItems.value.pop();
    });

    return {
      state,
      header,
      isMobile,
      returnBack,
      selectItem,
      breadcrumbsItems,
    };
  },
});

export default Component;
