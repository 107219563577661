import {
  ItemsModule,
  CatalogItemState,
} from '@/store/modules/catalog/ItemsModule';
import { DesignObjectType } from '@/models/DesignObjectType';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { flatten } from 'lodash';
import { GetterTree } from 'vuex';

interface ComponentState extends CatalogItemState {}

class ComponentsModule extends ItemsModule {
  state: ComponentState = {
    ...this.state,
    itemType: DesignObjectType.COMPONENT,
  };

  getters: GetterTree<CatalogItemState, any> = {
    ...this.getters,

    /**
     * Get all 'leaf' items, ignoring the category-nested structure
     */
    allComponents(state, getters, rootState, rootGetters): CatalogItem[] {
      const rootIds = getters.rootItems.map((i: CatalogItem) => i.id);
      const items: CatalogItem[] = flatten(
        rootIds.map((id: number) => getters.descendants(id)),
      );
      return items;
    },
  };
}

const componentsModule = new ComponentsModule();

export default componentsModule;
