import { LocationDetails } from '@/models/catalog/product/LocationDetails';

export class Size {
  public id: number = 0;
  public name: string = '';
  public price: number = 0;
  public locationDetails: LocationDetails[] = [];

  public constructor(initData: Partial<Size>) {
    Object.assign(this, initData);
  }

  public getLocationDetailsById(locationId: number): LocationDetails | null {
    return (
      this.locationDetails.find(im => im.locationId === locationId) || null
    );
  }
}
