import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { Dimensions } from '@/canvas/types';
import { Alert } from '@/utils/alerts';
import { Dialog } from '@/utils/dialogs';
import vuetify from '@/plugins/vuetify';

export enum ScreenType {
  /** Mobile screens that are less that 600px in height */
  ExtraSmall,
  Mobile,
  Tablet,
  Desktop,
}

interface UIState {
  /**
   * A state needed to programmatically trigger details component visibility.
   * Used to show trigger component visibility upon object select on canvas
   * @deprecated - not used
   */
  showObjectDetails: boolean;
  /**
   * Size of the working area inside the canvas.
   * Not an actual size of the <canvas> element
   * (in px in canvas coordinate system)
   */
  canvasSize: Dimensions;
  loading: boolean;
  loadingText: string | null;
  loadingCanvas: boolean;
  alerts: Alert[];
  dialog: Dialog | null;
  /** Show elements helpful for development */
  developmentMode: boolean;
  /**
   * Used to show togglable sidebar at the bottom of the screen for extra small mobile screens.
   * For other screen types Sidebar is constantly visible
   */
  showSideBar: boolean;
}

class UIModule implements Module<UIState, any> {
  namespaced: boolean = false;

  state: UIState = {
    /** @deprecated - not used */
    showObjectDetails: false,
    canvasSize: {
      width: 800,
      height: 800,
    },
    loading: true,
    loadingText: null,
    loadingCanvas: false,
    alerts: [],
    dialog: null,
    developmentMode: false,
    showSideBar: true,
  };

  getters: GetterTree<UIState, any> = {
    /** @deprecated - not used */
    showObjectDetails(state) {
      return state.showObjectDetails;
    },

    canvasSize(state) {
      return state.canvasSize;
    },

    screenType(): ScreenType {
      const { breakpoint } = vuetify.framework;
      switch (breakpoint.name) {
        case 'xs':
          if (breakpoint.height <= 500) {
            return ScreenType.ExtraSmall;
          } else return ScreenType.Mobile;
        case 'sm':
          return ScreenType.Tablet;
        case 'md':
        case 'lg':
        case 'xl':
          return ScreenType.Desktop;
      }
    },

    isMobile(state) {
      const { breakpoint } = vuetify.framework;
      return breakpoint.mobile;
    },

    isExtraSmallScreen(state, getters) {
      return getters.screenType === ScreenType.ExtraSmall;
    },

    isSmallScreen(state, getters) {
      return (
        getters.screenType === ScreenType.Mobile ||
        getters.screenType === ScreenType.Tablet
      );
    },

    loading(state): boolean {
      return state.loading;
    },

    loadingText(state): string | null {
      return state.loadingText;
    },

    loadingCanvas(state): boolean {
      return state.loadingCanvas;
    },

    alerts(state) {
      return state.alerts;
    },

    dialog(state) {
      return state.dialog;
    },

    developmentMode(state) {
      return process.env.VUE_APP_ENV === 'development' && state.developmentMode;
    },

    showSideBar(state) {
      return state.showSideBar;
    },
  };

  actions: ActionTree<UIState, any> = {
    /** @deprecated - not used */
    showObjectDetails({ commit }, showObjectDetails: boolean) {
      commit('setShowObjectDetails', showObjectDetails);
    },

    showCartDetails({ commit }, showCartDetails: boolean) {
      commit('setShowCartDetails', showCartDetails);
    },

    addAlert({ state, commit }, alert: Alert) {
      // show last 3 alerts at the same time
      const alertsLimit = 3;
      const alerts = [...state.alerts, alert].slice(-alertsLimit);

      commit('setAlerts', alerts);
      // remove alert after a while
      setTimeout(() => {
        commit(
          'setAlerts',
          state.alerts.filter(a => a !== alert),
        );
      }, 5000);
    },

    showDialog({ commit }, dialog: Dialog) {
      commit('setDialog', dialog);
    },

    hideDialog({ commit }) {
      commit('setDialog', null);
    },

    showSideBar({ commit }, value: boolean) {
      commit('showSideBar', value);
    },

    showLoader({ commit }) {
      commit('setLoading', true);
    },

    hideLoader({ commit }) {
      commit('setLoading', false);
    },
  };

  mutations: MutationTree<UIState> = {
    /** @deprecated - not used */
    setShowObjectDetails(state, showObjectDetails: boolean) {
      state.showObjectDetails = showObjectDetails;
    },

    setCanvasSize(state, canvasSize: Dimensions) {
      state.canvasSize = canvasSize;
    },

    setLoading(state, loading: boolean) {
      state.loading = loading;
    },

    setLoadingText(state, loadingText: string | null) {
      state.loadingText = loadingText;
    },

    setLoadingCanvas(state, loadingCanvas: boolean) {
      state.loadingCanvas = loadingCanvas;
    },

    setAlerts(state, value: Alert[]) {
      state.alerts = value;
    },

    setDialog(state, value: Dialog | null) {
      state.dialog = value;
    },

    setDevelopmentMode(state, value: boolean) {
      state.developmentMode = value;
    },

    showSideBar(state, value: boolean) {
      state.showSideBar = value;
    },
  };
}

const uiModule = new UIModule();
export default uiModule;
