import { Font, FontStatus } from '@/models/catalog/Font';
import FontFaceObserver from 'fontfaceobserver';
import WebFont from 'webfontloader';

/**
 * Hardcode to skip some fonts
 * TODO: each case should be resolved individually
 * - Gabriola - broken font file that causes 15s freeze on the page
 */
export const SKIP_FONTS = ['Gabriola'];

//TODO: add styles
export async function loadFont(fontFamily: string) {
  const fontObserver = new FontFaceObserver(fontFamily);

  try {
    await fontObserver.load(undefined, 15000);
  } catch (e: any) {
    console.error(e);
    console.error(`Failed to load: ${fontFamily}`);
    throw e.message;
  }
}

/**
 * Load all fonts from the list with FontFaceObserver
 * @param fonts list of fonts to load
 */
export async function loadAllFonts(fonts: Font[]) {
  const loadFont = async (font: Font) => {
    const retryLoadLimit = 3;
    let retryLoadCount = 1;
    font.status = FontStatus.Loading;
    const fontObserver = new FontFaceObserver(font.fontFamily);

    const fontLoadPromise = () =>
      new Promise((res, rej) => {
        fontObserver
          .load(undefined, 5000)
          .then(f => {
            font.status = FontStatus.Ready;
            res(f);
          })
          .catch(async e => {
            if (
              e.message.includes('timeout') &&
              retryLoadCount < retryLoadLimit
            ) {
              retryLoadCount++;
              font.status = FontStatus.Timeout;
              try {
                const result = await fontLoadPromise();
                res(result);
              } catch (e) {
                rej(e);
              }
            } else {
              font.status = FontStatus.Failed;
              console.error(`Failed to load font: ${font.fontFamily}:`, e);
              rej(e);
            }
          });
      });

    return fontLoadPromise();
  };

  return Promise.allSettled(
    fonts.filter(f => !SKIP_FONTS.includes(f.fontFamily)).map(f => loadFont(f)),
  );
}

/**
 * Load list of fonts with webfontloader lib
 * @deprecated not used due to noticed issue \
 * Sometimes font files are loading with status 200, but fontinactive is fired
 * @param fonts list of fonts to load
 */
export async function loadAllWebfont(fonts: Font[]) {
  WebFont.load({
    custom: {
      families: fonts
        .map(f => f.fontFamily)
        .filter(f => !SKIP_FONTS.includes(f)),
    },
    fontloading: function (familyName, fvd) {
      const f = fonts.find(f => f.fontFamily === familyName);
      if (f) f.status = FontStatus.Loading;
    },
    fontactive: function (familyName, fvd) {
      const f = fonts.find(f => f.fontFamily === familyName);
      if (f) f.status = FontStatus.Ready;
    },
    fontinactive: function (familyName, fvd) {
      const f = fonts.find(f => f.fontFamily === familyName);
      if (f) f.status = FontStatus.Failed;
      console.error(`Failed to load font ${familyName} ${fvd}`);
    },

    timeout: 3000,
    classes: false,
  });
}
