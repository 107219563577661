


















































































import {
  computed,
  defineComponent,
  onActivated,
  onDeactivated,
  reactive,
  ref,
  SetupContext,
  watch,
} from '@vue/composition-api';
import { ProductObject } from '@/models/designObject/ProductObject';
import { Location } from '@/models/catalog/product/Location';
import { TextObject, TextObjectData } from '@/models/designObject/TextObject';
import FontList from '@/components/elements/FontList.vue';
import EngravingPreview from '@/components/elements/EngravingPreview.vue';
import USymbolCard from '@/components/elements/USymbolCard.vue';
import { debounce } from 'lodash';
import { DesignObjectType } from '@/models/DesignObjectType';
import { USymbol } from '@/models/catalog/USymbol';
import TabCardLayout from '@/components/elements/TabCardLayout.vue';
import { ROUTES } from '@/router/constants';

interface State {
  // Product object, the lettering is on
  productObj: ProductObject | null;
  // Text object
  textObj: TextObject | null;

  locations: Location[];
  selectedLocation?: Location;

  selectedFontFamily: string;

  symbolsList: USymbol[];

  isLoading: boolean;
}

const Component = defineComponent({
  name: 'TextTab',
  components: {
    FontList,
    EngravingPreview,
    USymbolCard,
    TabCardLayout,
  },

  setup(_, { root: { $store, $router } }: SetupContext) {
    const state = reactive({
      productObj: computed(() => {
        const selectedObj = $store.getters[`design/selectedObject`];
        if (selectedObj?.type === DesignObjectType.TEXT)
          return $store.getters[`design/selectedParentProductObject`];
        return $store.getters[`design/selectedProductObject`];
      }),
      textObj: computed(
        () =>
          $store.getters[`design/selectedText`] ??
          $store.getters[`design/getTextByObjLocationId`]({
            parentId: state.productObj?.id,
            locationId: state.selectedLocation?.id,
          }) ??
          null,
      ),
      locations: computed(() => {
        let locations: Location[] = [];
        if (state.productObj?.product.locations)
          locations = state.productObj?.product.locations;
        /** if product is not a ring, change default location names */
        locations.forEach((location, i) => {
          location.name = isRing.value ? location.name : `Side ${i + 1}`;
        });

        return locations;
      }),
      selectedLocation: computed({
        get: () => state.productObj?.location ?? undefined,
        set: value => update({ location: value }),
      }),
      selectedFontFamily: computed({
        get: () => state.textObj?.fontFamily ?? '',
        set: value => update({ data: { fontFamily: value } }),
      }),
      symbolsList: computed(() => $store.getters[`symbolsModule/items`]),
      isLoading: computed(() => $store.getters.loading),
    }) as State;

    const allowText = computed(() => state.selectedLocation?.allowText);
    const isRing = computed(() => !!state.productObj?.product.isRing);
    const textContent = ref(state.textObj?.text || '');

    watch(textContent, async value => {
      if (!active.value) return;
      // text is added only if doesn't exist for the current location
      await addText();
      update({ data: { text: value } });
    });

    onActivated(() => {
      if (!state.productObj) return;
      if (isRing.value) {
        const location = state.locations.find(
          l => l.id === state.textObj?.locationId,
        );
        state.selectedLocation = location;
      }
      selectTextObj();
      active.value = true;
    });

    onDeactivated(() => {
      if (!state.productObj) return;
      const isCharmType = state.productObj?.type === DesignObjectType.COMPONENT;
      if (isCharmType) {
        $store.dispatch('design/selectObject', state.productObj?.id);
      } else {
        $store.dispatch('design/deselectObject');
        textContent.value = '';
      }
      active.value = false;
      textContent.value = '';
    });

    // hack to prevent debounced text updates after text tab is closed
    const active = ref(true);

    watch(
      () => state.textObj,
      (newValue, oldValue) => {
        if (!newValue) return;
        if (oldValue && newValue.id !== oldValue?.id)
          textContent.value = newValue.text;
        if (!isRing.value) return;
        const location = state.locations.find(
          l => l.id === newValue?.locationId,
        );
        state.selectedLocation = location;
      },
    );

    /**
     * Select existing text obj
     */
    const selectTextObj = async () => {
      if (!state.productObj) return;
      if (!state.selectedLocation) return;
      // select parent obj if text is not allowed
      if (!state.selectedLocation.allowText) {
        return $store.dispatch('design/selectObject', state.productObj.id);
      }
      // select child text object
      let textObj = $store.getters[`design/getTextByObjLocationId`]({
        parentId: state.productObj.id,
        locationId: state.selectedLocation.id,
      });
      if (textObj) {
        await $store.dispatch('design/selectObject', textObj.id);
        textContent.value = textObj.text;
      } else {
        textContent.value = '';
        await $store.dispatch('design/selectObject', state.productObj.id);
      }
    };

    const addText = async () => {
      if (state.isLoading) return;
      if (!state.productObj) return;
      if (!state.selectedLocation) return;

      let textObj = $store.getters[`design/getTextByObjLocationId`]({
        parentId: state.productObj.id,
        locationId: state.selectedLocation.id,
      });

      if (textObj) return;
      try {
        await $store.dispatch('design/addText', {
          objId: state.productObj.id,
          locId: state.selectedLocation.id,
          text: textContent.value,
        });
      } catch {
        $router.back();
      }
    };

    // change location of item on canvas on location select
    const changeLocation = async (location?: Location) => {
      if (!state.productObj) return;
      if (!location) return;
      if (location.id === state.productObj.locationId) return;
      textContent.value = '';
      await $store.dispatch('design/updateProductObject', {
        id: state.productObj.id,
        data: {
          sizeId: state.productObj.sizeId,
          locationId: location.id,
        },
      });
      if (!location.allowText) return;
      await selectTextObj();
    };

    // todo: refactor into better event based structure
    const update = debounce(
      async (value: { location?: Location; data?: TextObjectData }) => {
        await changeLocation(value.location);
        if (value.data) updateText(value.data);
      },
      500,
    );

    const updateText = (data: TextObjectData) => {
      if (!state.textObj) return;
      $store.dispatch('design/updateText', {
        id: state.textObj.id,
        data,
      });
    };

    const close = () => {
      const isCharmType = state.productObj?.type === DesignObjectType.COMPONENT;
      $router.push({
        name: isCharmType ? ROUTES.component.name : ROUTES.baseProduct.name,
      });
    };

    const addSymbolToTextContent = (symbol: USymbol) => {
      textContent.value = `${textContent.value}${symbol.icon}`;
    };

    const apply = () => {
      if (state.textObj) {
        const data = {
          text: textContent.value,
          fontFamily: state.selectedFontFamily,
        };
        update({ location: state.selectedLocation, data });
      }
      close();
    };

    const remove = () => {
      textContent.value = '';
      if (state.textObj) $store.dispatch('design/deleteText', state.textObj.id);
      close();
    };

    return {
      state,
      apply,
      remove,
      close,
      allowText,
      isSymbolMenuOpen: ref(false),
      addSymbolToTextContent,
      textContent,
    };
  },
});

export default Component;
