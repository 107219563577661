const { parseSVG } = require('svg-path-parser');
import { chain } from 'lodash';
import { Point } from '@/canvas/types';
import { SafeAreaSegment } from '@/models/designObject/types';

export function extractPathSegmentsFromSvg(svgString: string) {
  const d = getDFromSvgString(svgString);
  return parseDToPoints(d);
}

/**
 * Get d attribute from path element
 * @param svgString
 */
function getDFromSvgString(svgString: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'image/svg+xml');

  const paths = doc.getElementsByTagName('path');
  if (!paths.length) throw 'No path is present in safe area svg';
  const path = paths[0];

  return path.getAttribute('d') || '';
}

/**
 * Get points from d attribute
 * @param d
 */
function parseDToPoints(d: string): SafeAreaSegment[] {
  const commands = parseSVG(d);
  const groupedCmds = chain(commands).groupBy('code').value();
  const mCmds = (groupedCmds as any)['M'];
  const cCmds = (groupedCmds as any)['C'];
  const lCmds = (groupedCmds as any)['L'];

  const points: SafeAreaSegment[] = mCmds.map((val: Point, index: number) => {
    const startPoint: Point = {
      x: val.x,
      y: val.y,
    };
    let ctrl1: Point | undefined;
    let ctrl2: Point | undefined;
    let endPoint: Point | undefined;
    const currC = cCmds ? cCmds[index] : null;
    if (currC) {
      ctrl1 = {
        x: currC.x1,
        y: currC.y1,
      };
      ctrl2 = {
        x: currC.x2,
        y: currC.y2,
      };
      endPoint = {
        x: currC.x,
        y: currC.y,
      };
    } else {
      const currL = lCmds ? lCmds[index] : null;
      if (currL)
        endPoint = {
          x: currL.x,
          y: currL.y,
        };
    }
    return {
      startPoint,
      ctrl1,
      ctrl2,
      endPoint,
    };
  });
  return points;
}
