var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._b({attrs:{"items":_vm.visibleFonts,"menu-props":{ offsetY: true },"loading":_vm.loading,"item-value":"fontFamily","item-text":"fontFamily"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({
        'font-family': ((item.fontFamily) + ", 'Josefin Sans', sans-serif !important"),
      }),domProps:{"textContent":_vm._s(item.fontFamily)}})]}},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('span',{style:({
        'font-family': ((item.fontFamily) + ", 'Josefin Sans', sans-serif !important"),
      }),domProps:{"textContent":_vm._s(item.fontFamily)}})]}}]),model:{value:(_vm.state.selectedFont),callback:function ($$v) {_vm.$set(_vm.state, "selectedFont", $$v)},expression:"state.selectedFont"}},'v-select',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }