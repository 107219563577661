

















































































import {
  computed,
  defineComponent,
  reactive,
  ref,
  SetupContext,
  watch,
} from '@vue/composition-api';
import { DesignObjectType } from '@/models/DesignObjectType';
import ItemSliderList from '@/components/elements/ItemSliderList.vue';
import ItemGridList from '@/components/elements/ItemGridList.vue';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { Size } from '@/models/catalog/product/Size';
import { formatCurrency } from '@/utils/currencyUtils';
import { ProductObject } from '@/models/designObject/ProductObject';
import AddEngravingButton from '@/components/elements/AddEngravingButton.vue';
import Footer from '@/components/elements/Footer.vue';
import { ROUTES } from '@/router/constants';
import { stepBackInCatalogTree } from '@/utils/catalogUtils';

interface State {
  components: CatalogItem[];
  siblings: CatalogItem[];
  sizes: Size[];
  selectedComponent: CatalogItem | null;
  selectedSize: Size | null;
  selectedSizePrice: number;
  selectedObj: ProductObject | null;
  isRootLevel: boolean;
  isLastLevel: boolean;
  showDetails: boolean;
}

const Component = defineComponent({
  name: 'ComponentTab',
  components: {
    ItemSliderList,
    ItemGridList,
    AddEngravingButton,
    Footer,
  },

  setup(_, { root: { $store, $router } }: SetupContext) {
    const type = DesignObjectType.COMPONENT;

    const state = reactive({
      components: computed(() => $store.getters[`${type}/subItems`]),
      siblings: computed(() => $store.getters[`${type}/siblings`]),
      sizes: computed(() => state.selectedComponent?.sizes ?? []),
      selectedComponent: computed(() => $store.getters[`${type}/selectedItem`]),
      selectedSize: computed(() => $store.getters[`${type}/selectedSize`]),
      selectedSizePrice: computed(
        () =>
          state.selectedSize?.price ?? state.selectedComponent?.minPrice ?? 0,
      ),
      selectedObj: computed(
        () => $store.getters[`design/selectedProductObject`],
      ),
      isRootLevel: computed(
        () =>
          !state.siblings.length &&
          state.components.every(item => !item.parentId),
      ),
      isLastLevel: computed(
        () =>
          state.components.every(item => item.isProduct) ||
          !!state.siblings.length,
      ),
      showDetails: computed(() => $store.getters[`${type}/showDetails`]),
    }) as State;

    const items = computed(() =>
      state.selectedComponent?.isProduct ? state.siblings : state.components,
    );

    const isMobile = computed(() => $store.getters.isMobile);

    const isShowMoreBtnVisible = ref(false);

    // Select component from list according to selected canvas object
    watch(
      () => state.selectedObj,
      value => {
        if (!value) return;
        if (value.type !== type) return;
        selectComponent.value(value.product);
      },
    );

    /**
     * if last level and product is not selected, select first item by default
     * */
    watch(
      () => state.isLastLevel,
      value => {
        const isSelectedItemIsProduct = state.selectedComponent?.isProduct;
        if (value && !isSelectedItemIsProduct)
          selectComponent.value(state.components[0]);
      },
    );

    // redirect to text tab on edit button click
    watch(
      () => state.showDetails,
      value => {
        if (value) {
          $router.push(ROUTES.textTab.path);
          $store.dispatch(`${type}/showDetails`, false);
        }
      },
    );

    function addComponent() {
      if (!state.selectedComponent) return;
      $store.dispatch('design/addComponent', {
        product: state.selectedComponent,
        sizeId: state.selectedComponent.sizes[0].id,
      });
    }

    const selectComponent = computed(
      () => (item: CatalogItem) =>
        $store.dispatch(`${type}/setSelectedItem`, item),
    );

    const isRemoveBtnVisible = computed(
      () =>
        state.selectedObj &&
        state.selectedObj.type === type &&
        state.isLastLevel,
    );

    const isAddBtnVisible = computed(
      () => state.selectedComponent && state.selectedComponent.isProduct,
    );

    function removeComponent() {
      if (!state.selectedObj) return;
      if (state.selectedObj.type !== type) return;
      $store.dispatch('design/deleteProductObject', state.selectedObj.id);
    }

    const returnBack = computed(
      () => () => stepBackInCatalogTree(state.selectedComponent, $store, type),
    );

    const seeMore = () => {
      $router.push({
        name: ROUTES.viewMore.name,
        params: {
          designObjectType: type,
        },
      });
    };

    return {
      type,
      items,
      state,
      seeMore,
      isMobile,
      returnBack,
      addComponent,
      formatCurrency,
      removeComponent,
      isAddBtnVisible,
      selectComponent,
      isShowMoreBtnVisible,
      isRemoveBtnVisible,
    };
  },
});

export default Component;
