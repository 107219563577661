






















import {
  computed,
  defineComponent,
  reactive,
  SetupContext,
} from '@vue/composition-api';

const Component = defineComponent({
  name: 'AlertsContainer',

  setup(_, { root: { $store } }: SetupContext) {
    const state = reactive({
      alerts: computed(() => $store.getters['alerts']),
    });

    return { state };
  },
});

export default Component;
