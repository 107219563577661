

















import {
  computed,
  defineComponent,
  reactive,
  SetupContext,
} from '@vue/composition-api';
import { formatCurrency } from '@/utils/currencyUtils';

const Component = defineComponent({
  name: 'PriceCard',

  setup(props, { root: { $store } }: SetupContext) {
    const state = reactive({
      quoteItems: computed(() => $store.getters['design/quoteItems']),
    });

    return {
      state,
      formatCurrency,
    };
  },
});

export default Component;
