






import { defineComponent } from '@vue/composition-api';

const Component = defineComponent({
  name: 'OrientationOverlay',
});

export default Component;
