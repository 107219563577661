





















import { defineComponent, ref } from '@vue/composition-api';
import ScreenRulers from '@/components/utils/dev/ScreenRulers.vue';
import CanvasLogs from '@/components/utils/dev/CanvasLogs.vue';

const Component = defineComponent({
  name: 'DevFab',

  components: {
    ScreenRulers,
    CanvasLogs,
  },

  setup() {
    const fab = ref(false);
    const showRulers = ref(false);
    const showCanvasLogs = ref(false);

    return {
      fab,
      showRulers,
      showCanvasLogs,
    };
  },
});

export default Component;
