












































import { computed, defineComponent, SetupContext } from '@vue/composition-api';
import { formatCurrency } from '@/utils/currencyUtils';

const Component = defineComponent({
  name: 'CartView',

  filters: {
    currency: function (value: number) {
      return formatCurrency(value);
    },
  },

  setup(props, { root: { $store, $router }, emit }: SetupContext) {
    const showCartDetails = computed(() => $store.getters.showCartDetails);

    const orderItems = computed(() => {
      if (!showCartDetails) return [];
      return $store.getters['design/productObjects'];
    });

    const total = computed(() => $store.getters['design/totalPrice']);

    function startOver() {
      $store.dispatch('design/resetState');
      emit('close');
      $router.push({ name: 'categoriesTab' });
    }

    return {
      showCartDetails,
      orderItems,
      total,
      startOver,
    };
  },
});

export default Component;
