













































































import {
  computed,
  defineComponent,
  reactive,
  ref,
  SetupContext,
} from '@vue/composition-api';
import { formatCurrency } from '@/utils/currencyUtils';
import PriceCard from '@/components/elements/PriceCard.vue';

interface State {
  total: number;
  showSideBar: boolean;
  isExtraSmallScreen: boolean;
}

const Component = defineComponent({
  name: 'CanvasAreaActions',

  components: {
    PriceCard,
  },

  setup(props, { root: { $store } }: SetupContext) {
    const state = reactive({
      total: computed(() => $store.getters['design/totalPrice']),
      showSideBar: computed(() => $store.getters['showSideBar']),
    }) as State;

    const isExtraSmallScreen = computed(
      () => $store.getters['isExtraSmallScreen'],
    );
    const isMobile = computed(() => $store.getters.isMobile);

    const isPriceMenuOpen = ref(false);
    const isZoomMenuOpen = ref(false);

    const toggleComponentsTab = computed(() => () => {
      $store.dispatch('showSideBar', !state.showSideBar);
    });

    const showDetailsIcon = computed(() => {
      if (isExtraSmallScreen)
        return state.showSideBar ? 'mdi-chevron-down' : 'mdi-chevron-up';
      else return state.showSideBar ? 'mdi-chevron-up' : 'mdi-chevron-down';
    });

    const buttonSize = computed(() => {
      if (state.isExtraSmallScreen) return 32;
      else if (isMobile.value) return 32;
      return 42;
    });

    const zoom = (value: number | 'FIT' | 'CUSTOM') => {
      $store.dispatch('design/updateCanvasZoom', { zoom: value });
    };

    const zoomLevels: { text: string; value: number | 'FIT' | 'CUSTOM' }[] = [
      { text: '75%', value: 75 },
      { text: '100%', value: 100 },
      { text: '125%', value: 125 },
      { text: 'FIT', value: 'FIT' },
    ];

    return {
      state,
      formatCurrency,
      isMobile,
      isExtraSmallScreen,
      isPriceMenuOpen,
      toggleComponentsTab,
      buttonSize,
      showDetailsIcon,
      zoom,
      zoomLevels,
      isZoomMenuOpen,
    };
  },
});

export default Component;
