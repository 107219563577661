import { fabric } from 'fabric';
import {
  setupControlsTheme,
  setupDeleteControl,
  setupDetailsIcon,
  setupRotateControl,
} from '@/canvas/helpers/controls';
import { CanvasMode, Dimensions, FabricEvents } from '@/canvas/types';
import { getLocationObjId } from '@/canvas/utils/idsUtils';
import { IEvent } from 'fabric/fabric-impl';
import { OutputType } from '@/store/modules/design/types';
import Hammer from 'hammerjs';
import { clamp, clone } from 'lodash';

/**
 * Canvas padding - space between objects and canvas edges \
 * For actual used padding see {@link FabricCanvas.padding}
 */
export const enum PaddingUnit {
  PIXEL = 'pixel',
  PERCENT = 'percent',
}
export interface CanvasPadding {
  value: number;
  unit: PaddingUnit;
}

export interface ZoomRange {
  min: number;
  max: number;
}

export interface CanvasCustomizationOptions {
  backgroundColor: string;
  padding: CanvasPadding;
  setupControls?: boolean;
  zoomRange?: ZoomRange;
  enableZoom?: boolean;
  workingArea?: Dimensions;
}

export default class FabricCanvas {
  private _canvas: fabric.Canvas;
  private mode: CanvasMode = CanvasMode.Default;
  private outputType: OutputType | null = null;
  private _padding: CanvasPadding = { value: 10, unit: PaddingUnit.PERCENT };
  private hammer;
  private canvasZoom = 1;
  private panCoords = { x: 0, y: 0 };
  /** Zoom range values */
  private zoomRange: ZoomRange = { min: 0.3, max: 2 };
  /**
   * Working area - area to fit design into \
   * Specified in px in canvas coord system
   * Not the same as canvas size
   * General approach is the following
   * 1. Design objects are **scaled** to fit within workingArea
   * 2. Canvas HTML element is **resized** according to layout and screen type
   * 2. Canvas is **zoomed** to fit working area
   */
  private workingArea: Dimensions = { width: 800, height: 800 };
  private enablePan = false; // temporary way to disable panning when design is scaled to fit
  /**
   * Custom wrap for fabric.Canvas. \
   * By default the following options are applied (can be overridden):
   * - disabled selection by dragging
   * - disable multiselect
   * - preserve object stacking order
   *
   * @param canvasElement html canvas element
   * @param fabricOptions fabric canvas options
   */
  public constructor(
    canvasElement: HTMLCanvasElement | string,
    fabricOptions?: fabric.ICanvasOptions,
    customOptions?: Partial<CanvasCustomizationOptions>,
  ) {
    const defaultFabricOptions = {
      renderOnAddRemove: false,
      preserveObjectStacking: true,
      selectionKey: 'none',
      selection: false, // disable multiselect, select by dragging
      targetFindTolerance: 2,
    };

    this._canvas = new fabric.Canvas(canvasElement, {
      ...defaultFabricOptions,
      ...fabricOptions,
    });
    this.hammer = new Hammer.Manager(this.canvas.getSelectionElement());
    setupControlsTheme();
    setupRotateControl();
    if (customOptions?.enableZoom) this.setupZoom();

    // Controls with custom handlers should be setup only for a main canvas to avoid controls conflicts
    if (customOptions?.setupControls) {
      this.setupControls();
    }
    this.attachClearIconsEvent();

    if (customOptions?.backgroundColor)
      this.canvas.setBackgroundColor(customOptions?.backgroundColor, () => {});
    if (customOptions?.padding) this._padding = customOptions.padding;
    if (customOptions?.zoomRange) this.zoomRange = customOptions.zoomRange;
    if (customOptions?.workingArea)
      this.workingArea = customOptions.workingArea;
  }

  public getObjects() {
    return this.canvas.getObjects();
  }

  public get canvas(): fabric.Canvas {
    return this._canvas;
  }

  setupZoom() {
    this.wheelZoom();
    const doubleTap = this.doubleTap();
    const pan = this.pan();
    const pinch = this.pinchZoom();
    doubleTap.recognizeWith(pan);
    pan.requireFailure([doubleTap]);
  }

  /** Handle zoom with mouse wheel on desktop devices. */
  wheelZoom() {
    this.canvas.on('mouse:wheel', opt => {
      const delta = opt.e.deltaY;
      const zoom = clamp(
        this.canvas.getZoom() * 0.999 ** delta,
        this.zoomRange.min,
        this.zoomRange.max,
      );
      const point = new fabric.Point(opt.e.offsetX, opt.e.offsetY);
      this.canvas.zoomToPoint(point, zoom);
      this.canvas.requestRenderAll();
      opt.e.preventDefault();
      opt.e.stopPropagation();
      this.canvasZoom = zoom;
      this.enablePan = true;
      this.canvas.fire(FabricEvents.ViewportChange, {
        zoomLabel: 'CUSTOM',
        zoom: this.canvas.getZoom(),
        viewport: this.canvas.viewportTransform,
      });
    });
  }

  /** Handle zoom with a pinch gesture for mobiles. */
  pinchZoom() {
    const pinch = new Hammer.Pinch();
    this.hammer.add([pinch]);

    this.hammer.on('pinchmove', ev => {
      if (this.canvas.getActiveObject()) return;
      if (ev.pointers.length < 2) return;
      const scale = clamp(
        this.canvasZoom * ev.scale,
        this.zoomRange.min,
        this.zoomRange.max,
      );
      const point = this.canvas.getPointer(ev.srcEvent, true);
      this.canvas.zoomToPoint(point, scale);
      this.canvas.requestRenderAll();
    });

    this.hammer.on('pinchend', ev => {
      if (this.canvas.getActiveObject()) return;
      const scale = clamp(
        this.canvasZoom * ev.scale,
        this.zoomRange.min,
        this.zoomRange.max,
      );
      this.canvasZoom = scale;
      this.enablePan = true;
      this.canvas.fire(FabricEvents.ViewportChange, {
        zoomLabel: 'CUSTOM',
        zoom: this.canvas.getZoom(),
        viewport: this.canvas.viewportTransform,
      });
    });

    return pinch;
  }

  /**
   * Handle double tap.
   * Double tap on empty space - reset zoom
   * Double tap on object - open editing tab
   */
  doubleTap() {
    const doubleTap = new Hammer.Tap({
      event: 'doubletap',
      taps: 2,
    });
    this.hammer.add([doubleTap]);

    this.hammer.on('doubletap', ev => {
      if (!this.canvas.getActiveObject()) {
        this.resetZoom();
      }
      this.canvas.fire(FabricEvents.ShowDetails, {
        ev,
        target: this.canvas.getActiveObject(),
      });
    });

    return doubleTap;
  }

  /**
   * Add pan event to the canvas.
   * For both desktop and mobile devices.
   * TODO: add bounds for panning
   */
  pan() {
    const pan = new Hammer.Pan();
    this.hammer.add([pan]);
    this.hammer.on('panstart', ev => {
      this.panCoords = {
        x: ev.center.x,
        y: ev.center.y,
      };
    });
    this.hammer.on('pan panmove', ev => {
      if (!this.enablePan) return;
      if (this.canvas.getActiveObject()) return;
      // pan with single touch
      if (ev.maxPointers != 1) return;

      const vpt = this.canvas.viewportTransform;
      if (!vpt) return;
      vpt[4] += ev.center.x - this.panCoords.x;
      vpt[5] += ev.center.y - this.panCoords.y;

      this.canvas.requestRenderAll();

      this.panCoords = {
        x: ev.center.x,
        y: ev.center.y,
      };
    });

    this.hammer.on('panend', ev => {
      const vpt = this.canvas.viewportTransform;
      if (!vpt) return;
      this.canvas.setViewportTransform(vpt);
      this.canvas.fire(FabricEvents.ViewportChange, {
        zoomLabel: 'CUSTOM',
        zoom: this.canvas.getZoom(),
        viewport: this.canvas.viewportTransform,
      });
    });

    return pan;
  }

  /**
   * Get actual calculated canvas padding value
   */
  public get padding(): number {
    const { value, unit } = this._padding;
    if (unit === PaddingUnit.PIXEL) return value;
    const { width, height } = this.getCanvasSize();
    const padding = Math.min((width / 100) * value, (height / 100) * value);
    return padding;
  }

  private paddingRect?: fabric.Rect = undefined; //todo: remove dev object

  // Todo: controls handlers should be canvas independent
  // Reasoning:
  // Conflicts happens when setting custom handlers to controls bound to this
  // As controls are set via prototype, it is shareable between all canvas instances
  // one can access canvas through event target object instead
  public setupControls() {
    setupDeleteControl(this.onDeleteButtonClicked.bind(this));
    setupDetailsIcon(this.onEditButtonClicked.bind(this));
  }

  public addObject(obj: fabric.Object, index: number = -1) {
    this.canvas.insertAt(obj, index, false);
    // if object with higher index was loaded faster, it should be after curr object
    while (this.canvas.getObjects()[index - 1]?.data?.index > obj.data?.index) {
      this.canvas.moveTo(obj, index - 1);
    }
    return obj.data.id;
  }

  public requestRender() {
    this.canvas.requestRenderAll();
  }

  public addObjects(objs: fabric.Object[]) {
    this.canvas.add(...objs);
  }

  public replaceObject(obj: fabric.Object, index?: number) {
    if (this.getObjById(obj.data.id)) this.deleteObjects([obj.data.id]);
    return this.addObject(obj, index);
  }

  public clear() {
    this.canvas.clear();
  }

  public setCanvasMode(mode: CanvasMode, outputType: OutputType | null = null) {
    this.mode = mode;
    if (this.mode === CanvasMode.GenerateOutput) {
      this.outputType = outputType;
    } else {
      this.outputType = null;
    }
  }

  public getCanvasMode() {
    return this.mode;
  }

  // objects number that should be displayed on canvas
  private objsQuantity: number = 0;
  // objects number that are already displayed
  private displayedObjsQuantity: number = 0;
  /**
   * Set multiple objects mode on
   * @param objsQuantity
   */
  public multipleObjsModeOn(objsQuantity: number) {
    // set multiple objs mode on if not already set
    if (!this.objsQuantity) {
      this.objsQuantity = objsQuantity;
      this.attachEventListener(FabricEvents.ObjectAdded, this.onAddObjHandler);
    }
  }

  /**
   * Turn off multiple objects mode
   */
  public multipleObjsModeOff() {
    this.objsQuantity = 0;
    this.displayedObjsQuantity = 0;
    this.outputType = null;

    this.canvas.off(FabricEvents.ObjectAdded, this.onAddObjHandler);
  }

  private onAddObjHandler = () => this.onAddObj();

  /**
   * Handle add object event
   * Works only in output mode
   * When all objects are added, fires ReadyForOutput event
   */
  private async onAddObj() {
    this.displayedObjsQuantity++;

    if (this.displayedObjsQuantity >= this.objsQuantity) {
      this.canvas.fire(FabricEvents.MultipleObjectsAdded, {
        target: this.outputType,
      });
    }
  }

  public deleteObjects(ids: string[]) {
    const objects: fabric.Object[] = [];
    ids.forEach(i => {
      const obj = this.getObjById(i);
      if (obj) objects.push(obj);
    });
    this.canvas.remove(...objects);
  }

  /**
   * Select or deselect object ob canvas by id
   * @param id id of thr object to select, null to remove selection
   * @returns
   */
  public setActiveObject(id: string | null) {
    if (!id) {
      // remove selection
      this.canvas.discardActiveObject().renderAll();
      return;
    }
    // skip selecting already selected obj
    if (id === this.canvas.getActiveObject()?.data?.id) {
      return;
    }
    // select object on canvas by id
    const obj = this.getObjById(id);
    if (obj) this.canvas.setActiveObject(obj).renderAll();
  }

  public getObjById(id: string): fabric.Object | null {
    return this.deepFindObject(id);
  }

  /**
   * Get canvas actual width and height (in px)
   * @returns canvas dimensions
   */
  public getCanvasSize(): Dimensions {
    const width = this.canvas.getWidth();
    const height = this.canvas.getHeight();
    return { width, height };
  }

  public setCanvasSize(size: Dimensions) {
    this.canvas.setDimensions(size);
  }

  /**
   * Scale canvas and all it's content to certain size
   * Needed when window is resized or design is loaded
   * @param size
   * @param canvasSize
   */
  public scaleToSize(size: Dimensions, canvasSize: Dimensions) {
    if (size.width === 0 || size.height === 0) return;
    // resize canvas to fit it's container
    this.setCanvasSize(size);
    this.resetZoom(canvasSize);
  }

  public resizeCanvas(size: Dimensions) {
    this.setCanvasSize(size);
    this.scaleContentToFit();
    this.addPaddingRect(); // todo: remove padding rect
    this.requestRender();
  }

  public scaleContentToFit() {
    const { width, height } = this.getCanvasSize();

    // save active obj
    const activeObject = this.canvas.getActiveObject();
    this.canvas.discardActiveObject();

    // select all objects on canvas
    this.deletePaddingRect();
    const allSelection = new fabric.ActiveSelection(this.canvas.getObjects(), {
      canvas: this.canvas,
    });

    // calculate scale factor to fit all objects into canvas
    // canvas padding is taken into account)
    const w = allSelection.width || 1;
    const h = allSelection.height || 1;

    const scaleFactor = Math.min(
      (width - this.padding * 2) / w,
      (height - this.padding * 2) / h,
    );
    allSelection.scale(scaleFactor);

    // Clear and redraw  objects on canvas
    this.canvas.clear();
    this.canvas.centerObject(allSelection);
    for (const obj of allSelection.getObjects()) {
      this.canvas.add(obj);
    }

    // restore active obj
    this.canvas.discardActiveObject();
    if (activeObject) this.canvas.setActiveObject(activeObject);
  }

  /**
   * Reset zoom canvas to fit all content.
   * @param workingAreaSize size of the working area, see canvasSize in the Vuex store
   */
  public resetZoom(workingAreaSize: Dimensions = this.workingArea) {
    // 1. Reset canvas zoom & panning to the initial ones
    const canvasSize = this.getCanvasSize();
    const initZoom = Math.min(
      canvasSize.width / workingAreaSize.width,
      canvasSize.height / workingAreaSize.height,
    );
    this.canvas.setZoom(initZoom);
    this.canvas.absolutePan({ x: 0, y: 0 });

    // 2. Group all canvas content
    const allGroup = new fabric.Group(this.canvas.getObjects(), {});

    // 3. Find zoom to fit content
    const bbox = allGroup.getBoundingRect(true);
    const zoomFactor = Math.min(
      canvasSize.width / bbox.width,
      canvasSize.height / bbox.height,
      initZoom,
    );
    this.canvas.setZoom(zoomFactor);
    this.canvasZoom = zoomFactor;

    // 4. Pan canvas to center the content
    const canvasCenter = this.canvas.getCenter();
    const center = allGroup.getCenterPoint();
    const newLeft = -canvasCenter.left + center.x * initZoom;
    const newTop = -canvasCenter.top + center.y * initZoom;
    const point = new fabric.Point(newLeft, newTop);
    this.canvas.absolutePan(point);

    // 5. Destroy group and render
    allGroup.destroy();
    this.requestRender();

    // hack to clear hammerjs events
    // sometimes pointdown is recorded by hammerjs but point up is not
    // this behavior breaks further pinch, pan events
    // such behavior was noticed the designer is in the iframe
    // and canvas gets deactivated (e..g when text panel is opened)
    // @ts-ignore
    this.hammer.input.store = [];

    // 6. Fire event to trigger store changes
    this.enablePan = false;
    this.canvas.fire(FabricEvents.ViewportChange, {
      zoomLabel: 'FIT',
      zoom: this.canvas.getZoom(),
      viewport: this.canvas.viewportTransform,
    });
  }

  /**
   * Reset zoom and then Apply provided zoomInput.
   * Zooming origin is canvas center.
   * zoomInput is not equal to the real canvas zoom, \
   * as canvas is also zoomed to fit within device screen.
   * Thus 125% zoom on mobile and desktop screens shall differ.
   *
   * e.g.
   * canvas actual 'fit' zoom is 0.7
   * applying zoom 125% shall result in 0.7 * 1.25 = 0.875 actual zoom value
   *
   * @param zoomInput zoom value in %
   * @param workingAreaSize size of the working area, see canvasSize in the Vuex store
   */
  public applyZoom(zoomInput: number, workingAreaSize: Dimensions) {
    this.resetZoom(workingAreaSize);
    const zoom = clamp(
      this.canvas.getZoom() * (zoomInput / 100),
      this.zoomRange.min,
      this.zoomRange.max,
    );
    const { left, top } = this.canvas.getCenter();
    const point = new fabric.Point(left, top);
    this.canvas.zoomToPoint(point, zoom);
    this.requestRender();
    this.enablePan = zoomInput !== 100;
    this.canvas.fire(FabricEvents.ViewportChange, {
      zoomLabel: zoomInput,
      zoom: this.canvas.getZoom(),
      viewport: this.canvas.viewportTransform,
    });
  }

  /**
   * Visualize padding on canvas
   * Kept for development purposes
   */
  private addPaddingRect() {
    if (this.paddingRect) this.deletePaddingRect();
    const { width, height } = this.getCanvasSize();
    this.paddingRect = new fabric.Rect({
      width,
      height,
      top: -this.padding,
      left: -this.padding,
      fill: 'transparent',
      stroke: 'palegreen',
      strokeWidth: this.padding * 2,
      selectable: false,
      evented: false,
      opacity: 0.5,
    });
    this.canvas.add(this.paddingRect);
  }

  private deletePaddingRect() {
    if (!this.paddingRect) return;
    this.canvas.remove(this.paddingRect);
  }

  /**
   * Get low res screenshot of current canvas in jpeg format
   *
   * @param previewSize size of the output image
   * @param canvasSize size of the canvas that is stored in the Vuex store
   * @returns
   */
  public getCartPreview(previewSize: Dimensions, canvasSize: Dimensions) {
    // save current canvas viewport
    const viewport = clone(this.canvas.viewportTransform) ?? [];
    const size = this.getCanvasSize();

    // reset canvas zoom and panning
    this.setCanvasSize(previewSize);
    this.resetZoom(canvasSize);

    const changeBG =
      !this.canvas.backgroundColor && !this.canvas.backgroundImage;
    if (changeBG) this.canvas.setBackgroundColor('white', () => {});
    const img = this.canvas.toDataURL({
      format: 'jpeg',
      quality: 0.7,
    });

    // restore saved canvas size and viewport
    this.setCanvasSize(size);
    this.canvas.setViewportTransform(viewport);
    if (changeBG) this.canvas.setBackgroundColor('', () => {});
    return img;
  }

  /**
   * Get screenshot of current canvas in png format
   */
  public getCanvasPreview(canvasSize: Dimensions) {
    this.resetZoom(canvasSize);
    return this.canvas.toDataURL({
      format: 'png',
    });
  }

  /** Attach handler for several events */
  public attachEventListeners(
    eventNames: string[],
    eventHandler: (event?: IEvent) => Promise<void>,
  ) {
    eventNames.forEach((eventName: string) =>
      this.attachEventListener(eventName, eventHandler),
    );
  }

  /** Attach handler for single event */
  public attachEventListener(
    eventName: string,
    eventHandler: (event?: IEvent) => Promise<void>,
  ) {
    this.canvas.on(eventName, eventHandler);
  }

  private onDeleteButtonClicked(
    event: Event,
    control: { target: fabric.Object },
  ) {
    if (!this.getObjById(control.target.data.id)) return;
    this.canvas.fire(FabricEvents.ObjectDeleted, { target: control.target });
    const children = this.canvas
      ?.getObjects()
      .filter(
        (obj: fabric.Object) => obj.data?.parentId === control.target.data?.id,
      );
    children.forEach(child => {
      this.canvas.fire(FabricEvents.ObjectDeleted, { target: child });
    });
  }

  private async onEditButtonClicked(
    event: Event,
    control: { target: fabric.Object },
  ) {
    if (!this.getObjById(control.target.data.id)) return;
    this.canvas.fire(FabricEvents.ShowDetails, { target: control.target });
  }

  /**
   * Remove all custom control icons when object is deselected
   * Needed only for rings
   */
  private attachClearIconsEvent() {
    // find previously selected object (parent)
    let prevSelectedId: string | undefined;
    this.attachEventListener(
      'before:selection:cleared',
      async (event?: IEvent) => {
        prevSelectedId = this.canvas.getActiveObject()?.data?.id;
      },
    );

    // updated previously selected id when selection change
    this.canvas.on(FabricEvents.SelectionUpdated, async event => {
      //@ts-ignore
      prevSelectedId = event.deselected?.data?.id;
    });

    // remove control icons when
    // * clicked outside parent object
    // * clicked outside control icon
    // * selection changed
    this.attachEventListeners(
      [FabricEvents.MouseDown, FabricEvents.SelectionUpdated],
      async (event?: IEvent) => {
        const fabricObject = event?.target;
        if (
          !fabricObject ||
          (fabricObject?.data?.controlIcon &&
            fabricObject?.data?.parentId !== prevSelectedId) ||
          (!fabricObject?.data?.controlIcon &&
            fabricObject?.data?.id !== prevSelectedId)
        ) {
          const icons = this.canvas
            .getObjects()
            .filter(obj => obj?.data?.controlIcon);
          this.canvas.remove(...icons);
        }
      },
    );
  }

  private deepFindObject(id: string, locationId?: number) {
    let object: fabric.Object | null = null;

    function deepFind(root: fabric.Object, id: string) {
      if (object) return;
      if ((<fabric.Group>root)._objects) {
        (<fabric.Group>root)._objects.forEach(o => deepFind(o, id));
      }
      if (root.data && root.data.id === getLocationObjId(id, locationId)) {
        object = root;
      }
    }

    this.canvas._objects.forEach(o => deepFind(o, id));
    return object;
  }

  /**
   * util function for logging during development
   * See CanvasLogs component
   */
  log(...args: any[]) {
    const el = document.getElementById('canvas-log');
    if (!el) return;
    el.textContent = JSON.stringify(args, undefined, 2);
    console.log('[canvas]: ', ...args);
  }
}
