







































import {
  computed,
  defineComponent,
  reactive,
  SetupContext,
  watch,
} from '@vue/composition-api';
import { ProductObject } from '@/models/designObject/ProductObject';
import { Location } from '@/models/catalog/product/Location';
import { TextObject } from '@/models/designObject/TextObject';
import EngravingPreview from '@/components/elements/EngravingPreview.vue';
import { DesignObject } from '@/models/designObject/DesignObject';
import { DesignObjectType } from '@/models/DesignObjectType';
import TabCardLayout from '@/components/elements/TabCardLayout.vue';
import { ROUTES } from '@/router/constants';

interface State {
  // Actual selected object on canvas
  selectedObj: DesignObject | null;
  // Product object, the lettering is on
  parentObj: ProductObject | null;
  // All parent texts
  parentTextObjects: TextObject[];

  locations: Location[];
  selectedLocation?: Location;
}

/**
 * Details tab - tab showing details about selected component.
 * This tab can an extra step before adding engraving
 * ! Note: currently is not used
 */
const Component = defineComponent({
  name: 'DetailsTab',
  components: {
    EngravingPreview,
    TabCardLayout,
  },

  setup(_, { root: { $store, $router, $i18n } }: SetupContext) {
    const state = reactive({
      selectedObj: computed(() => $store.getters[`design/selectedObject`]),
      parentObj: computed(() => {
        if (state.selectedObj?.type === DesignObjectType.TEXT)
          return $store.getters[`design/selectedParentProductObject`];
        return $store.getters[`design/selectedProductObject`];
      }),
      locations: computed(() => {
        let locations: Location[] = [];
        if (state.parentObj?.product.locations)
          locations = state.parentObj?.product.locations;
        return locations;
      }),
      selectedLocation: computed({
        get: () =>
          state.locations.find(l => l.id === state.parentObj?.locationId),
        set: value => changeLocation(value),
      }),
      parentTextObjects: computed(() =>
        $store.getters[`design/texts`].filter(
          ({ parentId }: TextObject) => parentId === state.parentObj?.id,
        ),
      ),
    }) as State;

    const isCharmType = computed(
      () => state.parentObj?.type === DesignObjectType.COMPONENT,
    );

    async function changeLocation(location?: Location) {
      if (!state.parentObj) return;
      if (!location) return;
      if (location.id === state.parentObj.locationId) return;
      await $store.dispatch('design/updateProductObject', {
        id: state.parentObj.id,
        data: {
          sizeId: state.parentObj.sizeId,
          locationId: location.id,
        },
      });
    }

    watch(
      () => state.parentObj,
      value => {
        if (!value) close();
      },
    );

    const close = () => {
      $store.dispatch('design/deselectObject');
      $router.push({
        name: isCharmType ? ROUTES.component.name : ROUTES.baseProduct.name,
      });
    };

    const allowText = computed(() => state.selectedLocation?.allowText);

    const addText = () => $router.replace(ROUTES.textTab.path);

    const deleteCharm = computed(() => () => {
      if (!state.parentObj) return;
      if (!isCharmType.value) return;

      $store.dispatch('design/deleteProductObject', state.parentObj.id);
      close();
    });

    const engravingBtnText = computed(() => {
      const hasText = !!state.parentTextObjects.find(
        ({ locationId }) => locationId === state.selectedLocation?.id,
      );
      return hasText
        ? $i18n.t('buttons.editEngraving')
        : $i18n.t('buttons.addEngraving');
    });

    return {
      state,
      close,
      addText,
      allowText,
      deleteCharm,
      isCharmType,
      engravingBtnText,
    };
  },
});

export default Component;
