import { render, staticRenderFns } from "./ItemSliderList.vue?vue&type=template&id=0e0a3e69&scoped=true&"
import script from "./ItemSliderList.vue?vue&type=script&lang=ts&"
export * from "./ItemSliderList.vue?vue&type=script&lang=ts&"
import style0 from "./ItemSliderList.vue?vue&type=style&index=0&id=0e0a3e69&prod&lang=scss&scss=true&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e0a3e69",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VBtn,VCard,VChip,VIcon,VImg,VProgressCircular,VRow,VSheet,VSlideGroup,VSlideItem})
