import FabricCanvas from '@/canvas/fabric/FabricCanvas';
import { watch } from '@vue/composition-api';
import { Store } from 'vuex';
import { RootState } from '@/store/store';
import { useStore } from '@/composables/useStore';
import { OutputState } from '@/store/modules/design/types';
import {
  safeAreaObjToFabricObj,
  productObjToFabricObj,
  textObjToFabricText,
} from '@/canvas/mappers';
import { fabric } from 'fabric';
import { CanvasMode } from '@/canvas/types';

/**
 * Triggered when output preview (front/back) is generated
 * @param canvas
 */
export function watchOutputState(canvas: FabricCanvas) {
  const store: Store<RootState> = useStore();
  watch(
    () => store.getters['design/getOutputState'],
    async (state: OutputState, prevState: OutputState) => {
      canvas.clear();
      const products = state.productObjects;
      const texts = state.texts;
      const safeAreas = state.safeAreas;

      const objsToDisplay: fabric.Object[] = [];
      await Promise.all(
        safeAreas.map(async safeArea => {
          const objToAdd = await safeAreaObjToFabricObj(safeArea);
          objsToDisplay.push(objToAdd);
        }),
      );

      await Promise.all(
        products.map(async product => {
          const objToAdd = await productObjToFabricObj(product);
          objsToDisplay.push(objToAdd);
        }),
      );
      await Promise.all(
        texts.map(async text => {
          const objToAdd = await textObjToFabricText(text);
          objsToDisplay.push(objToAdd);
        }),
      );

      canvas.setCanvasMode(CanvasMode.GenerateOutput, state.outputType);
      canvas.multipleObjsModeOn(objsToDisplay.length);

      canvas.addObjects(objsToDisplay);
      canvas.requestRender();
    },
  );
}
