/**
 * Canvas events used within app \
 * For more details regarding Fabric events - see http://fabricjs.com/events
 */
export enum FabricEvents {
  // Fabric object events
  ObjectMoved = 'object:moved',
  ObjectMoving = 'object:moving',
  ObjectRotated = 'object:rotated',
  ObjectScaled = 'object:scaled',

  ObjectDeleted = 'object:deleted',
  ObjectAdded = 'object:added',

  // Fabric selection events
  SelectionCreated = 'selection:created',
  SelectionUpdated = 'selection:updated',

  // Fabric mouse events
  MouseDown = 'mouse:down',

  // --- Custom events ---
  ShowDetails = 'showDetails',
  // Works only in output mode
  // Used to notify when design is loaded
  MultipleObjectsAdded = 'multipleObjectsAdded',
  /** Canvas is zoomed or panned */
  ViewportChange = 'viewportChange',
  DoubleTap = 'doubletap',
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface Point {
  x: number;
  y: number;
}

export enum CanvasMode {
  LoadDesign,
  GenerateOutput,
  Default,
}
