








import { computed, defineComponent, SetupContext } from '@vue/composition-api';
import DesktopLayout from '@/components/main/DesktopLayout.vue';
import MobileLayout from '@/components/main/MobileLayout.vue';

const Component = defineComponent({
  name: 'DesignerView',

  components: {
    DesktopLayout,
    MobileLayout,
  },

  setup(_, ctx: SetupContext) {
    const isMobile = computed(() => ctx.root.$store.getters.isMobile);

    /** Show fullscreen router view if provided one */
    const mobileScreen = computed(
      () =>
        isMobile.value &&
        ctx.root.$route.matched.find(r => r.components.mobileView),
    );

    return {
      isMobile,
      mobileScreen,
    };
  },
});

export default Component;
