import { ProductObject } from '@/models/designObject/ProductObject';
import { TextObject } from '@/models/designObject/TextObject';
import { BehaviourOptions, TextCanvasProps } from '@/models/designObject/types';
import { Dimensions } from '@/canvas/types';
import { getFontSize } from '@/calculateProperties/helpers/textFont';
import { getTextTransformMatrix } from '@/calculateProperties/helpers/transformMatrix';
import { getTextBoundingBox } from '@/calculateProperties/helpers/boundingBox';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';

/**
 * Calculate text properties
 * @param textObj
 * @param canvasSize
 * @param parent
 * @param parentSafeArea
 */
export async function textProps(
  textObj: TextObject,
  canvasSize: Dimensions,
  parent?: ProductObject,
  parentSafeArea?: SafeAreaObject,
): Promise<TextCanvasProps> {
  const transformMatrix: number[] = getTextTransformMatrix(
    textObj,
    canvasSize,
    parent,
    parentSafeArea,
  );
  const boundingBox = getTextBoundingBox(textObj, parentSafeArea);
  const behaviourOptions: BehaviourOptions = textBehaviourOptions();

  const fontSize = await getFontSize(textObj, parent, parentSafeArea);
  const isMultiline = isTextMultiline(parent);
  return {
    transformMatrix,
    boundingBox,
    behaviourOptions,
    fontSize,
    isMultiline,
  };
}

export function textVisibility(
  text: TextObject,
  parent?: ProductObject,
): boolean {
  return (
    !!parent && (parent.product.isRing || text.locationId === parent.locationId)
  );
}

export function isTextMultiline(parent?: ProductObject) {
  return !!parent && !parent.product.isRing;
}

export function textBehaviourOptions(): BehaviourOptions {
  return {
    selectable: true,
    rotatable: false,
    resizable: false,
    movable: false,
    deletable: true,
    editable: false,
  };
}
