





















import { defineComponent } from '@vue/composition-api';
import CanvasArea from '@/components/main/CanvasArea.vue';
import BottomBar from '@/components/main/BottomBar.vue';
import SidebarTab from '@/components/main/SidebarTab.vue';

const Component = defineComponent({
  name: 'DesktopLayout',

  components: {
    CanvasArea,
    BottomBar,
    SidebarTab,
  },
});

export default Component;
