import { QuoteItem } from '@/models/quote/QuoteItem';

export class Quote {
  public items: QuoteItem[] = [];
  public total: number = 0;

  public constructor(initData: Partial<Quote>) {
    Object.assign(this, initData);
  }
}
