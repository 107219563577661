import { fabric } from 'fabric';
import { FabricEvents } from '@/canvas/types';
import { setUpCustomControls } from '@/canvas/helpers/customControls';
import {
  applyTransformationMatrix,
  calcPositionOnSafeArea,
} from '@/canvas/utils/modifyObject';
import { DesignObjectType } from '@/models/DesignObjectType';
import { IEvent } from 'fabric/fabric-impl';

/**
 * Moving event for movable objects
 * @param object
 */
export function attachMovingEvent(object: fabric.Object) {
  let movingObject = false;
  let objectMoved = false;
  object.on('mousedown', event => {
    const { target, pointer } = event;
    if (!target) return;
    if (!pointer) return;
    if (!target.containsPoint(pointer)) return;
    // skip if control has been clicked
    if (target._findTargetCorner(pointer)) return;
    movingObject = true;
    objectMoved = false;
  });

  object.on('mousemove', event => {
    if (movingObject) {
      objectMoved = true;
      const canvas = object.canvas;
      if (!canvas) return;

      // 1. Set transformation according to event coords
      const pointer = canvas.getPointer(event.e);
      const transformMatrix: number[] = [...object.calcTransformMatrix()];
      transformMatrix[4] = pointer.x;
      transformMatrix[5] = pointer.y;

      // 2. Snap to the safeArea
      const parentSafeArea = findObjectSafeArea(
        object.canvas?.getObjects() || [],
        object.data?.parentId,
      );
      if (parentSafeArea) {
        const posOnSafeArea = calcPositionOnSafeArea(parentSafeArea, pointer);
        if (posOnSafeArea) {
          transformMatrix[4] = posOnSafeArea.x;
          transformMatrix[5] = posOnSafeArea.y;
        }
      }

      // 3. Apply transformation, fire event
      applyTransformationMatrix(object, transformMatrix);
      object.setCoords();
      object.canvas?.renderAll();
      object.fire('moving');
    }
  });

  object.on('mouseup', e => {
    movingObject = false;
    if (objectMoved) {
      object.canvas?.fire(FabricEvents.ObjectMoved, { target: object });
    }
  });
}

// was used for rings, now not used
export function makeObjectSelectableInGroup(object: fabric.Object) {
  object.on('mousedown', e => {
    const group = <fabric.Group>e.target;
    if (group && group._objects) {
      const item = group._objects.find(o => o === object);
      item?.canvas?.setActiveObject(item);

      if (item) setUpCustomControls(group, item);
    }
  });
}

export function lockChildrenTransformToObject(parent: fabric.Object) {
  let movingEventHandler: (evt: IEvent) => void;
  let parentMoved = false; // shows whether parent was moved

  let children: fabric.Object[] | undefined = [];

  parent.on('mousedown', function (evt) {
    parentMoved = false;

    children = parent?.canvas
      ?.getObjects()
      .filter((obj: fabric.Object) => obj.data?.parentId === parent.data?.id);

    let parentPos = parent.calcTransformMatrix();

    movingEventHandler = function (evt: IEvent) {
      const updatedParentPos = parent.calcTransformMatrix();
      children?.forEach(child => {
        const transformMatrix: number[] = [...child.calcTransformMatrix()];
        transformMatrix[4] = child.left + updatedParentPos[4] - parentPos[4];
        transformMatrix[5] = child.top + updatedParentPos[5] - parentPos[5];
        applyTransformationMatrix(child, transformMatrix);
        child.setCoords();
      });
      parentPos = updatedParentPos;
      parentMoved = true;
    };

    parent.on('moving', movingEventHandler);
  });

  parent.on('mouseup', function () {
    parent.off('moving', movingEventHandler);

    if (parentMoved) {
      children?.forEach(child => {
        parent?.canvas?.fire(FabricEvents.ObjectMoved, { target: child });
      });
    }
  });
}

export function connectScalingToFontSize(fabricObject: fabric.Object) {
  fabricObject.on('scaling', e => {
    //@ts-ignore
    const textObj = <fabric.Text>e.transform?.target;
    if (textObj) {
      // set to false can have bad impact on performance
      textObj.noScaleCache = false;
      if (textObj.fontSize)
        textObj.fontSize = Number.parseInt(
          (textObj.fontSize * (textObj.scaleX || 1)).toFixed(0),
        );
      textObj.scaleX = 1;
      textObj.scaleY = 1;
      textObj._clearCache();
    }
  });
}

function fireEventFromChild(
  objectEvent: string,
  canvasEvent: FabricEvents,
  parent: fabric.Object,
) {
  parent.on(objectEvent, function (evt) {
    const children = parent?.canvas
      ?.getObjects()
      .filter((obj: fabric.Object) => obj.data?.parentId === parent.data?.id);

    children?.forEach(child => {
      parent?.canvas?.fire(canvasEvent, { target: child });
    });
  });
}

function findObjectSafeArea(objects: fabric.Object[], parentId: string) {
  return objects.find(
    obj =>
      obj.data?.parentId === parentId &&
      (obj.data?.type === DesignObjectType.SAFE_LINE ||
        obj.data?.type === DesignObjectType.SAFE_RECT),
  );
}
