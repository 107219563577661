import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  DesignMutationPayload,
  IDesignState,
} from '@/store/modules/design/types';
import { ProductObject } from '@/models/designObject/ProductObject';
import { TextObject, TextObjectData } from '@/models/designObject/TextObject';
import {
  getProductObjectById,
  getSafeAreaByObjId,
  getTextsByParentId,
} from '@/utils/storeUtils';
import { textProps, textVisibility } from '@/calculateProperties/textProps';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { AlertColor } from '@/utils/alerts';

export const textGetters: GetterTree<IDesignState, any> = {
  texts(state): TextObject[] {
    return [...state.texts];
  },

  selectedText(state): TextObject | null {
    const selectedId = state.selectedId;
    if (!selectedId) return null;

    const selectedText = state.texts.find(text => text.id === selectedId);
    return selectedText || null;
  },

  getTextById(state) {
    return (id: string): TextObject | null => {
      return state.texts.find(text => text.id === id) || null;
    };
  },

  getTextIndex(state, getters): number | null {
    // always put text on top of all other elements
    return (
      state.safeAreas.length + state.productObjects.length + state.texts.length
    );
  },

  getTextByObjLocationId(state) {
    return (value: {
      parentId: string;
      locationId: number;
    }): TextObject | null => {
      const { parentId, locationId } = value;
      return (
        state.texts.find(
          text => text.parentId === parentId && text.locationId === locationId,
        ) || null
      );
    };
  },
};

export const textActions: ActionTree<IDesignState, any> = {
  setTexts(
    { state, commit }: ActionContext<IDesignState, any>,
    texts: TextObject[],
  ) {
    commit('setPrevState', state);
    commit('setTexts', { value: texts });
  },

  async addText(
    { state, commit, rootGetters, dispatch }: ActionContext<IDesignState, any>,
    data: { objId: string; locId: number; text?: string },
  ) {
    const { objId, locId } = data;
    commit('setLoading', true, { root: true });
    const parentObject: ProductObject | null = getProductObjectById(
      state.productObjects,
      objId,
    );
    if (!parentObject) throw `Object with ${objId} is not present on canvas`;

    if (!parentObject.getLocationImageUrlById(locId))
      throw `Object with ${objId} does not have location`;

    const safeAreaObject: SafeAreaObject | undefined = getSafeAreaByObjId(
      state.safeAreas,
      objId,
      data.locId,
    );

    commit('setPrevState', state);

    const text = new TextObject(objId, locId, parentObject.product.name);
    try {
      text.updateProps({
        ...(await textProps(
          text,
          rootGetters['canvasSize'],
          parentObject,
          safeAreaObject,
        )),
        text: data.text ?? '',
      });
    } catch {
      commit('setLoading', false, { root: true });
      const error = 'Failed to load the font';
      await dispatch(
        'addAlert',
        {
          message: error,
          color: AlertColor.ERROR,
        },
        {
          root: true,
        },
      );
      throw new Error(error);
    }

    commit('setTexts', { value: [...state.texts, text] });
    commit('setSelectedId', { id: text.id });

    dispatch('getQuote');

    commit('setLoading', false, { root: true });
  },

  async updateTextByParent(
    ctx: ActionContext<IDesignState, any>,
    parentObject: ProductObject,
  ) {
    const { state, commit, getters, rootGetters, dispatch } = ctx;

    if (!getProductObjectById(state.productObjects, parentObject.id))
      throw `Parent object with ${parentObject.id} is not present on canvas`;

    const oldTexts = getTextsByParentId(getters.texts, parentObject.id);
    if (!oldTexts || !oldTexts.length) return;

    commit('setPrevState', state);

    oldTexts.forEach(async (oldText: TextObject) => {
      const text = cloneDeep<TextObject>(oldText);
      const safeAreaObject: SafeAreaObject | undefined = getSafeAreaByObjId(
        state.safeAreas,
        parentObject.id,
        text.locationId,
      );

      const props = await textProps(
        text,
        rootGetters['canvasSize'],
        parentObject,
        safeAreaObject,
      );
      text.updateProps(props);

      text.visible = textVisibility(text, parentObject);
      commit('updateTextObject', { text: text });
    });

    dispatch('getQuote');
  },

  async updateText(
    {
      state,
      commit,
      getters,
      rootGetters,
      dispatch,
    }: ActionContext<IDesignState, any>,
    { id, data }: { id: string; data: TextObjectData },
  ) {
    const prevText = getters.getTextById(id);
    if (!prevText) return null;

    commit('setPrevState', state);

    const parentObject: ProductObject | null = getProductObjectById(
      state.productObjects,
      prevText.parentId,
    );
    if (!parentObject)
      throw `Parent object with ${prevText.parentId} is not present on canvas`;

    //copy text to trigger watcher
    const text = cloneDeep<TextObject>(prevText);
    text.updateProps(data);

    const safeAreaObject: SafeAreaObject | undefined = getSafeAreaByObjId(
      state.safeAreas,
      parentObject.id,
      text.locationId,
    );

    const props = await textProps(
      text,
      rootGetters['canvasSize'],
      parentObject,
      safeAreaObject,
    );
    text.updateProps(props);

    commit('updateTextObject', { text: text });

    dispatch('getQuote');
  },

  /**
   * Delete text object
   * Select parent object if possible
   */
  deleteText(
    { state, commit, dispatch, rootGetters }: ActionContext<IDesignState, any>,
    id: string,
  ) {
    commit('setPrevState', state);

    // select parent obj if possible
    const selectedText: TextObject = rootGetters['design/selectedText'];
    const objId = selectedText ? selectedText.parentId : null;
    commit('setSelectedId', { id: objId });

    commit('setTexts', {
      value: state.texts.filter(object => object.id !== id),
    });

    dispatch('getQuote');
  },

  // used only after moving the text on the canvas
  async updateTextTransformMatrix(
    { state, commit, getters }: ActionContext<IDesignState, any>,
    { id, data }: { id: string; data: TextObjectData },
  ) {
    const prevText = getters.getTextById(id);
    if (!prevText) return null;

    commit('setPrevState', state);

    const text = cloneDeep<TextObject>(prevText);
    text.updateProps({ transformMatrix: data.transformMatrix });

    // if current text has siblings, their transformMatrix have to be updated accordingly
    const textSiblings = state.texts.filter(
      ({ parentId, id }) => parentId === text.parentId && id !== text.id,
    );
    textSiblings.forEach(textSibling => {
      textSibling.updateProps({ transformMatrix: text.transformMatrix });
    });

    commit('updateTextObject', { text: text });
  },
};

export const textMutations: MutationTree<IDesignState> = {
  updateTextObject(
    state,
    payload: DesignMutationPayload<{ text: TextObject }>,
  ) {
    const index = state.texts.findIndex(
      object => object.id === payload.text.id,
    );
    if (index !== -1) state.texts.splice(index, 1, payload.text);
  },

  setTexts(state, payload: DesignMutationPayload<{ value: TextObject[] }>) {
    state.texts = payload.value;
  },
};
