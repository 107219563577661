import { ProductObject } from '@/models/designObject/ProductObject';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import {
  calcPositionOnSafeLineObj,
  segmentsToBezierCurves,
} from '@/calculateProperties/helpers/bezierUtils';

/**
 * Count the component initial position.
 * If the first component is added, place it at the base product center.
 * If not the first component - shift it by 50% of its width to the right side.
 * If right side border is met, start shifting components from the center to the left side.
 * If left side border is met, repeat again with right side.
 */
export const componentTransform = async (
  safeArea: SafeAreaObject,
  baseProduct?: ProductObject,
  componentsCount?: number,
): Promise<number[]> => {
  const componentGap = 10;

  const baseProductWidth = baseProduct?.boundingBox?.width ?? 1;
  const baseProductHeight = baseProduct?.boundingBox?.height ?? 1;
  const baseProductTop = baseProduct?.boundingBox?.top ?? 0;
  const baseProductLeft = baseProduct?.boundingBox?.left ?? 0;

  const startPointX = baseProductLeft + baseProductWidth / 2;
  const startPointY = baseProductTop + baseProductHeight;

  const componentsPerOneSide =
    Math.floor(baseProductWidth / 2 / componentGap) ?? 1;

  let nextPointX;
  let nextPointY;

  if (!componentsCount) {
    nextPointX = baseProductLeft + baseProductWidth / 2;
    nextPointY = baseProductTop + baseProductHeight;
  } else {
    const nextPointXDirection =
      Math.floor((componentsCount - 1) / componentsPerOneSide) % 2 ? -1 : 1;
    const gapBetweenStartAndNextPoints =
      (((componentsCount - 1) % componentsPerOneSide) + 1) * componentGap;

    nextPointX =
      startPointX + gapBetweenStartAndNextPoints * nextPointXDirection;
    nextPointY = startPointY - gapBetweenStartAndNextPoints;
  }

  /** The exact position should be saved to store */
  const curves = segmentsToBezierCurves(safeArea);
  const { x, y } = calcPositionOnSafeLineObj(curves, {
    x: nextPointX,
    y: nextPointY,
  });

  return [1, 0, 0, 1, x, y];
};
