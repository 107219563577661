import { IPublicAPI } from '@/publicAPI/IPublicAPI';
import { DesignDTO } from '@/api/dto/DesignDTO';

/**
 * Wrapper around public api implementation
 * Needed to hide inner objects/functions that may be used inside impl class from the api user
 */
export class PublicAPI implements IPublicAPI {
  private publicAPI: IPublicAPI;
  constructor(publicAPI: IPublicAPI) {
    this.publicAPI = publicAPI;
  }

  public init() {
    return this.publicAPI.init();
  }

  public async loadDesignById(designId: string): Promise<void> {
    await this.publicAPI.loadDesignById(designId);
  }

  public async loadDesign(
    designId: string,
    designDTO: DesignDTO,
  ): Promise<void> {
    await this.publicAPI.loadDesign(designId, designDTO);
  }

  public generateOutput(second: boolean): Promise<string> {
    return this.publicAPI.generateOutput(second);
  }

  public get version(): string {
    return this.publicAPI.version;
  }

  public get developmentMode(): boolean {
    return this.publicAPI.developmentMode;
  }

  public set developmentMode(value: boolean) {
    this.publicAPI.developmentMode = value;
  }
}
