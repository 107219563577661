import {
  ItemsModule,
  CatalogItemState,
} from '@/store/modules/catalog/ItemsModule';
import { DesignObjectType } from '@/models/DesignObjectType';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { ProductObject } from '@/models/designObject/ProductObject';
import { flatten } from 'lodash';

interface ClosureState extends CatalogItemState {
  selectedItems: CatalogItem[];
}

class ClosuresModule extends ItemsModule {
  state: ClosureState = {
    ...this.state,
    itemType: DesignObjectType.CLOSURE,
    selectedItems: [],
  };

  getters: GetterTree<CatalogItemState, any> = {
    ...this.getters,

    /**
     * Get closures available for currently selected base product
     */
    filteredItems(state, getters, rootState, rootGetters): CatalogItem[] {
      const designBaseProduct: ProductObject | null =
        rootGetters[`design/baseProduct`];
      if (!designBaseProduct) return [];

      const closureIds = designBaseProduct.product.closures;
      const items = flatten(closureIds.map(id => getters.descendants(id)));
      return items;
    },

    selectedItems(state): CatalogItem[] {
      return (<ClosureState>state).selectedItems;
    },

    subItems(state, getters, rootState, rootGetters): CatalogItem[] {
      //if root is present, return children of the root
      if (state.selectedItem) {
        const subItemsIds =
          state.selectedItem.categories || state.selectedItem.products || [];
        return state.items.filter((item: CatalogItem) =>
          subItemsIds.includes(item.id),
        );
      }
      //otherwise return closures of current base product
      const currBaseProduct: ProductObject | null =
        rootGetters[`design/baseProduct`];
      if (currBaseProduct) {
        const baseProdClosures = currBaseProduct.product.closures;
        if (baseProdClosures) {
          return state.items.filter((item: CatalogItem) =>
            baseProdClosures.includes(item.id),
          );
        }
      }
      return [];
    },
  };

  actions: ActionTree<CatalogItemState, any> = {
    ...this.actions,

    setSelectedItems({ commit, getters, state, dispatch }, ids: number[]) {
      const items = flatten(ids.map(id => getters.descendants(id)));
      commit('setSelectedItems', items);
      // set default selected item
      commit('setSelectedItem', items[0] ?? null);
    },
  };

  mutations: MutationTree<CatalogItemState> = {
    ...this.mutations,

    setSelectedItems(state, items: CatalogItem[]) {
      (<ClosureState>state).selectedItems = items;
    },
  };
}

const closuresModule = new ClosuresModule();

export default closuresModule;
