















import { defineComponent } from '@vue/composition-api';

const Component = defineComponent({
  name: 'CanvasLogs',
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: Boolean,
  },

  setup(props) {},
});

export default Component;
