import { RouteConfig } from 'vue-router';
import BaseProductTab from '@/components/tabs/BaseProductTab.vue';
import ClosureTab from '@/components/tabs/ClosureTab.vue';
import ComponentTab from '@/components/tabs/ComponentTab.vue';
import TextTab from '@/components/tabs/TextTab.vue';
import CatalogTab from '@/components/tabs/CatalogTab.vue';
import DesignerView from '@/components/main/DesignerView.vue';
import DetailsTab from '@/components/tabs/DetailsTab.vue';
import { ROUTES } from '@/router/constants';
import { catalogTabBeforeEnter, viewMoreBeforeEnter } from '@/router/guards';

const routes: RouteConfig[] = [
  {
    path: ROUTES.catalogTab.path,
    name: ROUTES.catalogTab.name,
    components: {
      default: DesignerView,
      actionTab: CatalogTab,
    },
    children: [
      {
        path: ROUTES.baseProduct.path,
        name: ROUTES.baseProduct.name,
        component: BaseProductTab,
      },
      {
        path: ROUTES.closure.path,
        name: ROUTES.closure.name,
        component: ClosureTab,
      },
      {
        path: ROUTES.component.path,
        name: ROUTES.component.name,
        component: ComponentTab,
      },
    ],
    beforeEnter: catalogTabBeforeEnter,
  },
  {
    path: ROUTES.textTab.path,
    name: ROUTES.textTab.name,
    components: {
      default: DesignerView,
      actionTab: TextTab,
      mobileView: TextTab,
    },
  },
  // Details route is currently not used
  {
    path: ROUTES.detailsTab.path,
    name: ROUTES.detailsTab.name,
    components: {
      default: DesignerView,
      actionTab: DetailsTab,
      mobileView: DetailsTab,
    },
  },
];

export default routes;
