export enum FontStatus {
  NotLoaded = 'not-loaded',
  Loading = 'loading',
  Ready = 'ready',
  Failed = 'failed',
  Timeout = 'timeout',
}

export class Font {
  public id: number = 0;
  public fontFamily: string = '';
  public fileNormalUrl: string = '';
  public allowBold: boolean = false;
  public allowItalic: boolean = false;
  public status: FontStatus = FontStatus.NotLoaded;

  public constructor(initData: Partial<Font>) {
    Object.assign(this, initData);
  }
}
