import { DesignObjectType } from '@/models/DesignObjectType';
import { store } from '@/store/store';
import { ProductObject } from '@/models/designObject/ProductObject';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { safeAreaProps } from '@/calculateProperties/safeAreaProps';
import { TextObject } from '@/models/designObject/TextObject';
import { productProps } from '@/calculateProperties/productProps';
import { Dimensions } from '@/canvas/types';
import { textVisibility } from '@/calculateProperties/textProps';
import { OutputType } from '@/store/modules/design/types';

export function getCanvasSize() {
  return store.getters['canvasSize'];
}

/**
 * Return store action name for adding/choosing product depending on Product type
 * @param productType
 */
export function addProductActionName(productType: DesignObjectType) {
  let action = '';
  switch (productType) {
    case DesignObjectType.BASE:
      return (action = 'design/chooseBaseProduct');
    case DesignObjectType.CLOSURE:
      return (action = 'design/chooseClosure');
    case DesignObjectType.COMPONENT:
      return (action = 'design/addComponent');
    default:
      throw `There is no action for ${productType} type`;
  }
}

export function getProductObjectById(productObjs: ProductObject[], id: string) {
  return (
    productObjs.find(
      (designObj: ProductObject | null) => designObj && designObj.id === id,
    ) || null
  );
}

/**
 * Find safe areas that belongs to the locations of certain product
 * @param safeAreas - all safe areas kept in store
 * @param parentId - parent design product id (all locations must belong to it)
 * @param locIds - location ids
 */
export function getSafeAreasByObjId(
  safeAreas: SafeAreaObject[],
  parentId: string,
  locIds?: number[],
): SafeAreaObject[] {
  return safeAreas.filter(
    (safeAreaObj: SafeAreaObject) =>
      safeAreaObj &&
      safeAreaObj.parentId === parentId &&
      (!locIds || locIds.includes(safeAreaObj.locationId)),
  );
}

export function getTextsByParentId(texts: TextObject[], parentId: string) {
  return texts.filter(text => text.parentId === parentId);
}

/**
 * Update visibility of product object texts and return visible ones
 * @param texts - list of all texts on canvas
 * @param parentObj
 */
export function getVisibleObjTexts(
  texts: TextObject[],
  parentObj: ProductObject,
) {
  const parentTexts = getTextsByParentId(texts, parentObj.id);
  return parentTexts
    .filter(text => textVisibility(text, parentObj))
    .map(text => {
      text.visible = true;
      return text;
    });
}

/**
 * Return object safe areas that should be added and deleted
 * @param safeAreas - prev list of safe areas - used to find safe areas that must be deleted
 * @param parentObject
 */
export async function createObjSafeAreas(
  safeAreas: SafeAreaObject[],
  parentObject: ProductObject,
) {
  const locIds = parentObject.product.isRing
    ? parentObject.size?.locationDetails.map(loc => loc.locationId) || []
    : [parentObject.locationId];

  // delete prev safe areas if any
  const prevSafeAreas = getSafeAreasByObjId(safeAreas, parentObject.id, locIds);

  // for rings display all safe areas of current size
  const safeAreasStrings = parentObject.product.isRing
    ? parentObject.locationSafeAreas()
    : [
        {
          safeArea: parentObject.currSafeArea(),
          locId: parentObject.locationId,
        },
      ];

  const isBaseProduct = parentObject.type === DesignObjectType.BASE;
  const safeType =
    // safe areas are used, not safe lines
    isBaseProduct && !parentObject.product.isRing
      ? DesignObjectType.SAFE_LINE
      : DesignObjectType.SAFE_RECT;

  const productSafeAreas = await Promise.all(
    safeAreasStrings.map(async locSafeArea => {
      if (!locSafeArea.safeArea) return null;
      const safeArea = new SafeAreaObject(
        safeType,
        locSafeArea.safeArea,
        parentObject.id,
        locSafeArea.locId,
      );
      safeArea.updateProps(await safeAreaProps(safeArea, parentObject));
      return safeArea;
    }),
  );
  const filteredSafeAreas: SafeAreaObject[] = productSafeAreas.filter(
    safeArea => safeArea !== null,
  ) as SafeAreaObject[];

  return {
    safeAreasToDelete: prevSafeAreas,
    safeAreasToAdd: filteredSafeAreas,
  };
}

/**
 * Get safe area that belongs to the location of certain product
 * @param safeAreas - all safe areas kept in store
 * @param parentId - parent design product id (location must belong to it)
 * @param locId - location id
 */
export function getSafeAreaByObjId(
  safeAreas: SafeAreaObject[],
  parentId: string,
  locId?: number,
): SafeAreaObject | undefined {
  const locationSafeAreas = getSafeAreasByObjId(
    safeAreas,
    parentId,
    locId ? [locId] : undefined,
  );
  return locationSafeAreas && locationSafeAreas.length
    ? locationSafeAreas[0]
    : undefined;
}

export function isSmallScreen() {
  return store.getters['isSmallScreen'];
}

/**
 * find first closure that is not category in the given list of closures
 * @param closuresCatalog - the whole closures catalog
 * @param startingClosureIds - closures ids list to start with (closures list of certain product)
 */
export function getFirstClosureFromCatalog(
  closuresCatalog: CatalogItem[],
  startingClosureIds: number[],
): CatalogItem | null {
  for (let i = 0; i < startingClosureIds.length; i++) {
    const startingClosureId = startingClosureIds[i];

    const catalogItem = closuresCatalog.find(
      item => item.id === startingClosureId,
    );
    if (!catalogItem) return null;
    if (catalogItem && catalogItem.isProduct) return catalogItem;
    if (catalogItem && catalogItem.products && catalogItem.products.length) {
      const firstClosureId = catalogItem.products[0];
      return closuresCatalog.find(item => item.id === firstClosureId) || null;
    }

    if (catalogItem && catalogItem.closures) {
      const firstClosure = getFirstClosureFromCatalog(
        closuresCatalog,
        catalogItem.closures,
      );
      if (firstClosure) return firstClosure;
    }
  }
  return null;
}

/**
 * Turn all visible objects (products, texts) to another (second) side
 * @param side - show to which side objects must be turned
 * @param products
 * @param safeAreas
 * @param texts
 * @param canvasDim
 */
export async function turnObjectsToSide(
  side: OutputType,
  products: ProductObject[],
  safeAreas: SafeAreaObject[],
  texts: TextObject[],
  canvasDim: Dimensions,
) {
  async function turnObj(
    obj: ProductObject,
    baseProduct?: ProductObject,
    baseSafeArea?: SafeAreaObject,
  ) {
    const turnedLocation =
      side === OutputType.First
        ? obj.product.locations[0]
        : obj.product.locations[1];

    if (
      turnedLocation &&
      turnedLocation.id !== obj.locationId &&
      !obj.product.isRing // rings have both sides displayed at the same time
    ) {
      obj.locationId = turnedLocation.id;

      const props = await productProps(
        obj,
        canvasDim,
        baseProduct || undefined,
        baseSafeArea || undefined,
      );
      obj.updateProps(props);

      const prodSafeAreas = (await createObjSafeAreas([], obj)).safeAreasToAdd;
      turnedSafeAreas.push(...prodSafeAreas);
    } else {
      turnedSafeAreas.push(
        ...getSafeAreasByObjId(safeAreas, obj.id, [obj.locationId]),
      );
    }
    turnedTexts.push(...getVisibleObjTexts(texts, obj));
    turnedProducts.push(obj);
  }

  const turnedSafeAreas: SafeAreaObject[] = [];
  const turnedTexts: TextObject[] = [];
  const turnedProducts: ProductObject[] = [];

  const baseProduct: ProductObject | undefined = products.find(
    pr => pr.type === DesignObjectType.BASE,
  );

  let baseSafeArea: SafeAreaObject | undefined;
  if (baseProduct) {
    await turnObj(baseProduct);
    // use first safe area - for all products except of rings only one safe area is visible
    baseSafeArea =
      turnedSafeAreas && turnedSafeAreas.length
        ? turnedSafeAreas[0]
        : undefined;
  }

  await Promise.all(
    products
      .filter(pr => pr.type !== DesignObjectType.BASE)
      .map(async productObj => {
        return turnObj(productObj, baseProduct, baseSafeArea);
      }),
  );

  return {
    texts: turnedTexts,
    products: turnedProducts,
    safeAreas: turnedSafeAreas,
  };
}
