import { ProductObject } from '@/models/designObject/ProductObject';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';
import { TextObject } from '@/models/designObject/TextObject';
import {
  productDesignObjToFabric,
  safeAreaObjToFabricObj,
  textObjToFabricText,
} from '../mappers';
import { applyTransformationMatrix } from './modifyObject';

/**
 * Map product, safe area and text objects to fabric objects to be displayed in preview
 * The applied transformation is ignored
 * @param param0 product, safe area and text objects
 * @returns fabric.Object[]
 */
export async function createFabricPreviewObjects({
  productObj,
  safeAreaObj,
  textObj,
}: {
  productObj: ProductObject;
  safeAreaObj?: SafeAreaObject;
  textObj?: TextObject;
}) {
  // 1. Create product image on the canvas
  // Reset matrix transformation
  productObj.transformMatrix = [1, 0, 0, 1, 0, 0];
  const product = await productDesignObjToFabric(productObj);
  applyTransformationMatrix(product, productObj.transformMatrix, 'left', 'top');

  // 2. Create safe area on the canvas
  let safeArea = undefined;
  let safeAreaTMatrix: number[] = [];
  if (safeAreaObj) {
    const offset = safeAreaObj.safeAreaOffset;
    const size = safeAreaObj.safeAreaSize;

    safeAreaObj.transformMatrix = [...productObj.transformMatrix];
    safeAreaObj.transformMatrix[4] = offset.x + size.width / 2;
    safeAreaObj.transformMatrix[5] = offset.y + size.height / 2;
    safeAreaTMatrix = safeAreaObj.transformMatrix;

    safeArea = await safeAreaObjToFabricObj(safeAreaObj);
    applyTransformationMatrix(safeArea, safeAreaObj.transformMatrix);
  }

  // 3. Create text on the canvas
  // Text is transformed to be in the center of the safe area
  let text = undefined;
  if (textObj && safeAreaObj) {
    const { width: w, height: h } = safeAreaObj.safeAreaSize;

    textObj.transformMatrix = [...safeAreaTMatrix];
    textObj.visible = true;
    text = await textObjToFabricText(textObj);
    applyTransformationMatrix(text, textObj.transformMatrix);
    //scale text to fit within safe area
    const scaleFactor = Math.min(
      w / text.getScaledWidth(),
      h / text.getScaledHeight(),
    );
    text.scale(scaleFactor);
  }

  // omit undefined objects
  const objs: fabric.Object[] = [product, safeArea, text].filter(
    (o): o is fabric.Object => !!o,
  );

  return objs;
}
