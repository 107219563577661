import { Store } from 'vuex';
import { watch } from '@vue/composition-api';
import { differenceWith, isEqual } from 'lodash';
import { RootState } from '@/store/store';
import { useStore } from '@/composables/useStore';
import { ProductObject } from '@/models/designObject/ProductObject';
import FabricCanvas from '@/canvas/fabric/FabricCanvas';
import { AlertColor } from '@/utils/alerts';
import { productObjToFabricObj } from '@/canvas/mappers';
import { selectElement } from '@/canvas/stateWatchers/selectedWatch';
import { CanvasMode } from '@/canvas/types';
import { DesignObjectType } from '@/models/DesignObjectType';

export function watchProductObjects(canvas: FabricCanvas) {
  const store: Store<RootState> = useStore();

  watch(
    () => store.getters['design/productObjects'],
    async (components: ProductObject[], prevComponents: ProductObject[]) => {
      let errorMessage = null;

      try {
        //delete old objects
        const deletedObjects = differenceWith(
          prevComponents,
          components,
          (a: ProductObject, b: ProductObject) => a.id === b.id,
        );
        console.debug('deleted', deletedObjects);
        canvas.deleteObjects(deletedObjects.map((o: ProductObject) => o.id));
      } catch (e) {
        console.error(e);
        errorMessage = 'Cannot remove this product';
      }

      try {
        if (store.getters['design/loadDesignMode']) {
          canvas.setCanvasMode(CanvasMode.LoadDesign);
          canvas.multipleObjsModeOn(store.getters['design/countObjects']);
        }

        // add/update objects where image url changed
        const changedObjects = differenceWith(
          components,
          prevComponents,
          (a: ProductObject, b: ProductObject) => {
            return isEqual(a, b);
          },
        );
        console.debug('added or changed url', changedObjects);
        await Promise.all(
          changedObjects.map(async (dObj: ProductObject) => {
            const index = store.getters['design/getObjectIndex'](dObj);
            const objToAdd = await productObjToFabricObj(dObj, index);
            return await canvas.replaceObject(objToAdd, index);
          }),
        );
        // reset zoom if base product has been changed
        if (changedObjects.find(p => p.type === DesignObjectType.BASE)) {
          canvas.resetZoom(store.getters.canvasSize);
        }
        canvas.requestRender();
        await selectElement(store, canvas);
      } catch (e) {
        console.error(e);
        errorMessage = 'Cannot load product image';
      }

      if (errorMessage) {
        await store.dispatch('addAlert', {
          message: errorMessage,
          color: AlertColor.ERROR,
        });
        await store.dispatch('design/revertToPrevState');
      }
    },
    { deep: true },
  );
}
