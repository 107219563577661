import { fabric } from 'fabric';
import { BehaviourOptions } from '@/models/designObject/types';
import { attachMovingEvent } from '@/canvas/utils/attachEvents';
import { DesignObjectType } from '@/models/DesignObjectType';
import { Point } from '@/canvas/types';
import { calcPositionOnSafeLineObj } from '@/calculateProperties/helpers/bezierUtils';

export function applyOptions(obj: fabric.Object, options: BehaviourOptions) {
  if (obj.type === 'text') {
    (<fabric.IText>obj).editable = options.editable;
  }

  obj.lockMovementX = true;
  obj.lockMovementY = true;

  if (options.movable) {
    attachMovingEvent(obj);
  }

  if (!options.resizable) {
    obj.lockScalingX = true;
    obj.lockScalingY = true;
    obj.setControlsVisibility({
      bl: false,
      br: false,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      tl: false,
      tr: false,
    });
  } else {
    obj.setControlsVisibility({
      mb: false,
      ml: false,
      mr: false,
      mt: false,
    });
  }

  if (!options.rotatable) {
    obj.lockRotation = true;
    obj.setControlVisible('mtr', false);
  } else if (options.resizable) {
    obj.setControlVisible('tl', false);
  }

  obj.setControlVisible('edit', false);
  if (options.editable) {
    obj.setControlVisible('edit', true);
  }

  obj.setControlVisible('delete', false);
  if (options.deletable) {
    obj.setControlVisible('delete', true);
  }

  if (!options.selectable) {
    obj.selectable = false;
    obj.hoverCursor = 'default';
  } else {
    obj.hoverCursor = 'pointer';
  }

  obj.snapAngle = 45;
  obj.snapThreshold = 5;

  return obj;
}

/**
 * Apply transformation matrix
 * @param obj
 * @param transformationMatrix
 * @param originX
 * @param originY
 */

export function applyTransformationMatrix(
  obj: fabric.Object,
  transformationMatrix: number[] = [1, 0, 0, 1, 0, 0],
  originX = 'center',
  originY = 'center',
): void {
  if (transformationMatrix.length !== 6) return;
  const tOptions = fabric.util.qrDecompose(transformationMatrix);

  const newCenter = new fabric.Point(tOptions.translateX, tOptions.translateY);
  obj.set({
    ...tOptions,
    originX: originX,
    originY: originY,
  });
  obj.setPositionByOrigin(newCenter, originX, originY);
}

/**
 * Calculate position on the safe area curve depending on the passed position
 * @param safeAreaObject - safe area object
 * @param newPosition - new position
 */
export function calcPositionOnSafeArea(
  safeAreaObject: fabric.Object,
  newPosition: Point,
) {
  if (safeAreaObject.data.type === DesignObjectType.SAFE_RECT) {
    return calcPositionOnSafeRectObj(safeAreaObject, newPosition);
  } else {
    return calcPositionOnSafeLineObj(
      safeAreaObject.data.bezierCurves,
      newPosition,
    );
  }
}

/**
 * Not implemented because currently this logic is not needed
 * @param safeAreaObject
 * @param newPosition
 */
function calcPositionOnSafeRectObj(
  safeAreaObject: fabric.Object,
  newPosition: Point,
) {
  throw '[calcPositionOnSafeRectObj]: Not implemented';
}
