import { Store } from 'vuex';
import { RootState } from '@/store/store';
import FabricCanvas from '@/canvas/fabric/FabricCanvas';
import { AlertColor } from '@/utils/alerts';
import { getRingThumbId } from '@/canvas/utils/idsUtils';

/**
 * Function to set active object on canvas based on selected id from store
 * Not a true watcher, to be used in other watchers to select element after changes are made
 * @param store
 * @param canvas
 */
export async function selectElement(
  store: Store<RootState>,
  canvas: FabricCanvas,
) {
  const selectedId = store.getters['design/selectedId'];
  try {
    let idToSelect = selectedId;
    const selectedProductObject = store.getters['design/selectedProductObject'];
    //for rings only thumb must be selected
    if (selectedId && selectedProductObject?.product.isRing)
      idToSelect = getRingThumbId(selectedId);
    await canvas.setActiveObject(idToSelect);
  } catch (e) {
    console.error(e);

    await store.dispatch('addAlert', {
      message: `Cannot select object with id: ${selectedId}`,
      color: AlertColor.ERROR,
    });
  }
}
