
































import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { DesignObjectType } from '@/models/DesignObjectType';
import {
  computed,
  defineComponent,
  reactive,
  SetupContext,
  watch,
} from '@vue/composition-api';
import { setDialog } from '@/utils/dialogs';
import Footer from '@/components/elements/Footer.vue';

interface State {
  selectedBaseProduct: CatalogItem | null;
}

const Component = defineComponent({
  name: 'BottomBar',

  components: {
    Footer,
  },

  setup(_, { root: { $store, $router, $i18n } }: SetupContext) {
    const state = reactive({
      selectedBaseProduct: computed(
        () => $store.getters[`${DesignObjectType.BASE}/selectedItem`],
      ),
    }) as State;

    function startOver(confirmed: boolean) {
      if (!confirmed) return;
      $store.dispatch(`${DesignObjectType.BASE}/setSelectedItem`, null);
      $store.dispatch(`${DesignObjectType.BASE}/setSelectedCategory`, null);
      $store.dispatch(`${DesignObjectType.CLOSURE}/setSelectedItem`, null);
      $store.dispatch(`${DesignObjectType.COMPONENT}/setSelectedItem`, null);
      $store.dispatch(`design/resetState`);
      $router.push({ name: 'categoriesTab' });
    }

    function addToCart() {
      // trigger generating cart preview
      $store.dispatch('design/genCartPreview');
    }

    watch(
      // when preview is generated, trigger save design
      () => $store.getters['design/getCartPreview'],
      (cartPreview: string) => {
        if (cartPreview) {
          $store.dispatch('design/saveDesign');
        }
      },
    );

    const openDialog = () => {
      $store.dispatch(
        'showDialog',
        setDialog($i18n.t('prompts.changeProductDialog') as string, startOver),
      );
    };

    return {
      state,
      startOver,
      addToCart,
      openDialog,
    };
  },
});

export default Component;
