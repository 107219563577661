export class USymbol {
  public unicode: string = '';
  public icon: string = '';

  public unicodeToChar(text: string) {
    return text.replace(/((U\+)|(\\u))[\dA-F]{1,6}/gi, function (match) {
      const codePoint = parseInt(match.replace(/(U\+)|(\\u)/g, ''), 16);
      return String.fromCodePoint(codePoint);
    });
  }

  public constructor(initData: Partial<USymbol>) {
    initData.icon = this.unicodeToChar(initData.unicode || '');
    Object.assign(this, initData);
  }
}
