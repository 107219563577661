import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 728,
    },
    mobileBreakpoint: 'xs', // < 728px
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#292c34',
        secondary: '#d9e3f3',
        accent: '#2f64bf',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        ivory: '#FAF7F2',
      },
    },
  },
});
