















































import {
  computed,
  defineComponent,
  reactive,
  ref,
  SetupContext,
} from '@vue/composition-api';
import { DesignObjectType } from '@/models/DesignObjectType';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { Size } from '@/models/catalog/product/Size';
import { formatCurrency } from '@/utils/currencyUtils';
import ItemSliderList from '@/components/elements/ItemSliderList.vue';
import ItemGridList from '@/components/elements/ItemGridList.vue';
import { ROUTES } from '@/router/constants';
import Footer from '@/components/elements/Footer.vue';

interface State {
  closures: CatalogItem[];
  sizes: Size[];
  selectedClosure: CatalogItem | null;
  selectedSize: Size | null;
  selectedSizePrice: number;
}

const Component = defineComponent({
  name: 'ClosureTab',
  components: {
    ItemSliderList,
    ItemGridList,
    Footer,
  },

  setup(_, { root: { $store, $router }, emit }: SetupContext) {
    const type = DesignObjectType.CLOSURE;

    const state = reactive({
      closures: computed(() => $store.getters[`${type}/selectedItems`]),
      sizes: computed(() => state.selectedClosure?.sizes ?? []),
      selectedClosure: computed({
        get: () => $store.getters[`${type}/selectedItem`],
        set: value => $store.dispatch(`${type}/setSelectedItem`, value),
      }),
      selectedSize: computed(() => $store.getters[`${type}/selectedSize`]),
      selectedSizePrice: computed(
        () => state.selectedSize?.price ?? state.selectedClosure?.minPrice ?? 0,
      ),
    }) as State;

    const isMobile = computed(() => $store.getters.isMobile);

    const isShowMoreBtnVisible = ref(false);

    const seeMore = () => {
      $router.push({
        name: ROUTES.viewMore.name,
        params: {
          designObjectType: type,
        },
      });
    };

    return {
      state,
      type,
      formatCurrency,
      isMobile,
      seeMore,
      isShowMoreBtnVisible,
    };
  },
});

export default Component;
