import FabricCanvas from '@/canvas/fabric/FabricCanvas';
import { watch } from '@vue/composition-api';
import { Store } from 'vuex';
import { RootState } from '@/store/store';
import { useStore } from '@/composables/useStore';

const PREVIEW_SIZE = { width: 800, height: 800 };

/**
 * Triggered when cart preview must be generated
 * @param canvas
 */
export function watchGenCartPreview(canvas: FabricCanvas) {
  const store: Store<RootState> = useStore();
  watch(
    () => store.getters['design/getGenCartPreview'],
    async (curr: boolean, prev: boolean) => {
      if (curr !== prev) {
        const cartPreview = canvas.getCartPreview(
          PREVIEW_SIZE,
          store.getters.canvasSize,
        );
        store.dispatch('design/setCartPreview', cartPreview);
      }
    },
  );
}
