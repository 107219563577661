import { IPublicAPI } from '@/publicAPI/IPublicAPI';
import { store } from '@/store/store';
import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import Vue2TouchEvents from 'vue2-touch-events';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/vueI18n';
import App from '@/App.vue';
import { DesignDTO } from '@/api/dto/DesignDTO';
import router from '@/router/router';
import VueI18n from 'vue-i18n';

/**
 * Designer Public API implementation
 */
export class PublicApiImlp implements IPublicAPI {
  public init() {
    return this.setupVue();
  }

  public async loadDesignById(designId: string) {
    const waitForDesign = this.waitForDesign();
    await store.dispatch('design/loadDesignById', designId);
    return await waitForDesign;
  }

  public async loadDesign(
    designId: string,
    designDTO: DesignDTO,
  ): Promise<void> {
    const waitForDesign = this.waitForDesign();
    await store.dispatch('design/loadDesign', { designId, designDTO });
    return await waitForDesign;
  }

  public async generateOutput(second: boolean): Promise<string> {
    const waitForOutput = this.waitForOutput();
    if (second) {
      await store.dispatch('design/genSecondOutput');
    } else {
      await store.dispatch('design/genFirstOutput');
    }
    return await waitForOutput;
  }

  private setupVue() {
    return new Promise<string>((resolve, reject) => {
      Vue.config.productionTip = false;
      Vue.use(VueCompositionApi);
      // Vue.use(Vue2TouchEvents); // todo: is it used? conflicts with vuetify

      new Vue({
        store,
        vuetify,
        i18n,
        router,
        render: h => h(App),
        mounted: function () {
          resolve('Designer is initialized');
        },
      }).$mount('#app');
    });
  }

  private waitForDesign() {
    return new Promise<void>((resolve, reject) => {
      const unsubscribe = store.subscribeAction((action, state) => {
        switch (action.type) {
          case 'design/onDesignLoaded':
            unsubscribe();
            resolve();
        }
      });
    });
  }

  private waitForOutput(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const unsubscribe = store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'design/setFirstOutput':
            unsubscribe();
            resolve(store.getters['design/getFirstOutput']);
            break;
          case 'design/setSecondOutput':
            unsubscribe();
            resolve(store.getters['design/getSecondOutput']);
            break;
        }
      });
    });
  }

  public get version(): string {
    return process.env.VUE_APP_VERSION;
  }

  public get developmentMode(): boolean {
    return store.getters['developmentMode'];
  }

  public set developmentMode(value: boolean) {
    store.commit('setDevelopmentMode', value);
  }
}
