




















import { computed, defineComponent, SetupContext } from '@vue/composition-api';

const Component = defineComponent({
  name: 'FooterBar',

  setup(_, { root: { $store } }: SetupContext) {
    const isMobile = computed(() => $store.getters.isMobile);

    return {
      isMobile,
    };
  },
});

export default Component;
