import { TextObject } from '@/models/designObject/TextObject';
import { DesignObjectType } from '@/models/DesignObjectType';
import {
  applyOptions,
  applyTransformationMatrix,
} from '@/canvas/utils/modifyObject';
import { fabric } from 'fabric';
import { connectScalingToFontSize } from '@/canvas/utils/attachEvents';
import { loadFont } from '@/canvas/utils/fonts';

/**
 * Map textObject to fabric IText object
 * @param textObj
 * @param index - index on canvas where to put obj
 */
export async function textObjToFabricText(textObj: TextObject, index?: number) {
  await loadFont(textObj.fontFamily);

  let fabricText;
  if (textObj.isMultiline) {
    fabricText = new fabric.Textbox(textObj.text, {
      ...textObj,
      fontFamily:
        textObj.fontFamily +
        // fallback to symbol and emoji fonts,
        // last resort font used to indicate unsupported characters
        ", 'Noto Sans Symbol', 'Noto Sans Symbol 2', 'Noto Sans Emoji', 'Last Resort'",
      padding: 0,
      width: textObj.boundingBox?.width,
      textAlign: 'center',
    });
  } else {
    fabricText = new fabric.IText(textObj.text, {
      ...textObj,
      fontFamily:
        textObj.fontFamily +
        ", 'Noto Sans Symbol', 'Noto Sans Symbol 2', 'Noto Sans Emoji', 'Last Resort'",
      padding: 8,
    });
  }

  fabricText.data = {
    id: textObj.id,
    type: DesignObjectType.TEXT,
    parentId: textObj.parentId,
    index: index,
  };

  applyOptions(fabricText, textObj.behaviourOptions);
  applyTransformationMatrix(fabricText, textObj.transformMatrix);
  connectScalingToFontSize(fabricText);

  return fabricText;
}
