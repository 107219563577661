import { Mapper } from '@/models/mappers/Mapper';
import { Font } from '@/models/catalog/Font';
import { FontDTO } from '@/api/dto/FontDTO';

export class FontMapper extends Mapper<Font, FontDTO> {
  async mapFromDTO(fontDTO: FontDTO): Promise<Font> {
    return new Font({
      ...(<Partial<Font>>fontDTO),
    });
  }

  mapToDTO(font: Font): FontDTO {
    const { status, ...rest } = font;
    return { ...rest };
  }
}

export const fontMapper = new FontMapper();
