import { fabric } from 'fabric';
import { TextObjectData } from '@/models/designObject/TextObject';
import { ProductObjectData } from '@/models/designObject/ProductObject';
import { BoundingBox } from '@/models/designObject/types';

export function fabricToDesignObjData(
  fabricObject: fabric.Object,
): ProductObjectData {
  return {
    sizeId: fabricObject.data.sizeId,
    locationId: fabricObject.data.locationId,
    transformMatrix: fabricObject.calcTransformMatrix(),
    boundingBox: getBoundingBoxWithoutPadding(fabricObject),
  };
}

export function fabricToTextObjData(
  fabricObject: fabric.Object,
): TextObjectData {
  const textObj = <fabric.Text>fabricObject;
  if (!textObj) throw 'Fabric object must extend Text class';
  return {
    transformMatrix: fabricObject.calcTransformMatrix(),
    boundingBox: getBoundingBoxWithoutPadding(fabricObject),
    fontSize: textObj.fontSize,
  };
}

export function fabricToSafeAreaData(fabricObject: fabric.Object) {
  return {
    transformMatrix: fabricObject.calcTransformMatrix(),
    boundingBox: getBoundingBoxWithoutPadding(fabricObject),
  };
}
/**
 * Calc bounding box without object padding
 * @param fabricObject
 */
function getBoundingBoxWithoutPadding(
  fabricObject: fabric.Object,
): BoundingBox {
  return {
    left: fabricObject.getBoundingRect().left + (fabricObject.padding || 0),
    top: fabricObject.getBoundingRect().top + (fabricObject.padding || 0),
    width:
      fabricObject.getBoundingRect().width - 2 * (fabricObject.padding || 0),
    height:
      fabricObject.getBoundingRect().height - 2 * (fabricObject.padding || 0),
  };
}
