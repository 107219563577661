import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { fetchFontsList } from '@/api/apiClient';
import { Font } from '@/models/catalog/Font';
import { fontMapper } from '@/models/mappers/FontMapper';
import { FontDTO } from '@/api/dto/FontDTO';
import { attachFontsStylesheet } from '@/utils/fonts';

interface FontsState {
  items: Font[];
  loading: boolean;
}

class FontsModule implements Module<FontsState, any> {
  namespaced: boolean = true;

  state: FontsState = {
    items: [],
    loading: false,
  };

  getters: GetterTree<FontsState, any> = {
    items(state): Font[] {
      return state.items;
    },

    loading(state): boolean {
      return state.loading;
    },
  };

  actions: ActionTree<FontsState, any> = {
    async fetchItems({ state, commit }) {
      commit('setLoading', true);
      try {
        attachFontsStylesheet();
        if (!state.items.length) {
          const result: FontDTO[] = await fetchFontsList();
          const mappedItems: Font[] = await fontMapper.mapFromDTOList(result);
          commit('setItems', mappedItems);
        }
      } catch (err) {
        console.error(err);
      } finally {
        commit('setLoading', false);
      }
    },
  };

  mutations: MutationTree<FontsState> = {
    setItems(state, items: Font[]) {
      state.items = items;
    },

    setLoading(state, value: boolean) {
      state.loading = value;
    },
  };
}

const fontsModule = new FontsModule();
export default fontsModule;
