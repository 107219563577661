export interface Alert {
  message: string;
  color: AlertColor;
}

export enum AlertColor {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}
