

























































import {
  computed,
  defineComponent,
  reactive,
  SetupContext,
} from '@vue/composition-api';
import { Size } from '@/models/catalog/product/Size';
import { formatCurrency } from '@/utils/currencyUtils';
import { DesignObjectType } from '@/models/DesignObjectType';
import { SizeDialog } from '@/store/modules/catalog/BaseProductsModule';
import { useDefaultProductChosen } from '@/composables/useDefaultProductChosen';
import { setDialog } from '@/utils/dialogs';

interface State {
  dialog: SizeDialog | null;
  size: Size | null;
  isOpen: boolean;
}

const Component = defineComponent({
  name: 'ItemOptions',

  setup(props, { emit, root: { $store, $vuetify, $i18n } }: SetupContext) {
    const type = DesignObjectType.BASE;

    const state = reactive({
      dialog: computed(() => $store.getters[`${type}/sizeDialog`]),
      size: null,
      isOpen: computed({
        get: () => !!state.dialog,
        set: () => $store.dispatch(`${type}/hideSizeDialog`),
      }),
    }) as State;

    const title = $i18n.t('titles.selectSize');
    const itemName = computed(() => state.dialog?.item.name ?? '');
    const itemDescription = computed(
      () => state.dialog?.item.description ?? '',
    );
    const itemSizes = computed(() => state.dialog?.item.sizes ?? []);

    function getItemSizeText(size: Size) {
      return size.name + ' - ' + formatCurrency(size.price);
    }

    const isDefaultProductChosen = useDefaultProductChosen();

    const hide = () => {
      $store.dispatch(`${type}/hideSizeDialog`);
      state.size = null;
    };

    function close() {
      state.dialog?.onConfirm && state.dialog.onConfirm(false);
      hide();
    }

    async function selectSize() {
      if (!state.size) return;

      const selectConfirm = async () => {
        await $store.dispatch(`${type}/setSelectedItemWithSize`, {
          item: state.dialog?.item,
          size: state.size,
        });
        state.dialog?.onConfirm && state.dialog.onConfirm(true);
        hide();
      };

      if (isDefaultProductChosen.value) {
        await selectConfirm();
      } else {
        const changeProduct = async (isConfirmed: boolean) => {
          if (!isConfirmed) {
            close();
          } else {
            await selectConfirm();
          }
        };

        $store.dispatch(
          'showDialog',
          setDialog(
            $i18n.t('prompts.changeProductDialog') as string,
            changeProduct,
          ),
        );
      }
    }

    return {
      title,
      state,
      close,
      itemName,
      itemSizes,
      selectSize,
      getItemSizeText,
      itemDescription,
    };
  },
});

export default Component;
