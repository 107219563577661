export const currencyFormat: ICurrencyFormat = {
  locale: 'en-US',
  currency: 'USD',
};

export interface ICurrencyFormat {
  locale: string;
  currency: string;
}

export function formatCurrency(
  value: number,
  currencyFormatVal: ICurrencyFormat = currencyFormat,
): string {
  return new Intl.NumberFormat(currencyFormatVal.locale, {
    style: 'currency',
    currency: currencyFormatVal.currency,
  }).format(value);
}
