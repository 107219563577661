import { render, staticRenderFns } from "./DesignerView.vue?vue&type=template&id=53e38557&scoped=true&"
import script from "./DesignerView.vue?vue&type=script&lang=ts&"
export * from "./DesignerView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e38557",
  null
  
)

export default component.exports