/**
 * Get id of fabric object that represents product location
 * @param designObjId
 * @param locationId
 */
export function getLocationObjId(designObjId: string, locationId?: number) {
  if (!locationId) return designObjId;
  return `${designObjId}:${locationId}`;
}

/**
 * Return only design object id from fabric id
 * @param id - fabric object id
 */
export function designObjIdFromFabricObjId(id: string) {
  return id.split(':')[0];
}

/**
 * Get ring thumbnail image id
 * @param designObjId
 */
export function getRingThumbId(designObjId: string) {
  return `${designObjId}:thumb`;
}
