
































































import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  PropType,
  ref,
  SetupContext,
  watch,
} from '@vue/composition-api';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { formatCurrency } from '@/utils/currencyUtils';
import { DesignObjectType } from '@/models/DesignObjectType';
import { VItemGroup } from 'vuetify/lib';

const Component = defineComponent({
  name: 'ItemGridList',

  props: {
    items: {
      type: Array as PropType<CatalogItem[]>,
      default: () => [],
    },
    selectedItem: {
      type: Object as PropType<CatalogItem>,
      default: () => null,
    },
    addItem: {
      type: Function as PropType<() => void>,
      default: () => {},
    },

    selectedPrice: Number,

    showTitle: {
      type: Boolean,
      default: false,
    },

    designObjectType: {
      type: String as PropType<DesignObjectType>,
      default: '',
    },

    showPrice: {
      type: Boolean,
      default: true,
    },
    /**
     * Action to do on select, passes selected item
     * used for adding custom logic upon selection
     */
    selectItem: {
      type: Function as PropType<((item: CatalogItem) => void) | undefined>,
      default: undefined,
    },
  },

  emits: ['update:selectedItem', 'verticalScrollVisible'],

  setup(props, { root: { $store, $vuetify }, emit }: SetupContext) {
    onUpdated(() => checkScrollState.value());
    onMounted(() => checkScrollState.value());

    const item = computed({
      get: () => props.selectedItem,
      set: value => emit('update:selectedItem', value),
    });

    const gridElement = ref<InstanceType<typeof VItemGroup> | null>(null);

    const checkScrollState = computed(() => () => {
      const scrollHeight = gridElement.value?.$el.scrollHeight ?? 0;
      const clientHeight = gridElement.value?.$el.clientHeight ?? 0;
      emit('verticalScrollVisible', scrollHeight > clientHeight);
    });

    const activePrice = computed(
      () => props.selectedPrice ?? item.value.minPrice,
    );

    const itemsPerRow = computed(() => {
      switch ($vuetify.breakpoint.name) {
        case 'sm':
          return 2;
        case 'md':
        case 'xs':
        default:
          return 3;
        case 'lg':
          return 4;
        case 'xl':
          return 6;
      }
    });

    const selectCard = (toggle: Function, selectedItem: CatalogItem) => {
      if (props.selectItem) return props.selectItem(selectedItem);
      toggle();
    };

    const isMobile = computed(() => $store.getters.isMobile);

    return {
      item,
      isMobile,
      selectCard,
      activePrice,
      itemsPerRow,
      formatCurrency,
      gridElement,
    };
  },
});

export default Component;
