import { fabric } from 'fabric';
import { isSmallScreen } from '@/utils/storeUtils';

export const ICON_SIZE = 24;

const renderIcon =
  (icon: HTMLImageElement, size: number) =>
  (
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object,
  ) => {
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle || 0));
    ctx.drawImage(icon, -size / 2, -size / 2, size, size);
    ctx.restore();
  };

export function setupControlsTheme() {
  const color = '#A4C2F7';
  fabric.Object.prototype.set({
    padding: 16,
    cornerSize: isSmallScreen() ? ICON_SIZE : 16,
    borderColor: color,
    cornerStyle: 'circle',
    cornerStrokeColor: color,
    borderOpacityWhenMoving: 1,
    cornerColor: 'rgba(0, 0, 0, 0)',
  });
}

export function setupDeleteControl(clickHandler: any) {
  const icon = document.createElement('img');
  icon.src = require('../../assets/cancel-blue.svg');

  const options: IControlOptions = {
    x: 0.5,
    y: -0.5,
    cursorStyle: 'pointer',
    withConnection: false,
    render: renderIcon(icon, ICON_SIZE),
    mouseDownHandler: clickHandler,
  };

  // @ts-ignore
  fabric.Object.prototype.controls.delete = new fabric.Control(options);
}

export function setupDetailsIcon(clickHandler: any) {
  const icon = document.createElement('img');
  icon.src = require('../../assets/edit.svg');

  const options: IControlOptions = {
    x: -0.5,
    y: -0.5,
    cursorStyle: 'pointer',
    withConnection: true,
    render: renderIcon(icon, ICON_SIZE),
    mouseUpHandler: clickHandler,
  };

  // @ts-ignore
  fabric.Object.prototype.controls.edit = new fabric.Control(options);
}

export function setupRotateControl() {
  const icon = document.createElement('img');
  icon.src = require('../../assets/repeat-blue.svg');

  const applyStyles = (control: IControlOptions) => {
    control.x = -0.5;
    control.y = 0.5;
    control.offsetY = 0;
    control.cursorStyle = 'pointer';
    control.withConnection = false;
    control.render = renderIcon(icon, ICON_SIZE);
  };

  // @ts-ignore
  applyStyles(fabric.Object.prototype.controls.mtr);
}

interface IControlOptions {
  x?: number;
  y?: number;
  offsetX?: number;
  offsetY?: number;
  cursorStyle?: string;
  withConnection?: boolean;
  render?: (
    ctx: CanvasRenderingContext2D,
    left: number,
    top: number,
    styleOverride: any,
    fabricObject: fabric.Object,
  ) => void;
  mouseUpHandler?: (event: Event, control: { target: fabric.Object }) => void;
  mouseDownHandler?: (event: Event, control: { target: fabric.Object }) => void;
  cursorStyleHandler?: (event: Event, control: any) => string;
}
