import FabricCanvas from '@/canvas/fabric/FabricCanvas';
import { watch } from '@vue/composition-api';
import { Store } from 'vuex';
import { RootState } from '@/store/store';
import { useStore } from '@/composables/useStore';

/**
 * Watch and apply canvas zoom changes
 * @param canvas canvas to zoom
 */
export function watchViewportChange(canvas: FabricCanvas) {
  const store: Store<RootState> = useStore();
  watch(
    () => store.getters['design/canvasZoom'],
    async (value: number | 'FIT' | 'CUSTOM') => {
      if (value === 'FIT') {
        canvas.resetZoom(store.getters.canvasSize);
      } else if (typeof value === 'number') {
        canvas.applyZoom(value, store.getters.canvasSize);
      }
    },
  );
}
