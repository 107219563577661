import { DesignObject } from '@/models/designObject/DesignObject';
import { DesignObjectType } from '@/models/DesignObjectType';
import { Dimensions, Point } from '@/canvas/types';
import { extractPathSegmentsFromSvg } from '@/utils/svgUtils';
import { SafeAreaSegment } from '@/models/designObject/types';

/**
 * Interface to hold design object information received from canvas
 */
export type SafeAreaObjectData = Partial<
  Omit<SafeAreaObject, 'id' | 'type' | 'parentId'>
>;

export class SafeAreaObject extends DesignObject {
  private _locationId: number;
  private _svgString: string;
  // path segments
  private _segments: SafeAreaSegment[] = [];

  public strokeColor = 'rgba(0, 0, 0, 0)';

  constructor(
    safeAreaType: DesignObjectType,
    svgString: string,
    parentId: string,
    locationId: number,
  ) {
    super(safeAreaType, parentId);

    this._locationId = locationId;
    this._svgString = svgString;
    this._segments = extractPathSegmentsFromSvg(svgString);
  }

  public get locationId() {
    return this._locationId;
  }

  public get svgString() {
    return this._svgString;
  }

  public get safeAreaSize(): Dimensions {
    return {
      width: this.bbox.width,
      height: this.bbox.height,
    };
  }

  public get segments(): SafeAreaSegment[] {
    return this._segments;
  }

  /**
   * Get unscaled offset position relatively to the start of the svg viewbox
   * @deprecated use {@link safeAreaOffset} or {@link bbox} instead
   */
  public unscaledOffsetToSafeArea() {
    // offset from the start of viewbox is min x and min y
    // ! Note: actually this seems incorrect, the minX/Y points are not the edges of viewport
    // See bbox and bRect values for comparison.
    // However, it works somehow, thus not refactored for now.
    return {
      x: this.minX(),
      y: this.minY(),
    };
  }

  public get safeAreaOffset() {
    return {
      x: this.bbox.x,
      y: this.bbox.y,
    };
  }

  /**
   * Get bbox of the safe area - bounding rectangle of svg contents in svg;
   * It does not account for any transformation applied to the element or its parents.
   * https://developer.mozilla.org/en-US/docs/Web/API/SVGGraphicsElement/getBBox
   */
  public get bbox() {
    const svgElement = new DOMParser().parseFromString(
      this.svgString,
      'image/svg+xml',
    ).documentElement;
    const toRemove = document.body.insertAdjacentElement(
      'beforeend',
      svgElement,
    );
    const bounds = (toRemove as unknown as SVGGraphicsElement).getBBox();
    toRemove?.remove();
    return bounds;
  }

  /**
   * Get bounding rectangle of the safe area.
   * Provides information about the size of an element and its position relative to the viewport.
   * https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
   */
  public get bRect() {
    const svgElement = new DOMParser().parseFromString(
      this.svgString,
      'image/svg+xml',
    ).documentElement;
    const toRemove = document.body.insertAdjacentElement(
      'beforebegin',
      svgElement,
    );
    const bRect = (toRemove as Element).getBoundingClientRect();
    toRemove?.remove();
    return bRect;
  }

  /**
   * Start of the safe area - first point (taking into account translate and scaling)
   */
  public scaledSafeAreaStart(): Point {
    const startPoint = this.segments[0].startPoint;
    return this.getScaledPoint(startPoint) || { x: 0, y: 0 };
  }

  public scaledSafeAreaEnd(): Point {
    const endPoint = this.segments[this.segments.length - 1].endPoint;
    return this.getScaledPoint(endPoint) || { x: 0, y: 0 };
  }

  public scaledSafeAreaSize(): Dimensions {
    return {
      width: this.boundingBox?.width || this.safeAreaSize.width,
      height: this.boundingBox?.height || this.safeAreaSize.height,
    };
  }

  /**
   * Apply current scale and transform to initial segments
   */
  public scaledTranslatedSegments(): SafeAreaSegment[] {
    return this.segments.map(point => {
      return {
        startPoint: this.getScaledPoint(point.startPoint),
        ctrl1: this.getScaledPoint(point.ctrl1),
        ctrl2: this.getScaledPoint(point.ctrl2),
        endPoint: this.getScaledPoint(point.endPoint),
      };
    });
  }

  private getScaledPoint(point?: Point) {
    const scale = this.transformMatrix ? this.transformMatrix[0] || 1 : 1;
    const translateX = this.boundingBox?.left || 0;
    const translateY = this.boundingBox?.top || 0;
    const offset = this.safeAreaOffset;

    return point
      ? {
          x: (point.x - offset.x) * scale + translateX,
          y: (point.y - offset.y) * scale + translateY,
        }
      : undefined;
  }

  private maxX() {
    return this.maxSegmentsProp('x');
  }

  private maxY() {
    return this.maxSegmentsProp('y');
  }

  private minX() {
    return this.minSegmentsProp('x');
  }

  private minY() {
    return this.minSegmentsProp('y');
  }

  /**
   * Find maximum property value from the points array
   * @param prop - property name
   */
  private maxSegmentsProp(prop: string) {
    const props: number[] = this.segments.map(point => {
      const startPoint = point.startPoint as any;
      const endPoint = point.endPoint as any;
      if (startPoint && endPoint) {
        return startPoint[prop] > endPoint[prop]
          ? startPoint[prop]
          : endPoint[prop];
      }
      if (!startPoint && endPoint) return endPoint[prop];
      if (startPoint && !endPoint) return startPoint[prop];
      return 0;
    });
    return Math.max(...props);
  }

  /**
   * Find minimum property value from the points array
   * @param prop - property name
   */
  private minSegmentsProp(prop: string) {
    const props: number[] = this.segments.map(point => {
      const startPoint = point.startPoint as any;
      const endPoint = point.endPoint as any;
      if (startPoint && endPoint) {
        return startPoint[prop] < endPoint[prop]
          ? startPoint[prop]
          : endPoint[prop];
      }
      if (!startPoint && endPoint) return endPoint[prop];
      if (startPoint && !endPoint) return startPoint[prop];
      return Infinity;
    });
    return Math.min(...props);
  }
}
