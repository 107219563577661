import { Store } from 'vuex';
import { watch } from '@vue/composition-api';
import { differenceWith, isEqual } from 'lodash';
import { RootState } from '@/store/store';
import { useStore } from '@/composables/useStore';
import FabricCanvas from '@/canvas/fabric/FabricCanvas';
import { AlertColor } from '@/utils/alerts';
import { TextObject } from '@/models/designObject/TextObject';
import { textObjToFabricText } from '@/canvas/mappers';
import { selectElement } from '@/canvas/stateWatchers/selectedWatch';
import { CanvasMode } from '@/canvas/types';

export function watchTexts(canvas: FabricCanvas) {
  const store: Store<RootState> = useStore();

  watch(
    () => store.getters['design/texts'],
    async (texts: TextObject[], prevTexts: TextObject[]) => {
      let errorMessage = null;

      try {
        //delete old objects
        const deletedObjects = differenceWith(
          prevTexts,
          texts,
          (a: TextObject, b: TextObject) => a.id === b.id,
        );
        canvas.deleteObjects(deletedObjects.map((o: TextObject) => o.id));
      } catch (e) {
        console.error(e);
        errorMessage = 'Cannot remove this text';
      }

      try {
        if (store.getters['design/loadDesignMode']) {
          canvas.setCanvasMode(CanvasMode.LoadDesign);
          canvas.multipleObjsModeOn(store.getters['design/countObjects']);
        }

        // add/update text objects that changed
        const changedObjects = differenceWith(
          texts,
          prevTexts,
          (a: TextObject, b: TextObject) =>
            a.id === b.id &&
            a.fontFamily === b.fontFamily &&
            a.text === b.text &&
            a.visible === b.visible &&
            a.fontSize === b.fontSize &&
            isEqual(a.transformMatrix, b.transformMatrix),
        );
        console.debug('added or changed texts', texts);

        await Promise.all(
          changedObjects.map(async (textObj: TextObject) => {
            if (textObj.visible) {
              const index = store.getters['design/getTextIndex'];
              const textToAdd = await textObjToFabricText(textObj, index);
              canvas.replaceObject(textToAdd, index);
            } else {
              canvas.deleteObjects([textObj.id]);
            }
          }),
        );
        canvas.requestRender();
        await selectElement(store, canvas);
      } catch (e) {
        console.error(e);
        errorMessage = 'Failed to render text';
      }

      if (errorMessage) {
        await store.dispatch('addAlert', {
          message: errorMessage,
          color: AlertColor.ERROR,
        });
        await store.dispatch('design/revertToPrevState');
      }
    },
    { deep: true },
  );
}
