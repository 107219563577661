import FabricCanvas from '@/canvas/fabric/FabricCanvas';
import { Store } from 'vuex';
import { RootState } from '@/store/store';
import { useStore } from '@/composables/useStore';
import { CanvasMode, FabricEvents } from '@/canvas/types';
import { fabric } from 'fabric';
import { DesignObjectType } from '@/models/DesignObjectType';
import { AlertColor } from '@/utils/alerts';
import {
  fabricToDesignObjData,
  fabricToSafeAreaData,
  fabricToTextObjData,
} from '@/canvas/mappers';
import { designObjIdFromFabricObjId } from '@/canvas/utils/idsUtils';
import { ROUTES } from '@/router/constants';
import { useRouter } from '@/composables/useRouter';

export function watchCanvasEvents(canvas: FabricCanvas) {
  const store: Store<RootState> = useStore();
  const router = useRouter();

  canvas.attachEventListener(
    FabricEvents.ShowDetails,
    async function (event?: fabric.IEvent) {
      const fabricObject = event?.target;
      if (!fabricObject) return;
      const productId =
        fabricObject.data.type === DesignObjectType.TEXT
          ? fabricObject.data.parentId
          : fabricObject.data.id;
      const selectedProduct =
        store.getters['design/getProductObjById'](productId);
      if (
        selectedProduct.product.isTextAllowed &&
        router.currentRoute.path !== ROUTES.textTab.path
      ) {
        router.push(ROUTES.textTab);
      }
    },
  );

  canvas.attachEventListener(
    FabricEvents.MultipleObjectsAdded,
    async function (event?: fabric.IEvent) {
      if (canvas.getCanvasMode() === CanvasMode.GenerateOutput) {
        store.dispatch('design/setOutput', {
          type: event?.target,
          output: canvas.getCanvasPreview(store.getters.canvasSize),
        });
      } else if (canvas.getCanvasMode() === CanvasMode.LoadDesign) {
        store.dispatch('design/onDesignLoaded', {
          type: event?.target,
        });
      }
      canvas.multipleObjsModeOff();
      canvas.setCanvasMode(CanvasMode.Default);
    },
  );

  canvas.attachEventListeners(
    [FabricEvents.ObjectMoved, FabricEvents.ObjectRotated],
    async function (event?: fabric.IEvent) {
      const fabricObject = event?.target;
      if (fabricObject) {
        switch (fabricObject.data?.type) {
          //text can be moved/rotated with component
          case DesignObjectType.TEXT:
            const textData = fabricToTextObjData(fabricObject);
            await store.dispatch('design/updateTextTransformMatrix', {
              id: designObjIdFromFabricObjId(fabricObject.data.id),
              data: textData,
            });
            break;
          //safeArea can be moved/rotated with component
          case DesignObjectType.SAFE_RECT:
            const safeAreaData = fabricToSafeAreaData(fabricObject);
            await store.dispatch('design/updateSafeAreaTransformMatrix', {
              id: designObjIdFromFabricObjId(fabricObject.data.id),
              data: safeAreaData,
            });
            break;
          case DesignObjectType.BASE:
          case DesignObjectType.CLOSURE:
          case DesignObjectType.COMPONENT:
            const designData = fabricToDesignObjData(fabricObject);
            await store.dispatch('design/updateProductTransformMatrix', {
              id: fabricObject.data.id,
              data: designData,
            });
        }
      }
    },
  );

  canvas.attachEventListener(
    FabricEvents.ObjectScaled,
    async function (event?: fabric.IEvent) {
      try {
        const fabricObject = event?.target;
        if (fabricObject) {
          if (fabricObject.data.type === DesignObjectType.TEXT) {
            const textData = fabricToTextObjData(fabricObject);
            await store.dispatch('design/updateText', {
              id: fabricObject.data.id,
              data: textData,
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
  );

  canvas.attachEventListener(
    FabricEvents.ObjectDeleted,
    async function (event?: fabric.IEvent) {
      const fabricObject = event?.target;
      if (fabricObject) {
        const id = designObjIdFromFabricObjId(fabricObject.data.id);
        const type = fabricObject.data?.type;
        try {
          switch (type) {
            case DesignObjectType.COMPONENT:
              await store.dispatch('design/deleteProductObject', id);
              break;
            case DesignObjectType.TEXT:
              await store.dispatch('design/deleteText', id);
              break;
            case DesignObjectType.SAFE_RECT:
            case DesignObjectType.SAFE_LINE:
              await store.dispatch('design/deleteText', id);
              break;
            default:
              throw `Cannot delete object of type "${type}"`;
          }
        } catch (e) {
          console.error(e);

          await store.dispatch('addAlert', {
            message: 'Cannot delete component',
            color: AlertColor.ERROR,
          });
        }
      }
    },
  );

  canvas.attachEventListener(
    FabricEvents.MouseDown,
    async function (event?: fabric.IEvent) {
      const fabricObject = event?.target;
      //deselect event
      if (!fabricObject) await store.dispatch('design/selectObject', null);
    },
  );

  canvas.attachEventListeners(
    [FabricEvents.SelectionCreated, FabricEvents.SelectionUpdated],
    async function (event?: fabric.IEvent) {
      const fabricObject = event?.target;
      if (fabricObject && fabricObject.data?.id) {
        const id = designObjIdFromFabricObjId(fabricObject.data.id);
        await store.dispatch('design/selectObject', id);
      }
    },
  );

  canvas.attachEventListeners(
    [FabricEvents.DoubleTap],
    async function (event?: fabric.IEvent) {
      const fabricObject = event?.target;

      if (!fabricObject || !fabricObject.data?.id) return;
      const type: DesignObjectType = fabricObject.data.type;

      let path = router.currentRoute.path;
      switch (type) {
        // open engraving tab is text doubletapped
        case DesignObjectType.TEXT:
          if (router.currentRoute.path === ROUTES.textTab.path) return;
          path = ROUTES.textTab.path;
          break;

        case DesignObjectType.BASE:
        case DesignObjectType.CLOSURE:
        case DesignObjectType.COMPONENT:
          path = `/${ROUTES.catalogTab.path}/${ROUTES[type].path}`;
          break;
      }
      if (router.currentRoute.path === path) return;
      router.replace(path);
    },
  );

  canvas.attachEventListener(
    FabricEvents.ViewportChange,
    async function (event?: fabric.IEvent) {
      await store.dispatch('design/updateCanvasZoom', {
        zoom: (event as any).zoomLabel,
      });
    },
  );
}
