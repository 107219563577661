import {
  ItemsModule,
  CatalogItemState,
} from '@/store/modules/catalog/ItemsModule';
import { DesignObjectType } from '@/models/DesignObjectType';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { Size } from '@/models/catalog/product/Size';

export type SizeDialog = {
  item: CatalogItem;
  onConfirm?: (isConfirmed: boolean) => void;
};

interface BaseProductState extends CatalogItemState {
  selectedCategory: CatalogItem | null;
  sizeDialog: SizeDialog | null;
}

class BaseProductsModule extends ItemsModule {
  state: BaseProductState = {
    ...this.state,
    itemType: DesignObjectType.BASE,
    selectedCategory: null,
    sizeDialog: null,
  };

  getters: GetterTree<CatalogItemState, any> = {
    ...this.getters,

    /**
     * Direct children items of the selectedCategory
     * If selectedCategory is not specified, rootItems are returned.
     */
    subItems(state, getters): CatalogItem[] {
      const parentItem = (<BaseProductState>state).selectedCategory;
      if (!parentItem) return getters.rootItems;

      const subItemsIds = [
        ...(parentItem.categories ?? []),
        ...(parentItem.products ?? []),
      ];
      return state.items.filter((item: CatalogItem) =>
        subItemsIds.includes(item.id),
      );
    },

    selectedCategory(state): CatalogItem | null {
      return (<BaseProductState>state).selectedCategory;
    },

    sizeDialog(state): SizeDialog | null {
      return (<BaseProductState>state).sizeDialog;
    },
  };

  actions: ActionTree<CatalogItemState, any> = {
    ...this.actions,

    /**
     * Overridden action from ItemModule
     * Set selected category if selected item is category
     * Otherwise, additionally to selecting item, select available closures
     */
    async setSelectedItem(
      { commit, dispatch, getters },
      item: CatalogItem | null,
    ) {
      if (!item?.isProduct) {
        dispatch('setSelectedCategory', item);
        commit('setSelectedItem', item);
        return;
      }

      // check whether the location images are loaded
      // if not, reject selection
      try {
        const allImages: (() => Promise<unknown>)[] = [];
        item.sizes.forEach(size => {
          const location = size.locationDetails[0];
          allImages.push(location.getLocationImageSize.bind(location));
        });
        await Promise.all(allImages.map(imageLoad => imageLoad()));
      } catch (e) {
        return;
      }

      let selectedSize = getters.selectedSize;
      const isSelectedItemHasSameSize = item.sizes.some(
        ({ name }) => name === selectedSize?.name,
      );
      const shouldSelectDefaultSize =
        (!selectedSize || !isSelectedItemHasSameSize) &&
        item &&
        item.sizes.length;

      /** if one of the product sizes is selected, find it by name and select
       * Otherwise, select default first size
       */
      if (shouldSelectDefaultSize) {
        selectedSize = item.sizes[0];
      } else {
        selectedSize = item.sizes.find(
          ({ name }) => name === selectedSize?.name,
        );
      }
      commit('setSelectedItem', item);
      commit('setSelectedSize', selectedSize);

      // select closures
      const closureIds = item && !item.finished ? item.closures : [];
      await dispatch(
        `${DesignObjectType.CLOSURE}/setSelectedItems`,
        closureIds,
        { root: true },
      );
    },

    async setSelectedSize(
      { commit, rootGetters, dispatch },
      size: Size | null,
    ) {
      commit('setSelectedSize', size);

      // select default closure
      const defaultClosure =
        rootGetters[`${DesignObjectType.CLOSURE}/selectedItems`]?.[0];
      await dispatch(
        `${DesignObjectType.CLOSURE}/setSelectedItem`,
        defaultClosure,
        { root: true },
      );
    },

    setSelectedCategory({ commit }, item: CatalogItem | null) {
      commit('setSelectedCategory', item);
    },

    showSizeDialog({ commit }, dialog: SizeDialog) {
      commit('setSizeDialog', dialog);
    },

    hideSizeDialog({ commit }) {
      commit('setSizeDialog', null);
    },

    async setSelectedItemWithSize(
      { commit, dispatch, getters },
      { item, size }: { item: CatalogItem | null; size: Size | null },
    ) {
      commit('setSelectedItem', item);
      commit('setSelectedSize', size);

      // select closures
      const closureIds = item && !item.finished ? item.closures : [];
      await dispatch(
        `${DesignObjectType.CLOSURE}/setSelectedItems`,
        closureIds,
        { root: true },
      );
    },
  };

  mutations: MutationTree<CatalogItemState> = {
    ...this.mutations,

    setSelectedCategory(state, item: CatalogItem) {
      (<BaseProductState>state).selectedCategory = item;
    },

    setSizeDialog(state, value: SizeDialog | null) {
      (<BaseProductState>state).sizeDialog = value;
    },
  };
}

const baseProductsModule = new BaseProductsModule();

export default baseProductsModule;
