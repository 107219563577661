import axios from 'axios';
import { getAPIUrl } from '@/api/envUtils';
import { DesignObjectType } from '@/models/DesignObjectType';
import { CatalogItemDTO } from '@/api/dto/CatalogItemDTO';
import { FontDTO } from '@/api/dto/FontDTO';
import { USymbolDTO } from '@/api/dto/USymbolDTO';
import { DesignDTO, SaveDesignDTO } from '@/api/dto/DesignDTO';
import { QuoteDTO } from '@/api/dto/QuoteDTO';

const API_URL = getAPIUrl(process.env.VUE_APP_ENV); // dev-server

export async function fetchItems(
  type: DesignObjectType,
): Promise<CatalogItemDTO[]> {
  let endpoint = '';

  switch (type) {
    case DesignObjectType.BASE:
      endpoint = 'base-product';
      break;
    case DesignObjectType.CLOSURE:
      endpoint = 'closure';
      break;
    case DesignObjectType.COMPONENT:
      endpoint = 'component';
      break;
    default:
      console.warn('Provide Product type');
  }

  const response = await axios.get(`${API_URL}/${endpoint}`);

  return response.data;
}

export async function fetchFontsList(): Promise<FontDTO[]> {
  const response = await axios.get(`${API_URL}/fonts/list`);
  return response.data;
}

export async function fetchUSymbolsList(): Promise<USymbolDTO[]> {
  const response = await axios.get(`${API_URL}/symbols`);

  return response.data;
}

export function getFontsStylesheetUrl() {
  return `${API_URL}/fonts/css`;
}

export async function getQuote(design: DesignDTO): Promise<QuoteDTO> {
  const resp = await axios.post(`${API_URL}/get-quote`, design);
  return resp.data;
}

export async function saveDesign(payload: SaveDesignDTO) {
  const url = `${process.env.VUE_APP_SAVE_DESIGN_URL ?? API_URL}/save-design`;
  const response = await axios.post(url, payload);
  // if (window.top) window.top.location.assign(response.data.redirectUrl);
  // else window.location.assign(response.data.redirectUrl);
  if (window.top) window.top.location.href = response.data.redirectUrl;
  else window.location.href = response.data.redirectUrl;
}

export async function getDesignById(designId: string): Promise<DesignDTO> {
  const apiUrl = `${API_URL}/design?design_id=${designId}`;
  const resp = await axios.get(apiUrl);
  return resp.data;
}
