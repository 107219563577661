import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { DesignObjectType } from '@/models/DesignObjectType';
import { fetchItems } from '@/api/apiClient';
import { catalogItemMapper } from '@/models/mappers/CatalogItemMapper';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { uniq } from 'lodash';
import { Size } from '@/models/catalog/product/Size';

interface CatalogItemState {
  items: CatalogItem[];
  selectedItem: CatalogItem | null;
  loading: boolean;
  itemType: DesignObjectType | null;
  selectedSize: Size | null;
}

class ItemsModule implements Module<CatalogItemState, any> {
  namespaced: boolean = true;

  state: CatalogItemState = {
    items: [],
    selectedItem: null,
    loading: false,
    itemType: null,
    selectedSize: null,
  };

  getters: GetterTree<CatalogItemState, any> = {
    items(state): CatalogItem[] {
      return state.items;
    },

    /**
     * Get direct children items of the selected item \
     * It can be subcategories, or nested products \
     * If no item selected, rootItems are returned
     */
    subItems(state, getters): CatalogItem[] {
      if (!state.selectedItem) return getters.rootItems;

      const currItem = state.selectedItem;
      const subItemsIds = currItem.categories || currItem.products || [];
      return getters.getItemsByIds(subItemsIds);
    },

    selectedItem(state): CatalogItem | null {
      return state.selectedItem;
    },

    getItemById: (state, getters) =>
      function (itemId?: number): CatalogItem | undefined {
        const item = state.items.find(i => i.id === itemId);
        return item;
      },

    sizes(state): Size[] {
      if (!state.selectedItem) return [];
      return state.selectedItem.sizes;
    },

    selectedSize(state): Size | null {
      return state.selectedSize;
    },

    /** Root items - root categories */
    rootItems(state): CatalogItem[] {
      return state.items.filter((item: CatalogItem) => !item.parentId);
    },

    getItemsByIds:
      state =>
      (ids: number[] = []): CatalogItem[] => {
        return state.items.filter((item: CatalogItem) => ids.includes(item.id));
      },

    /**
     * Get all descendant products from category
     * Recursively gets products from subcategories
     * In other words flats nested structure and gets array of leaf nodes
     */
    descendants:
      (state, getters) =>
      (itemId?: number): number[] => {
        if (!itemId) itemId = state.selectedItem?.id;
        const itemIds = uniq(getters.descendantsRecursive(itemId, []));
        return getters.getItemsByIds(itemIds);
      },

    /** Inner function of descendants */
    descendantsRecursive:
      (state, getters) =>
      (itemId: number, leaves: number[] = []) => {
        const item: CatalogItem = getters.getItemById(itemId);
        if (!item) return leaves;
        // Return item if it is product
        if (item.isProduct) leaves.push(item.id);
        else if (item.products) {
          // Add nested products if item is category
          const subProducts = item.products.map(p =>
            getters.descendantsRecursive(p, leaves),
          );
          leaves.concat(subProducts);
        } else if (item.categories) {
          // Traverse subcategories
          const subCategories = item.categories.map(c =>
            getters.descendantsRecursive(c, leaves),
          );
          leaves.concat(subCategories);
        }
        return leaves;
      },

    /** Returns product siblings */
    siblings(state, getters) {
      const currItem = state.selectedItem;
      return currItem?.isProduct ? getters.descendants(currItem.parentId) : [];
    },

    loading(state): boolean {
      return state.loading;
    },
  };

  actions: ActionTree<CatalogItemState, any> = {
    async fetchItems({ state, commit }) {
      commit('setLoading', true);
      try {
        if (!state.items.length) {
          const result = state.itemType ? await fetchItems(state.itemType) : [];
          const mappedItems = await catalogItemMapper.mapFromDTOList(result);
          commit('setItems', mappedItems);
        }
      } catch (err) {
        console.error(err);
      } finally {
        commit('setLoading', false);
      }
    },

    setSelectedItem({ commit, getters }, item: CatalogItem | number | null) {
      if (typeof item === 'number') item = getters.getItemById(item);
      commit('setSelectedItem', item);

      // set first size as selected
      const size = (<CatalogItem>item)?.sizes[0] ?? null;
      commit('setSelectedSize', size);
    },

    setSelectedSize({ commit }, size: Size | null) {
      commit('setSelectedSize', size);
    },
  };

  mutations: MutationTree<CatalogItemState> = {
    setItems(state, items: CatalogItem[]) {
      state.items = items;
    },

    setSelectedItem(state, item: CatalogItem) {
      state.selectedItem = item;
    },

    setSelectedSize(state, size: Size) {
      state.selectedSize = size;
    },

    setLoading(state, value: boolean) {
      state.loading = value;
    },
  };
}

export { ItemsModule, CatalogItemState };
