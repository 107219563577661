import { ProductObject } from '@/models/designObject/ProductObject';
import { Dimensions } from '@/canvas/types';
import { DesignObjectType } from '@/models/DesignObjectType';

export function getScaleFactor(
  designObj: ProductObject,
  objectSize: Dimensions,
  canvasSize: Dimensions,
  baseProductPPI: number,
) {
  // todo: use actual padding from canvas, review
  const canvasPaddingRatio = 0.05; // 5%
  const canvasPadding = {
    width: canvasSize.width * canvasPaddingRatio,
    height: canvasSize.height * canvasPaddingRatio,
  };

  let desiredScaleFactor = 1;
  if (designObj.type === DesignObjectType.BASE) {
    const objDesiredSize = {
      width: canvasSize.width - 2 * canvasPadding.width,
      height: canvasSize.height - 2 * canvasPadding.height,
    };

    // resize base product according to canvas size
    desiredScaleFactor = Math.min(
      objDesiredSize.width / (objectSize.width || 1),
      objDesiredSize.height / (objectSize.height || 1),
    );
  } else {
    // resize other products according to base product
    const desiredWidth = designObj.imageWidthUnits() * baseProductPPI;
    desiredScaleFactor = desiredWidth / (objectSize.width || 1);
  }
  return desiredScaleFactor;
}
