














































import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import { useElementSize, useWindowSize } from '@vueuse/core';

const Component = defineComponent({
  name: 'ScreenRulers',
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: Boolean,
  },

  setup(props, { root: { $emit } }) {
    const content = ref<Vue | null>(null);
    const bodySize = reactive(
      useElementSize(
        document.body,
        { width: 0, height: 0 },
        { box: 'border-box' },
      ),
    );

    const windowInnerSize = reactive(useWindowSize());

    const heights = computed(() => [
      { name: 'window', height: windowInnerSize.height + 'px' },
      { name: 'vh', height: '100vh' },
      { name: 'dvh', height: '100dvh' },
      { name: 'svh', height: '100svh' },
      { name: 'lvh', height: '100lvh' },
    ]);

    const widths = computed(() => [
      { name: 'window', width: windowInnerSize.width + 'px' },
      { name: 'vw', width: '100vw' },
      { name: 'dvw', width: '100dvw' },
      { name: 'svw', width: '100svw' },
      { name: 'lvw', width: '100lvw' },
    ]);

    const windowOuterSize = computed(() => ({
      width: window.outerWidth,
      height: window.outerHeight,
    }));

    return {
      content,
      heights,
      widths,
      windowInnerSize,
      windowOuterSize,
      bodySize,
    };
  },
});

export default Component;
