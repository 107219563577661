














import ConfirmationDialog from '@/components/utils/ConfirmationDialog.vue';
import SizeDialog from '@/components/elements/SizeDialog.vue';
import AlertsContainer from '@/components/utils/AlertsContainer.vue';
import PreloaderOverlay from '@/components/utils/PreloaderOverlay.vue';
import OrientationOverlay from '@/components/utils/OrientationOverlay.vue';
import DevFab from '@/components/utils/dev/DevFab.vue';
import {
  SetupContext,
  defineComponent,
  onMounted,
  computed,
  reactive,
} from '@vue/composition-api';
import { provideStore } from '@/composables/useStore';
import { DesignObjectType } from './models/DesignObjectType';
import { useScreenOrientation } from '@vueuse/core';
import { ROUTES } from './router/constants';
import { provideRouter } from './composables/useRouter';

const Component = defineComponent({
  name: 'App',

  components: {
    AlertsContainer,
    PreloaderOverlay,
    OrientationOverlay,
    ConfirmationDialog,
    DevFab,
    SizeDialog,
  },
  setup(_, { root: { $store, $router } }: SetupContext) {
    onMounted(async () => {
      try {
        entryRoute();
        await $store.dispatch('fontsModule/fetchItems');
        await $store.dispatch('symbolsModule/fetchItems');
        await $store.dispatch(`${DesignObjectType.BASE}/fetchItems`);
        await $store.dispatch(`${DesignObjectType.CLOSURE}/fetchItems`);
        await $store.dispatch(`${DesignObjectType.COMPONENT}/fetchItems`);
      } catch (e) {
        console.error(e);
      } finally {
        await $store.dispatch('hideLoader');
      }
    });

    const state = reactive({
      developmentMode: computed(() => $store.getters['developmentMode']),
      loading: computed(() => $store.getters.loading),
      loadingText: computed(() => $store.getters.loadingText),
    });

    provideStore($store);
    provideRouter($router);

    const isMobile = computed(() => $store.getters.isMobile);

    function entryRoute() {
      $router.replace(ROUTES.selectCategory.path);
    }

    const { orientation } = useScreenOrientation();
    const warnOrientation = computed(
      () => isMobile.value && orientation.value?.includes('landscape'),
    );

    return {
      state,
      warnOrientation,
    };
  },
});

export default Component;
