






















































import {
  computed,
  defineComponent,
  PropType,
  SetupContext,
} from '@vue/composition-api';
import { CatalogItem } from '@/models/catalog/product/CatalogItem';
import { formatCurrency } from '@/utils/currencyUtils';

const Component = defineComponent({
  name: 'CategoriesGridList',

  props: {
    items: {
      type: Array as PropType<CatalogItem[]>,
      default: () => [],
    },
    selectedItem: {
      type: Object as PropType<CatalogItem>,
      default: () => null,
    },
  },

  emits: ['update:selectedItem'],

  setup(props, { root: { $store, $vuetify }, emit }: SetupContext) {
    const item = computed(() => props.selectedItem);

    const selectItem = (item: CatalogItem) => {
      emit('update:selectedItem', item);
    };

    const itemsPerRow = computed(() => {
      switch ($vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return 2;
        case 'md':
        default:
          return 3;
        case 'lg':
        case 'xl':
          return props.items.length <= 4 ? 2 : 4;
      }
    });

    const isMobile = computed(() => $store.getters.isMobile);

    return {
      item,
      isMobile,
      selectItem,
      itemsPerRow,
      formatCurrency,
    };
  },
});

export default Component;
