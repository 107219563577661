























import { Dialog } from '@/utils/dialogs';
import {
  computed,
  defineComponent,
  reactive,
  SetupContext,
} from '@vue/composition-api';

interface State {
  dialog: Dialog | null;
  isOpen: boolean;
}

const Component = defineComponent({
  name: 'ConfirmationDialog',

  setup(props, { root: { $store } }: SetupContext) {
    const state = reactive({
      dialog: computed(() => $store.getters['dialog']),
      isOpen: computed({
        get: () => !!state.dialog,
        set: () => $store.dispatch('hideDialog'),
      }),
    }) as State;

    function no() {
      state.dialog?.handler(false);
      $store.dispatch('hideDialog');
    }

    function yes() {
      state.dialog?.handler(true);
      $store.dispatch('hideDialog');
    }

    const message = computed(() => state.dialog?.message ?? '');

    return {
      state,
      no,
      yes,
      message,
    };
  },
});

export default Component;
