import { ProductObject } from '@/models/designObject/ProductObject';
import { BoundingBox } from '@/models/designObject/types';
import { Dimensions } from '@/canvas/types';
import { TextObject } from '@/models/designObject/TextObject';
import { SafeAreaObject } from '@/models/designObject/SafeAreaObject';

/**
 * Get bbox of the product object
 * @param safeAreaObject object to find bbox of
 * @param transformMatrix transformation matrix of the parent object
 * @param originalSize
 * @returns product object bbox
 */
export async function getProductBoundingBox(
  designProduct: ProductObject,
  transformMatrix?: number[],
  originalSize?: Dimensions,
) {
  const boundingBox: BoundingBox = { top: 0, left: 0, width: 0, height: 0 };

  if (!transformMatrix || transformMatrix.length < 6) return boundingBox;

  const imageSize = originalSize || (await designProduct.locationImageSize());
  if (imageSize) {
    boundingBox.width = imageSize.width * transformMatrix[0];
    boundingBox.height = imageSize.height * transformMatrix[3];

    boundingBox.left = transformMatrix[4] - boundingBox.width / 2;
    boundingBox.top = transformMatrix[5] - boundingBox.height / 2;
  }
  return boundingBox;
}

/**
 * Get bbox of the text object
 * Text object bbox - bbox of the safe area text has to fit in
 * @param safeAreaObject object to find bbox of
 * @param transformMatrix transformation matrix of the parent object
 * @returns text object bbox
 */
export function getTextBoundingBox(
  textObj: TextObject,
  parentSafeArea?: SafeAreaObject,
): BoundingBox {
  if (!parentSafeArea) {
    return textObj.boundingBox ?? { width: 0, height: 0 };
  }
  const editableAreaSize = parentSafeArea.scaledSafeAreaSize();
  return {
    width: editableAreaSize.width,
    height: editableAreaSize.height,
  };
}

/**
 * Get bbox of the safe area object
 * @param safeAreaObject object to find bbox of
 * @param transformMatrix transformation matrix of the parent object
 * @returns safe area bbox
 */
export function getSafeAreaBoundingBox(
  safeAreaObject: SafeAreaObject,
  transformMatrix?: number[],
): BoundingBox {
  const boundingBox: BoundingBox = { top: 0, left: 0, width: 0, height: 0 };

  const scale = transformMatrix ? transformMatrix[0] : 1;
  const safeAreaSize = safeAreaObject.safeAreaSize;
  boundingBox.width = safeAreaSize.width * scale;
  boundingBox.height = safeAreaSize.height * scale;

  if (!transformMatrix || transformMatrix.length < 6) return boundingBox;

  boundingBox.left = transformMatrix[4] - boundingBox.width / 2;
  boundingBox.top = transformMatrix[5] - boundingBox.height / 2;

  return boundingBox;
}
