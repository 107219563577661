import { fabric } from 'fabric';
import {
  applyOptions,
  applyTransformationMatrix,
} from '@/canvas/utils/modifyObject';
import { FabricEvents } from '@/canvas/types';
import { ICON_SIZE } from '@/canvas/helpers/controls';

export function loadImageFromUrl(url: string): Promise<fabric.Image> {
  return new Promise((resolve, reject) => {
    fabric.Image.fromURL(
      url,
      img => {
        if (!(img.width || img.height)) {
          return reject('Failed to load product image');
        }
        // development setup: uncomment to see product image actual borders
        // img.set({
        //   stroke: 'blue',
        //   strokeWidth: 1,
        //   opacity: 0.5,
        // });
        resolve(img);
      },
      {
        crossOrigin: 'anonymous',
      },
    );
  });
}

export function loadSvgFromString(svgString: string): Promise<fabric.Object> {
  return new Promise((resolve, reject) => {
    fabric.loadSVGFromString(svgString, (objs, options) => {
      const group = fabric.util.groupSVGElements(objs, options);
      resolve(group);
    });
  });
}

/**
 * Create fabric element from SVG file
 * @param src - file url
 */
export function parseSvgInGroup(src: string): Promise<fabric.Object> {
  return new Promise((resolve, reject) => {
    fabric.loadSVGFromURL(src, (objs, options) => {
      const group = fabric.util.groupSVGElements(objs, options);
      resolve(group);
    });
  });
}

/**
 * Create control icon (edit)
 * Needed only for rings
 * @param parentId - object id to which icon will be attached
 * @param position - where to put icon
 */
export async function createEditIcon(
  parentId: string,
  position?: { left: number; top: number },
) {
  const editIcon = await parseSvgInGroup(require('../../assets/edit.svg'));
  editIcon.data = {
    controlIcon: true,
    parentId: parentId,
  };
  const scaleX = ICON_SIZE / editIcon.getScaledWidth();
  const transformMatrix = [
    scaleX,
    0,
    0,
    scaleX,
    position?.left || 0,
    position?.top || 0,
  ];
  applyTransformationMatrix(editIcon, transformMatrix);
  applyOptions(editIcon, {
    selectable: false,
    rotatable: false,
    resizable: false,
    movable: false,
    deletable: false,
  });
  editIcon.setCoords();

  editIcon.hoverCursor = 'pointer';

  // keep previous selection when icon is clicked
  let selectedObj: fabric.Object | undefined | null;
  editIcon.on('mousedown:before', function (evt) {
    const obj = evt.target;
    selectedObj = obj?.canvas?.getActiveObject();
  });
  editIcon.on('mousedown', function (evt) {
    const obj = evt.target;
    if (selectedObj) obj?.canvas?.setActiveObject(selectedObj);
    obj?.canvas?.fire(FabricEvents.ShowDetails, {
      target: obj?.canvas?.getActiveObject(),
    });
  });
  return editIcon;
}
