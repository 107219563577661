export abstract class Mapper<Model extends Object, DTO extends Object> {
  public async mapFromDTOList(itemDTO: DTO[]): Promise<Model[]> {
    return await Promise.all(itemDTO.map(dto => this.mapFromDTO(dto)));
  }

  public mapToDTOList(items: Model[]): DTO[] {
    return items.map(item => this.mapToDTO(item));
  }

  // @ts-ignore
  public abstract async mapFromDTO(itemDTO: DTO): Promise<Model>;
  public abstract mapToDTO(item: Model): DTO;
}
