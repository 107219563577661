import { getImageSize } from '@/utils/imageUtils';
import { Dimensions } from '@/canvas/types';

export class LocationDetails {
  public locationId: number = 0;
  public url: string = '';
  public safeArea: string = '';
  public widthUnits: number = 1;

  public constructor(initData: Partial<LocationDetails>) {
    Object.assign(this, initData);
  }

  public getLocationImageSize(): Promise<Dimensions> {
    return getImageSize(this.url)();
  }
}
